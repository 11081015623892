import MainAppBar from "./Component/MainAppBar";
import PropertiesPanel from "./Component/PropertiesPanel/PropertiesPanel";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { grey, lightBlue } from "@mui/material/colors";
import { SnackbarProvider } from 'material-ui-snackbar-provider';
import Canvas from "./Component/Canvas";
import CustomSnackbar from "./Component/CustomSnackbar/CustomSnackbar";
import { UserContextProvider } from "./UserContext";
import { ModelerProvider } from "./ModelerContext";

const oneTheme = createTheme({
    palette: {
        primary: lightBlue,
        secondary: grey
    },
    typography: {
        fontFamily: ['Quicksand', 'Calibri', 'Arial'].join(','),
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
        fontWeightBold: 700,
        fontSize: 12
    },
    components : {
        MuiToolbar: {
            styleOverrides: {
                dense: {
                    height: 38,
                    minHeight: 38
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '0.8em',
                    backgroundColor: 'grey'
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    scrollbarWidth: 'thin',
                    scrollbarColor: "#bcbcbc #acacac",
                    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                        width: '0.4em',
                    },
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                        borderRadius: 8,
                        backgroundColor: "#bcbcbc",
                        //minHeight: 24,
                        //border: "1px solid #c9c9c9",
                    },
                    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                        backgroundColor: "#acacac",
                    },
                    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                        backgroundColor: "#acacac",
                    },
                    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#acacac",
                    },
                    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                        //backgroundColor: "#bcbcbc",
                    }
                }
            }
        }
    }
});

export default function App() {

    return (
        <ThemeProvider theme={oneTheme}>
            <SnackbarProvider 
                SnackbarComponent={CustomSnackbar}
                SnackbarProps={{ autoHideDuration: 4000, anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }}
            >
            <CssBaseline> 
            <div className="App">
                <UserContextProvider>
                <ModelerProvider>
                <PropertiesPanel/>
                <Canvas />
                <MainAppBar />
                </ModelerProvider>
                </UserContextProvider>
            </div>
            </CssBaseline>
            </SnackbarProvider>
        </ThemeProvider>
    );
}