import { useState } from "react";
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, Paper, TableContainer, Table, TableBody, TableRow, TableCell, IconButton, Box, Tabs, Tab } from "@mui/material";
import { Close } from '@mui/icons-material';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}  

export default function KeyboardDialog ({ isDialogOpen, handleCloseDialog }) {
    
    const tableWidth = 320;

    const [value, setValue] = useState(0);

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    return(
        <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle>
                Keyboard shortcuts
                <IconButton
                    onClick={handleCloseDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
            <>
               <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} centered>
                        <Tab label="Windows" {...a11yProps(0)} />
                        <Tab label="Mac" {...a11yProps(1)} />
                    </Tabs>
                </Box>
            <TabPanel value={value} index={0}>
            <TableContainer component={Paper} sx={{ minWidth: tableWidth }}>
            <Table>
                <TableBody>
                <TableRow>
                    <TableCell>Undo</TableCell>
                    <TableCell>⊞ + Z</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Redo</TableCell>
                    <TableCell>⊞ + ⇧ + Z</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Select All</TableCell>
                    <TableCell>⊞ + A</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Zooming</TableCell>
                    <TableCell>ctrl + Scrolling</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Scrolling (Vertical)</TableCell>
                    <TableCell>alt + Scrolling</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Scrolling (Horizontal)</TableCell>
                    <TableCell>alt + ⇧ + Scrolling</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Direct Editing</TableCell>
                    <TableCell>E</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Hand Tool</TableCell>
                    <TableCell>H</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Lasso Tool</TableCell>
                    <TableCell>L</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Space Tool</TableCell>
                    <TableCell>S</TableCell>
                </TableRow>
                </TableBody>
            </Table>
            </TableContainer>
            </TabPanel>
            <TabPanel value={value} index={1}>
            <TableContainer component={Paper} sx={{ minWidth: tableWidth }}>
            <Table>
                <TableBody>
                <TableRow>
                    <TableCell>Undo</TableCell>
                    <TableCell>⌘ + Z</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Redo</TableCell>
                    <TableCell>⌘ + ⇧ + Z</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Select All</TableCell>
                    <TableCell>⌘ + A</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Zooming</TableCell>
                    <TableCell>ctrl + Scrolling</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Scrolling (Vertical)</TableCell>
                    <TableCell>⌥ + Scrolling</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Scrolling (Horizontal)</TableCell>
                    <TableCell>⌥ + ⇧ + Scrolling</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Direct Editing</TableCell>
                    <TableCell>E</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Hand Tool</TableCell>
                    <TableCell>H</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Lasso Tool</TableCell>
                    <TableCell>L</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Space Tool</TableCell>
                    <TableCell>S</TableCell>
                </TableRow>
                </TableBody>
            </Table>
            </TableContainer>
            </TabPanel>
            </>
            </DialogContent>
        </Dialog>
    );
}