import { Box, Divider, IconButton, Dialog, DialogTitle, DialogContent, Typography, Link } from "@mui/material";
import { Close } from "@mui/icons-material";
import { LogoTitle } from "../Icon/LogoTitle";



export default function AboutDialog ({ isDialogOpen, handleCloseDialog }) {
    
    return(
        <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle>
                About...
                <IconButton
                    onClick={handleCloseDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                   <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >  
                   <LogoTitle fillColor="#03a9f4"/>
                    <Typography color="grey" >Version : demonstrator 0.1.0</Typography>
                    <Typography sx={{ px: 1, py: 3 }}>ArchiModel.net is a new free visual-modeling tool for creating ArchiMate models. The aim of ArchiModel.net is to provide an online and collaborative tool for a professional ArchiMate modeling.</Typography>
                    <Typography sx={{ px: 1, py: 0 }}>This is a demonstrator version with minimal functions and reduced support for ArchiMate specifications.
                    You are logged as a visitor profile and models in this sandbox can be created, updated or deleted by anyone until personal profile is available.
                    This is the beginning of a new adventure and if your interested in this project, find out <Link href="https://github.com/archimodel/archimodel-net/" target="_blank">here</Link> or send us an <Link href="mailto:contact@archimodel.net">email</Link>.</Typography>
                    <Typography sx={{ px: 1, py: 3 }}>ArchiModel is based on diagram-js modeler engine developped by <Link href="https://bpmn.io" target="_blank">BPMN.iO</Link> project.</Typography>
                    <Typography sx={{ px: 1, py: 0 }}>ArchiMate® is a modeling language for Enterprise Architecture and specifications are defined by <Link href="https://www.opengroup.org/archimate-forum/archimate-overview" target="_blank">The Open Group</Link>.</Typography>
                    <Typography sx={{ px: 1, py: 3 }}>Thanks to report bugs <Link href="https://github.com/archimodel/archimodel-net/issues" target="_blank">here</Link>.</Typography>
                    <Typography sx={{ px: 1, py: 1 }}>Donate and support ArchiModel.net</Typography>
                    <iframe src="https://github.com/sponsors/vbo75/button" title="Sponsor ArchiModel.net" height="32" width="114" style={{border: '0', borderRadius: '6px'}} target="_top"></iframe>
                    <Typography sx={{ px: 1, py: 3 }}></Typography>
                    <Typography color="grey" sx={{ px: 1 }}>Copyright © 2022 Vincent Boulet. All Rights Reserved.</Typography>
                    <Typography color="grey" sx={{ px: 1 }}>ArchiMate® is a registered trademark of The Open Group</Typography>
                    </Box>
            </DialogContent>
        </Dialog>
    );
}
