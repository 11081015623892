import { useEffect, useState } from "react";
//import { useMutation } from "@apollo/client";
//import { CREATE_MODEL, UPDATE_MODEL, MODELS_BY_USER } from "../graphql-operations";
import { Divider, TextField, Grid, styled, IconButton, CardActions, Dialog, DialogTitle, DialogContent, Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
//import { useUserContext } from "../UserContext";
//import useCustomSnackbar from "./CustomSnackbar/useCustomSnackbar";
import { useModeler } from "../ModelerContext";
//import { logger } from "../logger";

const CustomTextField = styled(TextField)({
    marginTop: 0,//2,
    marginBottom: 0, //3,
    "& .MuiFilledInput-root": {
      background: 'white'
    },
    "& .MuiFilledInput-root:hover": {
      background: 'white'
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: 'white'
    },
    "& .MuiFilledInput-root.Mui-disabled": {
      backgroundColor: 'white'
    },
  });

export default function SaveModelDialog ({ isDialogOpen, handleCloseDialog, nextStep }) {
    
    // by default, userID is set to Visitor's ID ("id-0")
//    const { user } = useUserContext();
    const {modeler, model_name, model_readOnly, saveModel} = useModeler();

//    const snackbar = useCustomSnackbar();
/*
    const [createModel] = useMutation(CREATE_MODEL, {
        refetchQueries: [
            {
                query: MODELS_BY_USER,
                variables: { query: {ownerID: user.userID } }
            }
        ],
        onCompleted: (data) => {
            var id = data.insertOneModel._id,
                name = data.insertOneModel.name;
            logger.log(`New model #${id} successfully inserted`);
            updateModelInnerID(id);
            saveModel();
            if (nextStep !== undefined) {
                nextStep();
            }
            snackbar.showSuccess(
                `${name} successfully saved.`);
        },
        onError: (error) => {
            logger.error(error);
            snackbar.showError(error.message);
        }});

    const [updateModel] = useMutation(UPDATE_MODEL, {
        refetchQueries: [
            {
                query: MODELS_BY_USER,
                variables: { query: {ownerID: user.userID } }
            }
        ],
        onCompleted: (data) => {
            var id = data.updateOneModel._id,
                name = data.updateOneModel.name;
            logger.log(`Model #${id} successfully updated`);
            saveModel();
            if (nextStep !== undefined) {
                nextStep();
            }
            snackbar.showSuccess(
                `${name} successfully updated.`);
        },
        onError: (error) => {
            logger.error(error);
            snackbar.showError(error.message);
        }});   
*/
    const handleSave = () => {
        handleCloseDialog();
        saveModel(modelName, modelDocumentation, nextStep);
        //if (nextStep !== undefined) {
        //    nextStep();
        //}

    /*    const today = new Date();
        updateModelName(modelName, modelDocumentation);
        var xmlmodel = await modeler.saveXML({ format: true });
        const input = { createdate: today ,
            lastupdate: today,
            description: modeler.getModel().documentation,
            modelID: modeler.getModel().id,
            name: modeler.getModel().name,
            ownerID: user.userID,
            xmlmodel: xmlmodel.xml
        };
        if (model_innerID === "0") {
            logger.log('Create new model in MDB');
            var data = Object.assign( input, {
                    createdate: today ,
                    lastupdate: today
                });
            await createModel({ variables: { data: data }});
        } else {
            logger.log('Update model #' + model_innerID);
            var set = Object.assign( input, {
                    lastupdate: today
                });                    
            await updateModel({ variables: { 
                query: { _id:  model_innerID },
                set: set }});
        }*/
    };

    const [modelName, setModelName] = useState('');
    const [modelDocumentation, setModelDocumentation] = useState('');

    // Get model's name and documentation from context when Dialog window is shown
    useEffect (() => {
        if (isDialogOpen) {
            setModelName(model_name);
            setModelDocumentation(modeler.getModel().documentation);
        }
    },[isDialogOpen, model_name, modeler]);

    const handleChangeName = (value) => {
        setModelName(value);
      };

      const handleChangeDocumentation = (value) => {
        setModelDocumentation(value); 
      };

    return(
        <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle>
                Save model
                <IconButton
                    onClick={handleCloseDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={1}
                  direction="column"
                >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography color="grey">
                        { modeler?.getModel()?.id }
                    </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CustomTextField
                        label="Name"
                        id="name-text-field"
                        value={modelName}
                        size="small"
                        variant="filled"
                        onChange={(e) => handleChangeName(e.target.value)}
                        fullWidth={true}
                        onFocus={(e) => e.target.select()}
                        autoFocus
                    />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CustomTextField
                        id="doc-text-field"
                        label="Documentation"
                        multiline
                        rows={4}
                        value={modelDocumentation}
                        onChange={(e) => handleChangeDocumentation(e.target.value)}
                        size="small"
                        variant="filled"
                        fullWidth={true}
                    />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CardActions sx={{ paddingTop: 0.5, paddingBottom: 0, display: "flex", justifyContent: "space-between" }} >
                        <Button size="small" disabled={model_readOnly} onClick={handleSave}>Save model</Button>
                        <Button size="small" onClick={handleCloseDialog}>Cancel</Button>
                    </CardActions>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
