import * as CX from './Concept';

export const RELATIONSHIPS_ALLOWED_FROM_IMP_MIG_DELIVRABLE = new Map([
  [CX.MOTIVATION_ASSESSMENT, 'no'],
  [CX.MOTIVATION_CONSTRAINT, 'rno'],
  [CX.MOTIVATION_DRIVER, 'no'],
  [CX.MOTIVATION_GOAL, 'rno'],
  [CX.MOTIVATION_MEANING, 'no'],
  [CX.MOTIVATION_OUTCOME, 'rno'],
  [CX.MOTIVATION_PRINCIPLE, 'rno'],
  [CX.MOTIVATION_REQUIREMENT, 'rno'],
  [CX.MOTIVATION_STAKEHOLDER, 'rno'],
  [CX.MOTIVATION_VALUE, 'no'],
  [CX.STRATEGY_CAPABILITY, 'ro'],
  [CX.STRATEGY_VALUE_STREAM, 'ro'],
  [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
  [CX.STRATEGY_RESOURCE, 'ro'],
  [CX.BUSINESS_ACTOR, 'ro'],
  [CX.BUSINESS_COLLABORATION, 'ro'],
  [CX.BUSINESS_EVENT, 'ro'],
  [CX.BUSINESS_FUNCTION, 'ro'],
  [CX.BUSINESS_INTERACTION, 'ro'],
  [CX.BUSINESS_INTERFACE, 'ro'],
  [CX.BUSINESS_OBJECT, 'ro'],
  [CX.BUSINESS_PROCESS, 'ro'],
  [CX.BUSINESS_ROLE, 'ro'],
  [CX.BUSINESS_SERVICE, 'ro'],
  [CX.BUSINESS_CONTRACT, 'ro'],
  [CX.BUSINESS_PRODUCT, 'ro'],
  [CX.BUSINESS_REPRESENTATION, 'ro'],
  [CX.APPLICATION_COLLABORATION, 'ro'],
  [CX.APPLICATION_COMPONENT, 'ro'],
  [CX.APPLICATION_EVENT, 'ro'],
  [CX.APPLICATION_FUNCTION, 'ro'],
  [CX.APPLICATION_INTERACTION, 'ro'],
  [CX.APPLICATION_INTERFACE, 'ro'],
  [CX.APPLICATION_PROCESS, 'ro'],
  [CX.APPLICATION_SERVICE, 'ro'],
  [CX.APPLICATION_DATA_OBJECT, 'ro'],
  [CX.TECHNOLOGY_ARTIFACT, 'ro'],
  [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'ro'],
  [CX.TECHNOLOGY_DEVICE, 'ro'],
  [CX.TECHNOLOGY_NODE, 'ro'],
  [CX.TECHNOLOGY_PATH, 'ro'],
  [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'ro'],
  [CX.TECHNOLOGY_COLLABORATION, 'ro'],
  [CX.TECHNOLOGY_EVENT, 'ro'],
  [CX.TECHNOLOGY_FUNCTION, 'ro'],
  [CX.TECHNOLOGY_INTERACTION, 'ro'],
  [CX.TECHNOLOGY_INTERFACE, 'ro'],
  [CX.TECHNOLOGY_PROCESS, 'ro'],
  [CX.TECHNOLOGY_SERVICE, 'ro'],
  [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'ro'],
  [CX.PHYSICAL_EQUIPMENT, 'ro'],
  [CX.PHYSICAL_FACILITY, 'ro'],
  [CX.PHYSICAL_MATERIAL, 'ro'],
  [CX.IMP_MIG_DELIVERABLE, 'scgo'],
  [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
  [CX.IMP_MIG_WORK_PACKAGE, 'o'],
  [CX.IMP_MIG_GAP, 'o'],
  [CX.IMP_MIG_PLATEAU, 'ro'],
  [CX.OTHER_LOCATION, 'ro'],
  [CX.OTHER_GROUPING, 'scgrno'],
  [CX.RELATIONSHIP_ACCESS, 'o'],
  [CX.RELATIONSHIP_AGGREGATION, 'o'],
  [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
  [CX.RELATIONSHIP_ASSOCIATION, 'o'],
  [CX.RELATIONSHIP_COMPOSITION, 'o'],
  [CX.RELATIONSHIP_FLOW, 'o'],
  [CX.RELATIONSHIP_INFLUENCE, 'o'],
  [CX.RELATIONSHIP_JUNCTION_AND, ''],
  [CX.RELATIONSHIP_JUNCTION_OR, ''],
  [CX.RELATIONSHIP_REALIZATION, 'o'],
  [CX.RELATIONSHIP_SERVING, 'o'],
  [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
  [CX.RELATIONSHIP_TRIGGERING, 'o']
]);

export const RELATIONSHIPS_ALLOWED_FROM_IMP_MIG_IMPLEMENTATION_EVENT = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'no'],
    [CX.MOTIVATION_CONSTRAINT, 'no'],
    [CX.MOTIVATION_DRIVER, 'no'],
    [CX.MOTIVATION_GOAL, 'no'],
    [CX.MOTIVATION_MEANING, 'no'],
    [CX.MOTIVATION_OUTCOME, 'no'],
    [CX.MOTIVATION_PRINCIPLE, 'no'],
    [CX.MOTIVATION_REQUIREMENT, 'no'],
    [CX.MOTIVATION_STAKEHOLDER, 'no'],
    [CX.MOTIVATION_VALUE, 'no'],
    [CX.STRATEGY_CAPABILITY, 'o'],
    [CX.STRATEGY_VALUE_STREAM, 'o'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'o'],
    [CX.STRATEGY_RESOURCE, 'o'],
    [CX.BUSINESS_ACTOR, 'o'],
    [CX.BUSINESS_COLLABORATION, 'o'],
    [CX.BUSINESS_EVENT, 'o'],
    [CX.BUSINESS_FUNCTION, 'o'],
    [CX.BUSINESS_INTERACTION, 'o'],
    [CX.BUSINESS_INTERFACE, 'o'],
    [CX.BUSINESS_OBJECT, 'o'],
    [CX.BUSINESS_PROCESS, 'o'],
    [CX.BUSINESS_ROLE, 'o'],
    [CX.BUSINESS_SERVICE, 'o'],
    [CX.BUSINESS_CONTRACT, 'o'],
    [CX.BUSINESS_PRODUCT, 'o'],
    [CX.BUSINESS_REPRESENTATION, 'o'],
    [CX.APPLICATION_COLLABORATION, 'o'],
    [CX.APPLICATION_COMPONENT, 'o'],
    [CX.APPLICATION_EVENT, 'o'],
    [CX.APPLICATION_FUNCTION, 'o'],
    [CX.APPLICATION_INTERACTION, 'o'],
    [CX.APPLICATION_INTERFACE, 'o'],
    [CX.APPLICATION_PROCESS, 'o'],
    [CX.APPLICATION_SERVICE, 'o'],
    [CX.APPLICATION_DATA_OBJECT, 'o'],
    [CX.TECHNOLOGY_ARTIFACT, 'o'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'o'],
    [CX.TECHNOLOGY_DEVICE, 'o'],
    [CX.TECHNOLOGY_NODE, 'o'],
    [CX.TECHNOLOGY_PATH, 'o'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'o'],
    [CX.TECHNOLOGY_COLLABORATION, 'o'],
    [CX.TECHNOLOGY_EVENT, 'o'],
    [CX.TECHNOLOGY_FUNCTION, 'o'],
    [CX.TECHNOLOGY_INTERACTION, 'o'],
    [CX.TECHNOLOGY_INTERFACE, 'o'],
    [CX.TECHNOLOGY_PROCESS, 'o'],
    [CX.TECHNOLOGY_SERVICE, 'o'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'o'],
    [CX.PHYSICAL_EQUIPMENT, 'o'],
    [CX.PHYSICAL_FACILITY, 'o'],
    [CX.PHYSICAL_MATERIAL, 'o'],
    [CX.IMP_MIG_DELIVERABLE, 'ao'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'scgtfo'],
    [CX.IMP_MIG_WORK_PACKAGE, 'tfo'],
    [CX.IMP_MIG_GAP, 'o'],
    [CX.IMP_MIG_PLATEAU, 'tfo'],
    [CX.OTHER_LOCATION, 'o'],
    [CX.OTHER_GROUPING, 'scgantfo'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
  ]);

export const RELATIONSHIPS_ALLOWED_FROM_IMP_MIG_WORK_PACKAGE = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'no'],
    [CX.MOTIVATION_CONSTRAINT, 'rno'],
    [CX.MOTIVATION_DRIVER, 'no'],
    [CX.MOTIVATION_GOAL, 'rno'],
    [CX.MOTIVATION_MEANING, 'no'],
    [CX.MOTIVATION_OUTCOME, 'rno'],
    [CX.MOTIVATION_PRINCIPLE, 'rno'],
    [CX.MOTIVATION_REQUIREMENT, 'rno'],
    [CX.MOTIVATION_STAKEHOLDER, 'rno'],
    [CX.MOTIVATION_VALUE, 'no'],
    [CX.STRATEGY_CAPABILITY, 'ro'],
    [CX.STRATEGY_VALUE_STREAM, 'ro'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
    [CX.STRATEGY_RESOURCE, 'ro'],
    [CX.BUSINESS_ACTOR, 'ro'],
    [CX.BUSINESS_COLLABORATION, 'ro'],
    [CX.BUSINESS_EVENT, 'ro'],
    [CX.BUSINESS_FUNCTION, 'ro'],
    [CX.BUSINESS_INTERACTION, 'ro'],
    [CX.BUSINESS_INTERFACE, 'ro'],
    [CX.BUSINESS_OBJECT, 'ro'],
    [CX.BUSINESS_PROCESS, 'ro'],
    [CX.BUSINESS_ROLE, 'ro'],
    [CX.BUSINESS_SERVICE, 'ro'],
    [CX.BUSINESS_CONTRACT, 'ro'],
    [CX.BUSINESS_PRODUCT, 'ro'],
    [CX.BUSINESS_REPRESENTATION, 'ro'],
    [CX.APPLICATION_COLLABORATION, 'ro'],
    [CX.APPLICATION_COMPONENT, 'ro'],
    [CX.APPLICATION_EVENT, 'ro'],
    [CX.APPLICATION_FUNCTION, 'ro'],
    [CX.APPLICATION_INTERACTION, 'ro'],
    [CX.APPLICATION_INTERFACE, 'ro'],
    [CX.APPLICATION_PROCESS, 'ro'],
    [CX.APPLICATION_SERVICE, 'ro'],
    [CX.APPLICATION_DATA_OBJECT, 'ro'],
    [CX.TECHNOLOGY_ARTIFACT, 'ro'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'ro'],
    [CX.TECHNOLOGY_DEVICE, 'ro'],
    [CX.TECHNOLOGY_NODE, 'ro'],
    [CX.TECHNOLOGY_PATH, 'ro'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'ro'],
    [CX.TECHNOLOGY_COLLABORATION, 'ro'],
    [CX.TECHNOLOGY_EVENT, 'ro'],
    [CX.TECHNOLOGY_FUNCTION, 'ro'],
    [CX.TECHNOLOGY_INTERACTION, 'ro'],
    [CX.TECHNOLOGY_INTERFACE, 'ro'],
    [CX.TECHNOLOGY_PROCESS, 'ro'],
    [CX.TECHNOLOGY_SERVICE, 'ro'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'ro'],
    [CX.PHYSICAL_EQUIPMENT, 'ro'],
    [CX.PHYSICAL_FACILITY, 'ro'],
    [CX.PHYSICAL_MATERIAL, 'ro'],
    [CX.IMP_MIG_DELIVERABLE, 'rao'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'tfo'],
    [CX.IMP_MIG_WORK_PACKAGE, 'scgtfo'],
    [CX.IMP_MIG_GAP, 'o'],
    [CX.IMP_MIG_PLATEAU, 'rtfo'],
    [CX.OTHER_LOCATION, 'ro'],
    [CX.OTHER_GROUPING, 'scgrantfo'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
  ]);
  
  export const RELATIONSHIPS_ALLOWED_FROM_IMP_MIG_GAP = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'o'],
    [CX.MOTIVATION_CONSTRAINT, 'o'],
    [CX.MOTIVATION_DRIVER, 'o'],
    [CX.MOTIVATION_GOAL, 'o'],
    [CX.MOTIVATION_MEANING, 'o'],
    [CX.MOTIVATION_OUTCOME, 'o'],
    [CX.MOTIVATION_PRINCIPLE, 'o'],
    [CX.MOTIVATION_REQUIREMENT, 'o'],
    [CX.MOTIVATION_STAKEHOLDER, 'o'],
    [CX.MOTIVATION_VALUE, 'o'],
    [CX.STRATEGY_CAPABILITY, 'o'],
    [CX.STRATEGY_VALUE_STREAM, 'o'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'o'],
    [CX.STRATEGY_RESOURCE, 'o'],
    [CX.BUSINESS_ACTOR, 'o'],
    [CX.BUSINESS_COLLABORATION, 'o'],
    [CX.BUSINESS_EVENT, 'o'],
    [CX.BUSINESS_FUNCTION, 'o'],
    [CX.BUSINESS_INTERACTION, 'o'],
    [CX.BUSINESS_INTERFACE, 'o'],
    [CX.BUSINESS_OBJECT, 'o'],
    [CX.BUSINESS_PROCESS, 'o'],
    [CX.BUSINESS_ROLE, 'o'],
    [CX.BUSINESS_SERVICE, 'o'],
    [CX.BUSINESS_CONTRACT, 'o'],
    [CX.BUSINESS_PRODUCT, 'o'],
    [CX.BUSINESS_REPRESENTATION, 'o'],
    [CX.APPLICATION_COLLABORATION, 'o'],
    [CX.APPLICATION_COMPONENT, 'o'],
    [CX.APPLICATION_EVENT, 'o'],
    [CX.APPLICATION_FUNCTION, 'o'],
    [CX.APPLICATION_INTERACTION, 'o'],
    [CX.APPLICATION_INTERFACE, 'o'],
    [CX.APPLICATION_PROCESS, 'o'],
    [CX.APPLICATION_SERVICE, 'o'],
    [CX.APPLICATION_DATA_OBJECT, 'o'],
    [CX.TECHNOLOGY_ARTIFACT, 'o'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'o'],
    [CX.TECHNOLOGY_DEVICE, 'o'],
    [CX.TECHNOLOGY_NODE, 'o'],
    [CX.TECHNOLOGY_PATH, 'o'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'o'],
    [CX.TECHNOLOGY_COLLABORATION, 'o'],
    [CX.TECHNOLOGY_EVENT, 'o'],
    [CX.TECHNOLOGY_FUNCTION, 'o'],
    [CX.TECHNOLOGY_INTERACTION, 'o'],
    [CX.TECHNOLOGY_INTERFACE, 'o'],
    [CX.TECHNOLOGY_PROCESS, 'o'],
    [CX.TECHNOLOGY_SERVICE, 'o'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'o'],
    [CX.PHYSICAL_EQUIPMENT, 'o'],
    [CX.PHYSICAL_FACILITY, 'o'],
    [CX.PHYSICAL_MATERIAL, 'o'],
    [CX.IMP_MIG_DELIVERABLE, 'o'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
    [CX.IMP_MIG_WORK_PACKAGE, 'o'],
    [CX.IMP_MIG_GAP, 'scgo'],
    [CX.IMP_MIG_PLATEAU, 'o'],
    [CX.OTHER_LOCATION, 'o'],
    [CX.OTHER_GROUPING, 'scgo'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
  ]);

  export const RELATIONSHIPS_ALLOWED_FROM_IMP_MIG_PLATEAU = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'no'],
    [CX.MOTIVATION_CONSTRAINT, 'cgrno'],
    [CX.MOTIVATION_DRIVER, 'no'],
    [CX.MOTIVATION_GOAL, 'cgrno'],
    [CX.MOTIVATION_MEANING, 'no'],
    [CX.MOTIVATION_OUTCOME, 'cgrno'],
    [CX.MOTIVATION_PRINCIPLE, 'rno'],
    [CX.MOTIVATION_REQUIREMENT, 'cgrno'],
    [CX.MOTIVATION_STAKEHOLDER, 'irno'],
    [CX.MOTIVATION_VALUE, 'no'],
    [CX.STRATEGY_CAPABILITY, 'cgro'],
    [CX.STRATEGY_VALUE_STREAM, 'cgro'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'cgro'],
    [CX.STRATEGY_RESOURCE, 'cgro'],
    [CX.BUSINESS_ACTOR, 'cgro'],
    [CX.BUSINESS_COLLABORATION, 'cgro'],
    [CX.BUSINESS_EVENT, 'cgro'],
    [CX.BUSINESS_FUNCTION, 'cgro'],
    [CX.BUSINESS_INTERACTION, 'cgro'],
    [CX.BUSINESS_INTERFACE, 'cgro'],
    [CX.BUSINESS_OBJECT, 'cgro'],
    [CX.BUSINESS_PROCESS, 'cgro'],
    [CX.BUSINESS_ROLE, 'cgro'],
    [CX.BUSINESS_SERVICE, 'cgro'],
    [CX.BUSINESS_CONTRACT, 'cgro'],
    [CX.BUSINESS_PRODUCT, 'cgro'],
    [CX.BUSINESS_REPRESENTATION, 'cgro'],
    [CX.APPLICATION_COLLABORATION, 'cgro'],
    [CX.APPLICATION_COMPONENT, 'cgro'],
    [CX.APPLICATION_EVENT, 'cgro'],
    [CX.APPLICATION_FUNCTION, 'cgro'],
    [CX.APPLICATION_INTERACTION, 'cgro'],
    [CX.APPLICATION_INTERFACE, 'cgro'],
    [CX.APPLICATION_PROCESS, 'cgro'],
    [CX.APPLICATION_SERVICE, 'cgro'],
    [CX.APPLICATION_DATA_OBJECT, 'cgro'],
    [CX.TECHNOLOGY_ARTIFACT, 'cgro'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'cgro'],
    [CX.TECHNOLOGY_DEVICE, 'cgro'],
    [CX.TECHNOLOGY_NODE, 'cgro'],
    [CX.TECHNOLOGY_PATH, 'cgro'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'cgro'],
    [CX.TECHNOLOGY_COLLABORATION, 'cgro'],
    [CX.TECHNOLOGY_EVENT, 'cgro'],
    [CX.TECHNOLOGY_FUNCTION, 'cgro'],
    [CX.TECHNOLOGY_INTERACTION, 'cgro'],
    [CX.TECHNOLOGY_INTERFACE, 'cgro'],
    [CX.TECHNOLOGY_PROCESS, 'cgro'],
    [CX.TECHNOLOGY_SERVICE, 'cgro'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'cgro'],
    [CX.PHYSICAL_EQUIPMENT, 'cgro'],
    [CX.PHYSICAL_FACILITY, 'cgro'],
    [CX.PHYSICAL_MATERIAL, 'cgro'],
    [CX.IMP_MIG_DELIVERABLE, 'ao'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'tfo'],
    [CX.IMP_MIG_WORK_PACKAGE, 'tfo'],
    [CX.IMP_MIG_GAP, 'o'],
    [CX.IMP_MIG_PLATEAU, 'scgtfo'],
    [CX.OTHER_LOCATION, 'cgro'],
    [CX.OTHER_GROUPING, 'scgirantfo'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
  ]);