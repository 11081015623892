import { Close  } from "@mui/icons-material";
import { Button, Dialog, DialogTitle, DialogContent, Divider, CardActions, Typography, IconButton } from "@mui/material";
import { useState, } from "react";
import { useModeler } from "../ModelerContext";
import SaveModelDialog from "./SaveModelDialog";

export default function SaveCurrentModelDialog ({ isDialogOpen, handleCloseDialog, nextStep }) {

    const {model_name} = useModeler();
    const [saveModelDialogIsOpen, setSaveModelDialogIsOpen] = useState(false);

    const handleOpenSaveModelDialog = () => {
        setSaveModelDialogIsOpen(true);
        handleCloseDialog();
    };

    const handleCloseSaveModelDialog = () => {
        setSaveModelDialogIsOpen(false);
    };

    const handleNextStep = () => {
        nextStep();
        handleCloseDialog();
    }

    return(
        <>
        <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
        >
        <DialogTitle>
            Save current model ?
                <IconButton
                    onClick={handleCloseDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Typography>Changes on current model '{model_name}' : do you want to save changes before open a new model ?</Typography>
                <CardActions sx={{ paddingBottom: 0.5, display: "flex", justifyContent: "space-between" }} >
                    <Button size="small" onClick={handleOpenSaveModelDialog}>Save current model</Button>
                    <Button size="small" color="error" onClick={handleNextStep}>Don't save current model</Button>
                    <Button size="small" onClick={handleCloseDialog}>Cancel</Button>
                </CardActions>
            </DialogContent>
        </Dialog>
        <SaveModelDialog 
            isDialogOpen={saveModelDialogIsOpen}
            handleCloseDialog={handleCloseSaveModelDialog}
            nextStep={nextStep}
        />
        </>
    );

}