import * as CX from './Concept';

export const RELATIONSHIPS_ALLOWED_FROM_PHYSICAL_MATERIAL = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'no'],
    [CX.MOTIVATION_CONSTRAINT, 'rno'],
    [CX.MOTIVATION_DRIVER, 'no'],
    [CX.MOTIVATION_GOAL, 'rno'],
    [CX.MOTIVATION_MEANING, 'no'],
    [CX.MOTIVATION_OUTCOME, 'rno'],
    [CX.MOTIVATION_PRINCIPLE, 'rno'],
    [CX.MOTIVATION_REQUIREMENT, 'rno'],
    [CX.MOTIVATION_STAKEHOLDER, 'no'],
    [CX.MOTIVATION_VALUE, 'no'],
    [CX.STRATEGY_CAPABILITY, 'ro'],
    [CX.STRATEGY_VALUE_STREAM, 'ro'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
    [CX.STRATEGY_RESOURCE, 'ro'],
    [CX.BUSINESS_ACTOR, 'o'],
    [CX.BUSINESS_COLLABORATION, 'o'],
    [CX.BUSINESS_EVENT, 'ro'],
    [CX.BUSINESS_FUNCTION, 'ro'],
    [CX.BUSINESS_INTERACTION, 'ro'],
    [CX.BUSINESS_INTERFACE, 'ro'],
    [CX.BUSINESS_OBJECT, 'ro'],
    [CX.BUSINESS_PROCESS, 'ro'],
    [CX.BUSINESS_ROLE, 'o'],
    [CX.BUSINESS_SERVICE, 'ro'],
    [CX.BUSINESS_CONTRACT, 'ro'],
    [CX.BUSINESS_PRODUCT, 'o'],
    [CX.BUSINESS_REPRESENTATION, 'o'],
    [CX.APPLICATION_COLLABORATION, 'ro'],
    [CX.APPLICATION_COMPONENT, 'ro'],
    [CX.APPLICATION_EVENT, 'ro'],
    [CX.APPLICATION_FUNCTION, 'ro'],
    [CX.APPLICATION_INTERACTION, 'ro'],
    [CX.APPLICATION_INTERFACE, 'ro'],
    [CX.APPLICATION_PROCESS, 'ro'],
    [CX.APPLICATION_SERVICE, 'ro'],
    [CX.APPLICATION_DATA_OBJECT, 'ro'],
    [CX.TECHNOLOGY_ARTIFACT, 'ro'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'o'],
    [CX.TECHNOLOGY_DEVICE, 'ro'],
    [CX.TECHNOLOGY_NODE, 'o'],
    [CX.TECHNOLOGY_PATH, 'o'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'ro'],
    [CX.TECHNOLOGY_COLLABORATION, 'o'],
    [CX.TECHNOLOGY_EVENT, 'ro'],
    [CX.TECHNOLOGY_FUNCTION, 'ro'],
    [CX.TECHNOLOGY_INTERACTION, 'ro'],
    [CX.TECHNOLOGY_INTERFACE, 'ro'],
    [CX.TECHNOLOGY_PROCESS, 'ro'],
    [CX.TECHNOLOGY_SERVICE, 'ro'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'o'],
    [CX.PHYSICAL_EQUIPMENT, 'ro'],
    [CX.PHYSICAL_FACILITY, 'o'],
    [CX.PHYSICAL_MATERIAL, 'scgro'],
    [CX.IMP_MIG_DELIVERABLE, 'o'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
    [CX.IMP_MIG_WORK_PACKAGE, 'o'],
    [CX.IMP_MIG_GAP, 'o'],
    [CX.IMP_MIG_PLATEAU, 'o'],
    [CX.OTHER_LOCATION, 'o'],
    [CX.OTHER_GROUPING, 'scgrno'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
]);

export const RELATIONSHIPS_ALLOWED_FROM_PHYSICAL_DISTRIBUTION_NETWORK = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'no'],
    [CX.MOTIVATION_CONSTRAINT, 'rno'],
    [CX.MOTIVATION_DRIVER, 'no'],
    [CX.MOTIVATION_GOAL, 'rno'],
    [CX.MOTIVATION_MEANING, 'no'],
    [CX.MOTIVATION_OUTCOME, 'rno'],
    [CX.MOTIVATION_PRINCIPLE, 'rno'],
    [CX.MOTIVATION_REQUIREMENT, 'rno'],
    [CX.MOTIVATION_STAKEHOLDER, 'irno'],
    [CX.MOTIVATION_VALUE, 'no'],
    [CX.STRATEGY_CAPABILITY, 'ro'],
    [CX.STRATEGY_VALUE_STREAM, 'ro'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
    [CX.STRATEGY_RESOURCE, 'ro'],
    [CX.BUSINESS_ACTOR, 'irvtfo'],
    [CX.BUSINESS_COLLABORATION, 'irvtfo'],
    [CX.BUSINESS_EVENT, 'irvtfo'],
    [CX.BUSINESS_FUNCTION, 'irvtfo'],
    [CX.BUSINESS_INTERACTION, 'irvtfo'],
    [CX.BUSINESS_INTERFACE, 'irvtfo'],
    [CX.BUSINESS_OBJECT, 'ao'],
    [CX.BUSINESS_PROCESS, 'irvtfo'],
    [CX.BUSINESS_ROLE, 'irvtfo'],
    [CX.BUSINESS_SERVICE, 'irvtfo'],
    [CX.BUSINESS_CONTRACT, 'ao'],
    [CX.BUSINESS_PRODUCT, 'vtfo'],
    [CX.BUSINESS_REPRESENTATION, 'ao'],
    [CX.APPLICATION_COLLABORATION, 'rvtfo'],
    [CX.APPLICATION_COMPONENT, 'rvtfo'],
    [CX.APPLICATION_EVENT, 'rvtfo'],
    [CX.APPLICATION_FUNCTION, 'rvtfo'],
    [CX.APPLICATION_INTERACTION, 'rvtfo'],
    [CX.APPLICATION_INTERFACE, 'rvtfo'],
    [CX.APPLICATION_PROCESS, 'rvtfo'],
    [CX.APPLICATION_SERVICE, 'rvtfo'],
    [CX.APPLICATION_DATA_OBJECT, 'ao'],
    [CX.TECHNOLOGY_ARTIFACT, 'iao'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'vtfo'],
    [CX.TECHNOLOGY_DEVICE, 'girvtfo'],
    [CX.TECHNOLOGY_NODE, 'girvtfo'],
    [CX.TECHNOLOGY_PATH, 'rvtfo'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'girvtfo'],
    [CX.TECHNOLOGY_COLLABORATION, 'rvtfo'],
    [CX.TECHNOLOGY_EVENT, 'irvtfo'],
    [CX.TECHNOLOGY_FUNCTION, 'irvtfo'],
    [CX.TECHNOLOGY_INTERACTION, 'irvtfo'],
    [CX.TECHNOLOGY_INTERFACE, 'girvtfo'],
    [CX.TECHNOLOGY_PROCESS, 'irvtfo'],
    [CX.TECHNOLOGY_SERVICE, 'irvtfo'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'scgvtfo'],
    [CX.PHYSICAL_EQUIPMENT, 'girvtfo'],
    [CX.PHYSICAL_FACILITY, 'girvtfo'],
    [CX.PHYSICAL_MATERIAL, 'iao'],
    [CX.IMP_MIG_DELIVERABLE, 'o'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
    [CX.IMP_MIG_WORK_PACKAGE, 'io'],
    [CX.IMP_MIG_GAP, 'io'],
    [CX.IMP_MIG_PLATEAU, 'o'],
    [CX.OTHER_LOCATION, 'vtfo'],
    [CX.OTHER_GROUPING, 'scgirvantfo'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
]);

export const RELATIONSHIPS_ALLOWED_FROM_PHYSICAL_EQUIPMENT = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'no'],
    [CX.MOTIVATION_CONSTRAINT, 'rno'],
    [CX.MOTIVATION_DRIVER, 'no'],
    [CX.MOTIVATION_GOAL, 'rno'],
    [CX.MOTIVATION_MEANING, 'no'],
    [CX.MOTIVATION_OUTCOME, 'rno'],
    [CX.MOTIVATION_PRINCIPLE, 'rno'],
    [CX.MOTIVATION_REQUIREMENT, 'rno'],
    [CX.MOTIVATION_STAKEHOLDER, 'no'],
    [CX.MOTIVATION_VALUE, 'no'],
    [CX.STRATEGY_CAPABILITY, 'ro'],
    [CX.STRATEGY_VALUE_STREAM, 'ro'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
    [CX.STRATEGY_RESOURCE, 'ro'],
    [CX.BUSINESS_ACTOR, 'vtfo'],
    [CX.BUSINESS_COLLABORATION, 'vtfo'],
    [CX.BUSINESS_EVENT, 'rvtfo'],
    [CX.BUSINESS_FUNCTION, 'rvtfo'],
    [CX.BUSINESS_INTERACTION, 'rvtfo'],
    [CX.BUSINESS_INTERFACE, 'rvtfo'],
    [CX.BUSINESS_OBJECT, 'ao'],
    [CX.BUSINESS_PROCESS, 'rvtfo'],
    [CX.BUSINESS_ROLE, 'vtfo'],
    [CX.BUSINESS_SERVICE, 'rvtfo'],
    [CX.BUSINESS_CONTRACT, 'ao'],
    [CX.BUSINESS_PRODUCT, 'vtfo'],
    [CX.BUSINESS_REPRESENTATION, 'ao'],
    [CX.APPLICATION_COLLABORATION, 'rvtfo'],
    [CX.APPLICATION_COMPONENT, 'rvtfo'],
    [CX.APPLICATION_EVENT, 'rvtfo'],
    [CX.APPLICATION_FUNCTION, 'rvtfo'],
    [CX.APPLICATION_INTERACTION, 'rvtfo'],
    [CX.APPLICATION_INTERFACE, 'rvtfo'],
    [CX.APPLICATION_PROCESS, 'rvtfo'],
    [CX.APPLICATION_SERVICE, 'rvtfo'],
    [CX.APPLICATION_DATA_OBJECT, 'ao'],
    [CX.TECHNOLOGY_ARTIFACT, 'iao'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'vtfo'],
    [CX.TECHNOLOGY_DEVICE, 'cgrvtfo'],
    [CX.TECHNOLOGY_NODE, 'vtfo'],
    [CX.TECHNOLOGY_PATH, 'vtfo'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'cgirvtfo'],
    [CX.TECHNOLOGY_COLLABORATION, 'vtfo'],
    [CX.TECHNOLOGY_EVENT, 'irvtfo'],
    [CX.TECHNOLOGY_FUNCTION, 'irvtfo'],
    [CX.TECHNOLOGY_INTERACTION, 'irvtfo'],
    [CX.TECHNOLOGY_INTERFACE, 'cgirvtfo'],
    [CX.TECHNOLOGY_PROCESS, 'irvtfo'],
    [CX.TECHNOLOGY_SERVICE, 'irvtfo'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'vtfo'],
    [CX.PHYSICAL_EQUIPMENT, 'scgrvtfo'],
    [CX.PHYSICAL_FACILITY, 'vtfo'],
    [CX.PHYSICAL_MATERIAL, 'iao'],
    [CX.IMP_MIG_DELIVERABLE, 'o'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
    [CX.IMP_MIG_WORK_PACKAGE, 'o'],
    [CX.IMP_MIG_GAP, 'o'],
    [CX.IMP_MIG_PLATEAU, 'o'],
    [CX.OTHER_LOCATION, 'vtfo'],
    [CX.OTHER_GROUPING, 'scgirvantfo'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
]);

export const RELATIONSHIPS_ALLOWED_FROM_PHYSICAL_FACILITY = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'no'],
    [CX.MOTIVATION_CONSTRAINT, 'rno'],
    [CX.MOTIVATION_DRIVER, 'no'],
    [CX.MOTIVATION_GOAL, 'rno'],
    [CX.MOTIVATION_MEANING, 'no'],
    [CX.MOTIVATION_OUTCOME, 'rno'],
    [CX.MOTIVATION_PRINCIPLE, 'rno'],
    [CX.MOTIVATION_REQUIREMENT, 'rno'],
    [CX.MOTIVATION_STAKEHOLDER, 'ino'],
    [CX.MOTIVATION_VALUE, 'no'],
    [CX.STRATEGY_CAPABILITY, 'ro'],
    [CX.STRATEGY_VALUE_STREAM, 'ro'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
    [CX.STRATEGY_RESOURCE, 'ro'],
    [CX.BUSINESS_ACTOR, 'ivtfo'],
    [CX.BUSINESS_COLLABORATION, 'ivtfo'],
    [CX.BUSINESS_EVENT, 'irvtfo'],
    [CX.BUSINESS_FUNCTION, 'irvtfo'],
    [CX.BUSINESS_INTERACTION, 'irvtfo'],
    [CX.BUSINESS_INTERFACE, 'irvtfo'],
    [CX.BUSINESS_OBJECT, 'ao'],
    [CX.BUSINESS_PROCESS, 'irvtfo'],
    [CX.BUSINESS_ROLE, 'ivtfo'],
    [CX.BUSINESS_SERVICE, 'irvtfo'],
    [CX.BUSINESS_CONTRACT, 'ao'],
    [CX.BUSINESS_PRODUCT, 'vtfo'],
    [CX.BUSINESS_REPRESENTATION, 'ao'],
    [CX.APPLICATION_COLLABORATION, 'rvtfo'],
    [CX.APPLICATION_COMPONENT, 'rvtfo'],
    [CX.APPLICATION_EVENT, 'rvtfo'],
    [CX.APPLICATION_FUNCTION, 'rvtfo'],
    [CX.APPLICATION_INTERACTION, 'rvtfo'],
    [CX.APPLICATION_INTERFACE, 'rvtfo'],
    [CX.APPLICATION_PROCESS, 'rvtfo'],
    [CX.APPLICATION_SERVICE, 'rvtfo'],
    [CX.APPLICATION_DATA_OBJECT, 'ao'],
    [CX.TECHNOLOGY_ARTIFACT, 'iao'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'vtfo'],
    [CX.TECHNOLOGY_DEVICE, 'cgirvtfo'],
    [CX.TECHNOLOGY_NODE, 'cgivtfo'],
    [CX.TECHNOLOGY_PATH, 'vtfo'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'cgirvtfo'],
    [CX.TECHNOLOGY_COLLABORATION, 'vtfo'],
    [CX.TECHNOLOGY_EVENT, 'irvtfo'],
    [CX.TECHNOLOGY_FUNCTION, 'irvtfo'],
    [CX.TECHNOLOGY_INTERACTION, 'irvtfo'],
    [CX.TECHNOLOGY_INTERFACE, 'cgirvtfo'],
    [CX.TECHNOLOGY_PROCESS, 'irvtfo'],
    [CX.TECHNOLOGY_SERVICE, 'irvtfo'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'vtfo'],
    [CX.PHYSICAL_EQUIPMENT, 'cgirvtfo'],
    [CX.PHYSICAL_FACILITY, 'scgivtfo'],
    [CX.PHYSICAL_MATERIAL, 'iao'],
    [CX.IMP_MIG_DELIVERABLE, 'o'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'io'],
    [CX.IMP_MIG_WORK_PACKAGE, 'io'],
    [CX.IMP_MIG_GAP, 'o'],
    [CX.IMP_MIG_PLATEAU, 'o'],
    [CX.OTHER_LOCATION, 'vtfo'],
    [CX.OTHER_GROUPING, 'scgirvantfo'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
]);