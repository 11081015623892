import React, { useEffect, useState, useCallback } from 'react';
import { Close, EditOutlined, MenuOpen } from '@mui/icons-material';
import { Grid, Fab, Typography, IconButton, Toolbar, Drawer, Divider, TextField, styled } from '@mui/material';
import { NOTE, VIEW, getTypeName } from 'archimate-js/lib/util/ModelUtil';
import { matchIsValidColor, MuiColorInput } from 'mui-color-input';
import { useElementReducer, ELEMENT_ACTIONS, ARCHIMATE_NODE, ARCHIMATE_CONNECTION } from './ElementReducer';
import { useModeler } from '../../ModelerContext';
import UpdateModelDialog from '../UpdateModelDialog';
import { getArchimateIcon } from '../../Icon/IconsMap';
import { logger } from '../../logger';

const drawerWidth = 240;
const appbarHeight = 38;

const CustomTextField = styled(TextField)({
  marginTop: 0,//2,
  marginBottom: 0, //3,
  background: 'white',
  "& .MuiInputBase-input": {
    paddingLeft: 6,
    paddingRight: 4,
  },
  "& .MuiInputLabel-root": {
    paddingLeft: 6,
  },
  "& .MuiInputBase-root:hover": {
    //borderBottom: "2px solid #ff0000",
  },
  /* "& .MuiFilledInput-root": {
    background: 'white'
  },
  "& .MuiFilledInput-root:hover": {
    background: 'white'
  },
  "& .MuiFilledInput-root.Mui-focused": {
    backgroundColor: 'white'
  },
  "& .MuiFilledInput-root.Mui-disabled": {
    backgroundColor: 'white'
  }, */
});

const CustomColorInput = styled(MuiColorInput)({
  marginTop: 0,
  marginBottom: 0,
  background: 'white',
  "& .MuiInputBase-input": {
    //paddingLeft: 6,
    //paddingRight: 4,
  },
  "& .MuiInputLabel-root": {
    paddingLeft: 6,
  },
  /* "& .MuiFilledInput-root": {
    background: 'white'
  },
  "& .MuiFilledInput-root:hover": {
    background: 'white'
  },
  "& .MuiFilledInput-root.Mui-focused": {
    backgroundColor: 'white'
  },
  "& .MuiFilledInput-root.Mui-disabled": {
    backgroundColor: 'white'
  }, */
  "& .MuiColorInput-Button": {
    //boxShadow: 'none',
    marginLeft: 8,
    marginBottom: 6,
    height: 16,
    width: 14
  },
});

export default function PropertiesPanel() {


  const {modeler, model_name, model_unsaved, refresh, refreshed, changed} = useModeler();
  const [figure, setFigure] = useState();
  const [drawerIsOpen, setDrawerIsOpen] = useState(true);
  const [updateModelDialogIsOpen, setUpdateModelDialogIsOpen] = useState(false);
  const [element, dispatch] = useElementReducer();

  const initElement = useCallback((element) => {
    dispatch({
      type: ELEMENT_ACTIONS.INIT_ELEMENT,
      payload: element
    });
  }, [dispatch]);

  useEffect (() => {
    logger.log("useEffect => modeler triggering")
    if (modeler !== null) {
      logger.log("useEffect => start listening eventBus")
      modeler.get('eventBus').on([ 'selection.changed' ], 750, function(event) {
        logger.log(event);
        if (event.newSelection.length >= 1) { 
          var newSelection = event.newSelection[0];
          if (newSelection && newSelection.type !== 'archimate:Relationship') {
            initElement(newSelection);
            setFigure(newSelection);
          }
        } else {
          var rootElement = modeler.get('canvas').getRootElement();
          initElement(rootElement);
          setFigure(rootElement);
        }

      });
      modeler.get('eventBus').on([ 'element.changed' ], 750, function(event) {
        //'selection.changed', 'element.click', 'shape.add', 'shape.selected',
        //'element.mousedown', 'element.mouseup', 'element.hover'
        logger.log(event);
        if (event.element && event.element.type !== 'archimate:Relationship') {
          initElement(event.element);
          setFigure(event.element);
        }
        if (!model_unsaved) {
          logger.log('useEffect element.changed => model changed');
          changed();
        }
      });
    }
  },[modeler, initElement, model_unsaved, changed]);

  useEffect (() => {
    logger.log("useEffect => modeler, refresh triggering")
    if (modeler !== null && refresh) {
      logger.log("useEffect => set canvas' rootElement")
      var rootElement = modeler.get('canvas').getRootElement();
      initElement(rootElement);
      /*dispatch({
        type: ELEMENT_ACTIONS.INIT_ELEMENT,
        payload: modeler.get('canvas').getRootElement()
      });*/
      setFigure(rootElement);
      refreshed();
    }
  },[modeler, refresh, refreshed, initElement]);

  const updateElement = (valueName, value) => {
    dispatch({
      type: ELEMENT_ACTIONS.UPDATE_INPUT,
      payload: { name: valueName, value: value },
    });
    if (!model_unsaved) {
      logger.log('update element => model changed');
      changed();
    }
  };

  const handleChangeName = (value) => {
    /*dispatch({
      type: ELEMENT_ACTIONS.UPDATE_INPUT,
      payload: { name: 'name', value: value },
    });*/
    updateElement('name', value);

    if (element.archiType === ARCHIMATE_NODE || element.archiType === ARCHIMATE_CONNECTION) {
      //figure.businessObject.elementRef.name = value;
      //modeler.get('eventBus').fire('element.changed', { element: figure });
      modeler.get('commandStack').execute('element.updateLabel', {
        element: figure,
        newLabel: value,
      });
    } else if (element.type === VIEW) {
      figure.businessObject.name = value;
    }

  };

  const handleChangeLabel = (value) => {
    /*dispatch({
      type: ELEMENT_ACTIONS.UPDATE_INPUT,
      payload: { name: 'label', value: value },
    });*/
    updateElement('label', value);

    if (element.type === NOTE) {
      modeler.get('commandStack').execute('element.updateLabel', {
        element: figure,
        newLabel: value,
      });
    }

  };

  const handleChangeDocumentation = (value) => {
    /*dispatch({
      type: ELEMENT_ACTIONS.UPDATE_INPUT,
      payload: { name: 'documentation', value: value },
    });*/
    updateElement('documentation', value);

    if (element.archiType === ARCHIMATE_NODE) {
      figure.businessObject.elementRef.documentation = value;
    } else if (element.archiType === ARCHIMATE_CONNECTION) {
      figure.businessObject.relationshipRef.documentation = value;
    } else if (element.type === VIEW) {
      figure.businessObject.documentation = value;
    }
  };

  const handleChangeFillColor = (color) => {
    if(matchIsValidColor(color)) {
      /*dispatch({
        type: ELEMENT_ACTIONS.UPDATE_INPUT,
        payload: { name: 'fillColor', value: color },
      });*/
      updateElement('fillColor', color);
      if (element.archiType === ARCHIMATE_NODE || element.type === NOTE) {
        modeler.get('commandStack').execute('element.setColor', { elements: [ figure ], property: {name: 'fillColor', value: color} });
      }
    }
  };

  const handleChangeLineColor = (color) => {
    if(matchIsValidColor(color)) {
      /*dispatch({
        type: ELEMENT_ACTIONS.UPDATE_INPUT,
        payload: { name: 'fillColor', value: color },
      });*/
      updateElement('lineColor', color);
      if (element.archiType === ARCHIMATE_NODE || element.archiType === ARCHIMATE_CONNECTION || element.type === NOTE) {
        modeler.get('commandStack').execute('element.setColor', { elements: [ figure ], property: {name: 'lineColor', value: color} });
      }
    }
  };


  /*
  const handleBlur= () => {
    logger.log("onBlur");
  }
*/
  const handleOpenDrawer = () => {
    setDrawerIsOpen(true);
  }

  const handleCloseDrawer = () => {
    setDrawerIsOpen(false);
  }

  const handleOpenUpdateModelDialog = () => {
    setUpdateModelDialogIsOpen(true);    
  }

  const handleCloseUpdateModelDialog = () => {
    setUpdateModelDialogIsOpen(false);
  }

  return (
      <>
      <UpdateModelDialog
          isDialogOpen={updateModelDialogIsOpen}
          handleCloseDialog={handleCloseUpdateModelDialog}></UpdateModelDialog>
      <Fab size="small" aria-label="open menu" sx={{ margin: 0,
          top: 20,
          left: 'auto',
          bottom:'auto',
          right: 20,
          position: 'fixed',
          //width: 30,
          //height: 25
          boxShadow: 1
         }} onClick={handleOpenDrawer} >
        <MenuOpen fontSize="small" />
      </Fab>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            height: `calc(100% - ${appbarHeight}px)`,
            boxSizing: 'border-box',
            backgroundColor: 'rgb(246, 246, 246)',
            boxShadow: 1
          },
        }}
        //variant="permanent"
        anchor="right"
        variant="persistent"
        open={drawerIsOpen}
      >
        <Toolbar disableGutters variant="dense">
          <Typography
            noWrap
            variant="h6"
            sx={{flexGrow: 1, marginLeft: 1}}
          >
            { model_name || 'Loading...' }
          </Typography>
          <IconButton size="small" sx={{marginRight: 0.5}} onClick={handleOpenUpdateModelDialog} >
            <EditOutlined fontSize="small" />
          </IconButton>
          <IconButton size="small" sx={{marginRight: 0.5}} onClick={handleCloseDrawer} >
            <Close fontSize="small" />
          </IconButton>
        </Toolbar>
        <Divider />
        { element.type && 
        <Grid container direction="row" alignItems="center" >
          &nbsp;&nbsp;{ getArchimateIcon(element.type) }
        <Typography
            noWrap
            sx={{marginLeft: 1, marginTop: 0.5, marginBottom: 0.5}}
          >{ getTypeName(element.type) }</Typography>
        </Grid>
        }
        { element.type && (((((element.archiType === ARCHIMATE_NODE) && (element.type !== NOTE)) || element.archiType === ARCHIMATE_CONNECTION)) || element.type === VIEW) && 
        <CustomTextField
          label="Name"
          id="name-text-field"
          value={ element.name }
          size="small"
          variant="standard"
          onChange={(e) => handleChangeName(e.target.value)}
        />
        }
        { element.type && (((((element.archiType === ARCHIMATE_NODE) && (element.type !== NOTE)) || element.archiType === ARCHIMATE_CONNECTION)) || element.type === VIEW) && 
        <CustomTextField
          id="doc-text-field"
          label="Documentation"
          multiline
          rows={4}
          value={ element.documentation }
          onChange={(e) => handleChangeDocumentation(e.target.value)}
          size="small"
          variant="standard"
          //margin="dense"
        />
        }
        { element.type && element.type === NOTE &&
        <CustomTextField
          id="label-text-field"
          label="Label"
          multiline
          rows={4}
          value={ element.label }
          onChange={(e) => handleChangeLabel(e.target.value)}
          size="small"
          variant="standard"
          //margin="dense"
        />
        }
        { element.fillColor!==null &&
        <CustomColorInput
          id="fillcolor-input-field"
          format="hex8"
          fallbackValue="#cccccc"
          value={element.fillColor}
          onChange={handleChangeFillColor}
          label="Fill color"
          size="small"
          variant="standard"
        />
        }
        { element.lineColor!==null &&
        <CustomColorInput
          id="linecolor-input-field"
          format="hex8"
          fallbackValue="#cccccc"
          value={element.lineColor}
          onChange={handleChangeLineColor}
          label="Line color"
          size="small"
          variant="standard"
        />
        }
      </Drawer>
      </>
  );
}