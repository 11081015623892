import { useState, useEffect } from 'react';
// import UploadModelIcon from '@mui/icons-material/UploadFileRounded';
import NewModelIcon from '@mui/icons-material/NoteAdd';
import OpenModelIcon from '@mui/icons-material/FileOpen';
import SaveModelIcon from '@mui/icons-material/Task';
import { ZoomOutMap, ZoomInMap } from '@mui/icons-material';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { Tooltip, Zoom, Typography, Box, AppBar, IconButton, Toolbar, useTheme } from '@mui/material';
import KeyboardDialog from './KeyboardDialog';
import OpenModelDialog from './OpenModelDialog';
import SaveModelDialog from './SaveModelDialog';
import { LogoTitle } from '../Icon/LogoTitle';
import SaveCurrentModelDialog from './SaveCurrentModelDialog';
import { useModeler } from '../ModelerContext';
import UserMenu from './UserMenu';
import { logger } from '../logger';

const iconSize = '26px'; // 'medium' or 'large'

export default function MainAppBar() {

    const [isFullscreen, setIsFullscreen] = useState(false);
    const [keyboardDialogIsOpen, setKeyboardDialogIsOpen] = useState(false);
    const [openModelDialogIsOpen, setOpenModelDialogIsOpen] = useState(false);
    const [saveModelDialogIsOpen, setSaveModelDialogIsOpen] = useState(false);
    const [saveCurrentModelDialogIsOpen, setSaveCurrentModelDialogIsOpen] = useState(false);
    const theme = useTheme();
    const {model_unsaved, newModel} = useModeler();

    const handleOpenOpenModelDialog = () => {
      logger.log('Open OpenModel dialog');
      setOpenModelDialogIsOpen(true);
    };

    const handleCloseOpenModelDialog = () => {
        logger.log('Close OpenModel dialog');
        setOpenModelDialogIsOpen(false);
    };

    const handleOpenSaveModelDialog = () => {
      logger.log('Open SaveModel dialog');
      setSaveModelDialogIsOpen(true);
    };

    const handleCloseSaveModelDialog = () => {
        logger.log('Close SaveModel dialog');
        setSaveModelDialogIsOpen(false);
    };

    const handleOpenKeyboardDialog = () => {
      logger.log('Open Keyboard dialog');
      setKeyboardDialogIsOpen(true);
    };

    const handleCloseKeyboardDialog = () => {
        logger.log('Close Keyboard dialog');
        setKeyboardDialogIsOpen(false);
    };

    /* const handleUploadModel = () => {
      logger.log("Upload model");
    }; */   

    const handleOpenNewModel = () => {
      logger.log("Open new model");
      if (model_unsaved) {
        setSaveCurrentModelDialogIsOpen(true);
      } else {
        openNewModel();
      }
    };

    const openNewModel = () => {
      newModel();
    };

    const handleCloseSaveCurrentModelDialog = () => {
      setSaveCurrentModelDialogIsOpen(false);
    }

    useEffect(() => {
      const onFullscreenChange = () => {
        setIsFullscreen(Boolean(document.fullscreenElement));
      }
            
      document.addEventListener('fullscreenchange', onFullscreenChange);
    
      return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, []);

    const handleToggleFullscreen = () => {
      logger.log("Toogle fullscreen mode");
      if (isFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      } else {
        document.body.requestFullscreen();
        setIsFullscreen(true);
      }
    };
    
    return (
          <AppBar position="fixed" color="primary" sx={{top: 'auto', bottom: 0, 
          zIndex: theme.zIndex.drawer + 1,
          transform: 'translateZ(0)'
          }} >
            <Toolbar disableGutters variant="dense" >
            <Box sx={{ px: 1}} ></Box>
            <LogoTitle fillColor="#ffffff" /><Box sx={{ px: 1}} ></Box>
                <Typography
                    noWrap
                    color="white"
                    variant="h5"
                > | </Typography>
                <Box sx={{ flexGrow: 1 }}>
                    <Tooltip title="New model" TransitionComponent={Zoom} arrow>
                    <IconButton 
                        size="small"
                        onClick={handleOpenNewModel}
                        sx={{ ml: 1.1, color: 'white'}}
                    >
                        <NewModelIcon sx={{ fontSize: iconSize }} />
                    </IconButton>
                    </Tooltip>
                    <SaveCurrentModelDialog
                      isDialogOpen={saveCurrentModelDialogIsOpen}
                      handleCloseDialog={handleCloseSaveCurrentModelDialog}
                      nextStep={openNewModel}
                    />
                    <Tooltip title="Open model" TransitionComponent={Zoom} arrow>
                    <IconButton 
                        size="small"
                        onClick={handleOpenOpenModelDialog}
                        sx={{ color: 'white'}}
                    >
                        <OpenModelIcon sx={{ fontSize: iconSize }} />
                    </IconButton>
                    </Tooltip>
                    <OpenModelDialog 
                      isDialogOpen={openModelDialogIsOpen}
                      handleCloseDialog={handleCloseOpenModelDialog}
                    />
                    <Tooltip title="Save model" TransitionComponent={Zoom} arrow>
                    <IconButton 
                        size="small"
                        onClick={handleOpenSaveModelDialog}
                        sx={{ color: 'white'}}
                    >
                        <SaveModelIcon sx={{ fontSize: iconSize }} />
                    </IconButton>
                    </Tooltip>
                    <SaveModelDialog 
                      isDialogOpen={saveModelDialogIsOpen}
                      handleCloseDialog={handleCloseSaveModelDialog}
                    />
                    {/* <Tooltip title="Upload model" TransitionComponent={Zoom} arrow>
                    <IconButton 
                        size="small"
                        onClick={handleUploadModel}
                        sx={{ color: 'white'}}
                    >
                        <UploadModelIcon sx={{ fontSize: iconSize }} />
                    </IconButton>
                    </Tooltip> */}
                    <Tooltip title="Toggle fullscreen" TransitionComponent={Zoom} arrow>
                    <IconButton 
                        size="small"
                        onClick={handleToggleFullscreen}
                        sx={{ color: 'white'}}
                    >
                        { isFullscreen ? <ZoomInMap sx={{ fontSize: iconSize }} /> : <ZoomOutMap sx={{ fontSize: iconSize }} /> }
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Keyboard shortcuts" TransitionComponent={Zoom} arrow>
                    <IconButton
                        size="small"
                        onClick={handleOpenKeyboardDialog}
                        sx={{ color: 'white'}}
                    >
                        <KeyboardIcon sx={{ fontSize: iconSize }} />
                    </IconButton>
                    </Tooltip>
                    <KeyboardDialog
                      isDialogOpen={keyboardDialogIsOpen}
                      handleCloseDialog={handleCloseKeyboardDialog}
                    />
                </Box>
                <UserMenu></UserMenu>
              </Toolbar>
          </AppBar>
        );
    };