import { useReducer } from "react";
import { logger } from "./logger";

const MODELER_INITIAL_STATE = {
    modeler: null,
    model_name: null,
    model_innerID: null,
    model_unsaved: null,
    model_readOnly: null,
    refresh: null,
    loading: null,
    error: null,
};

const MODELER_ACTIONS = {
    INIT_MODELER: "INIT_MODELER",
    LOADING: "LOADING",
    ERROR: "ERROR",
    NEW_MODEL: "NEW_MODEL",
    OPEN_MODEL: "OPEN_MODEL",
    SAVE_MODEL: "SAVE_MODEL",
    UPDATE_MODEL_NAME: "UPDATE_MODEL_NAME",
    UPDATE_MODEL_INNERID: "UPDATE_MODEL_INNERID",
    REFRESHED: "REFRESHED",
    CHANGED: "CHANGED",
};

const modelerReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case MODELER_ACTIONS.INIT_MODELER:
            logger.log(MODELER_ACTIONS.INIT_MODELER, payload);

            return {
                ...state,
                modeler: payload.modeler,
                model_name: payload.model_name,
                model_innerID : payload.model_innerID,
                model_unsaved: payload.model_unsaved,
                loading: payload.loading,
                refresh: payload.refresh,
            };
        case MODELER_ACTIONS.LOADING:
            logger.log(MODELER_ACTIONS.LOADING, payload);

            return {
                ...state,
                error: payload.error,
                loading: payload.loading,
            };
        case MODELER_ACTIONS.ERROR:
            logger.log(MODELER_ACTIONS.ERROR, payload);

            return {
                ...state,
                error: payload.error,
                loading: payload.loading,
            };
        case MODELER_ACTIONS.NEW_MODEL:
            logger.log(MODELER_ACTIONS.NEW_MODEL, payload);

            return {
                ...state,
                model_name: payload.model_name,
                model_innerID: payload.model_innerID,
                model_unsaved: payload.model_unsaved,
                model_readOnly: payload.model_readOnly,
                loading: payload.loading,
                refresh: payload.refresh,
            };
        case MODELER_ACTIONS.OPEN_MODEL:
            logger.log(MODELER_ACTIONS.OPEN_MODEL, payload);

            return {
                ...state,
                model_name: payload.model_name,
                model_innerID: payload.model_innerID,
                model_unsaved: payload.model_unsaved,
                model_readOnly: payload.model_readOnly,
                loading: payload.loading,
                refresh: payload.refresh,
            };
        case MODELER_ACTIONS.UPDATE_MODEL_INNERID:
            logger.log(MODELER_ACTIONS.UPDATE_MODEL_INNERID, payload);

            return {
                ...state,
                model_innerID: payload.model_innerID,
            };
        case MODELER_ACTIONS.REFRESHED:
                logger.log(MODELER_ACTIONS.REFRESHED, payload);
    
                return {
                    ...state,
                    refresh: payload.refresh,
                };
        case MODELER_ACTIONS.UPDATE_MODEL_NAME:
            logger.log(MODELER_ACTIONS.UPDATE_MODEL_NAME, payload);

            return {
                ...state,
                model_name: payload.model_name,
                model_unsaved: payload.model_unsaved,
            };
        case MODELER_ACTIONS.SAVE_MODEL:
            logger.log(MODELER_ACTIONS.SAVE_MODEL, payload);

            return {
                ...state,
                model_name: payload.model_name,
                model_innerID: payload.model_innerID,
                model_unsaved: payload.model_unsaved,
                model_readOnly: payload.model_readOnly,
                loading: payload.loading,
            };
        case MODELER_ACTIONS.CHANGED:
            logger.log(MODELER_ACTIONS.CHANGED, payload);

            return {
                ...state,
                model_unsaved: payload.model_unsaved,
            };
        default:
            throw new Error(`No case for type ${type} found in shopReducer.`);
    }
};

const useModelerReducer = () => {
    const [state, dispatch] = useReducer(modelerReducer, MODELER_INITIAL_STATE);
    return [state, dispatch];
};

export {useModelerReducer, MODELER_ACTIONS, MODELER_INITIAL_STATE }