import { useEffect, useState } from "react";
import { Divider, TextField, Grid, styled, IconButton, CardActions, Dialog, DialogTitle, DialogContent, Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useModeler } from "../ModelerContext";

const CustomTextField = styled(TextField)({
    marginTop: 0,//2,
    marginBottom: 0, //3,
    "& .MuiFilledInput-root": {
      background: 'white'
    },
    "& .MuiFilledInput-root:hover": {
      background: 'white'
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: 'white'
    },
    "& .MuiFilledInput-root.Mui-disabled": {
      backgroundColor: 'white'
    },
  });

export default function UpdateModelDialog ({ isDialogOpen, handleCloseDialog }) {
    
    const {modeler, model_name, model_readOnly, updateModelName } = useModeler();

    const handleUpdate = () => {
        handleCloseDialog();
        updateModelName(modelName, modelDocumentation);
    };

    const [modelName, setModelName] = useState('');
    const [modelDocumentation, setModelDocumentation] = useState('');

    // Get model's name and documentation from context when Dialog window is shown
    useEffect (() => {
        if (isDialogOpen) {
            setModelName(model_name);
            setModelDocumentation(modeler.getModel().documentation);
        }
    },[isDialogOpen, model_name, modeler]);

    const handleChangeName = (value) => {
        setModelName(value);
    };

    const handleChangeDocumentation = (value) => {
        setModelDocumentation(value); 
    };

    return(
        <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle>
                Model properties
                <IconButton
                    onClick={handleCloseDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={1}
                  direction="column"
                >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography color="grey">
                        { modeler?.getModel()?.id }
                    </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CustomTextField
                        label="Name"
                        id="name-text-field"
                        value={modelName}
                        size="small"
                        variant="filled"
                        onChange={(e) => handleChangeName(e.target.value)}
                        fullWidth={true}
                        onFocus={(e) => e.target.select()}
                        autoFocus
                    />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CustomTextField
                        id="doc-text-field"
                        label="Documentation"
                        multiline
                        rows={4}
                        value={modelDocumentation}
                        onChange={(e) => handleChangeDocumentation(e.target.value)}
                        size="small"
                        variant="filled"
                        fullWidth={true}
                    />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CardActions sx={{ paddingTop: 0.5, paddingBottom: 0, display: "flex", justifyContent: "space-between" }} >
                        <Button size="small" disabled={model_readOnly} onClick={handleUpdate}>Update model</Button>
                        <Button size="small" onClick={handleCloseDialog}>Cancel</Button>
                    </CardActions>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
