import { Close  } from "@mui/icons-material";
import { Button, Dialog, DialogTitle, DialogContent, Divider, CardActions, Typography, IconButton } from "@mui/material";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { DELETE_MODEL, MODELS_BY_USER } from "../graphql-operations";
import { useUserContext } from "../UserContext";
import useCustomSnackbar from "./CustomSnackbar/useCustomSnackbar";
import { useModeler } from "../ModelerContext";
import { logger } from "../logger";

export default function DialogButton ({ buttonLabel, ButtonProps, modelName, modelId }) {

    const [isOpen, setIsOpen] = useState(false);
    // by default, userID is set to Visitor's ID ("id-0")
    const { user } = useUserContext();
    const { model_innerID, newModel } = useModeler();
    const snackbar = useCustomSnackbar();

    const [deleteModel] = useMutation(DELETE_MODEL, {
        update: (cache, { data: { deleteOneModel } }) => {
            const { models } = cache.readQuery({
                query: MODELS_BY_USER,
                variables: { query: {ownerID: user.userID } }
            });
            const deletedModelID = deleteOneModel._id;
            const updatedModels = models.filter(model => model._id !== deletedModelID);
            cache.writeQuery({
                query: MODELS_BY_USER,
                variables: { query: {ownerID: user.userID } },
                data: {
                    models: updatedModels
                }
            });
        },
        onCompleted: (data) => {
            var id = data.deleteOneModel._id,
                name = data.deleteOneModel.name;
            logger.log(`Model #${id} successfully deleted`);
            snackbar.showSuccess(`${name} successfully deleted.`);
        },
        onError: (error) => {
            logger.error(error);
            snackbar.showError(error.message);
        }
    });

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleDelete = () => {
        logger.log('Delete model #' + modelId);
        deleteModel({ variables: { query: { _id: modelId  } }});
        if (model_innerID === modelId) {
            newModel();
        }
    };

    return(
        <>
        <Button {...ButtonProps} onClick={handleOpen}>
            {buttonLabel}
        </Button>
        <Dialog open={isOpen} onClose={handleClose} >
        <DialogTitle>
            Delete model ?
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                    { model_innerID !== modelId 
                    ? ( <Typography> Do you really want to delete model {modelName} ?</Typography>)                    
                    : ( <>
                        <Typography> This is the current model, a new model will be load.</Typography>
                        <Typography> Do you really want to delete model {modelName} ?</Typography> 
                        </> )
                    }
                    
            <CardActions sx={{ paddingBottom: 0.5, display: "flex", justifyContent: "space-between" }} >
                <Button size="small" color="error" onClick={handleDelete}>Delete</Button>
                <Button size="small" onClick={handleClose}>Cancel</Button>
            </CardActions>
            </DialogContent>

        </Dialog>
        </>
    );

}