// from https://github.com/TeamWertarbyte/material-ui-snackbar-provider

import React from 'react'
import PropTypes from 'prop-types'
import { Snackbar, Button, IconButton, Alert } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";

export default function CustomSnackbar ({
  message,
  action,
  ButtonProps,
  SnackbarProps,
  customParameters
}) {

  const handleClose = SnackbarProps.onClose;

  return (
    <Snackbar autoHideDuration={3000} {...SnackbarProps}>
      <Alert
        severity={customParameters?.type}
        action={
          <>
          {action != null && (
            <Button color='inherit' size='small' {...ButtonProps}>
                {action}
            </Button>
          )}
          <IconButton
          aria-label='close'
          color='inherit'
          size='small'
          onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          </>
        }
      >
        <strong>{message}</strong>
      </Alert>


    </Snackbar>
  )
}

CustomSnackbar.propTypes = {
  message: PropTypes.string,
  action: PropTypes.string,
  ButtonProps: PropTypes.object,
  SnackbarProps: PropTypes.object,
  customParameters: PropTypes.shape({
    type: PropTypes.oneOf(['error', 'warning', 'info', 'success'])
  })
}