// React
import React from 'react';
import ReactDOM from 'react-dom/client';
// Apollo
import {
    ApolloProvider,
    ApolloClient,
    HttpLink,
    InMemoryCache,
  } from "@apollo/client";
// Realm
import * as Realm from "realm-web";

import App from './App';
import './index.css';

import { logger } from './logger';

import LogRocket from "logrocket";

// Start LogRocket in production mode
if (process.env.NODE_ENV === 'production') {
    LogRocket.init('orzblp/archimodel', {
        console: {
            isEnabled: true,
        }
    });
}

// MongoDB app's GraphQL endpoint
const MONGODB_APP_ID = "archimodel-ibxoo";

// const graphqlUri = `https://realm.mongodb.com/api/client/v2.0/app/${MONGODB_APP_ID}/graphql`;
// Local apps should use a local URI!
const graphqlUri = `https://europe-west1.gcp.realm.mongodb.com/api/client/v2.0/app/${MONGODB_APP_ID}/graphql`;

const mongodb_app = new Realm.App({ id: MONGODB_APP_ID });

// Get a valid Realm user access token to authenticate requests
async function getValidAccessToken() {
    // Guarantee that there's a logged in user with a valid access token
    if (!mongodb_app.currentUser) {
        // If no user is logged in, log in an anonymous user. The logged in user will have a valid
        // access token.
        const credentials = Realm.Credentials.anonymous();
        try { 
            await mongodb_app.logIn(credentials);
        } catch (err) {
            logger.error("Failed to log in", err);
        }
    } else {
        // An already logged in user's access token might be stale. To guarantee that the token is
        // valid, we refresh the user's custom data which also refreshes their access token.
        await mongodb_app.currentUser.refreshAccessToken();
    }
    return mongodb_app.currentUser.accessToken;
}

// Configure ApolloClient to connect to MongoDB app's GraphQL endpoint
const appollo_client = new ApolloClient({
    link: new HttpLink({
        uri: graphqlUri,
        // Custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
        // The function intercepts every Apollo HTTP request and adds an Authorization header 
        // with a valid access token before sending the request.
        fetch: async (uri, options) => {
            const accessToken = await getValidAccessToken();
            options.headers.Authorization = `Bearer ${accessToken}`;
            return fetch(uri, options);
        },
    }),
    cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
                models: {
                    merge: (existing = [], incoming) => {
                        return incoming;
                    },
                },
            },
          },
        },
      }),
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ApolloProvider client={appollo_client}>
        <App />
    </ApolloProvider>,
);
