import { Box, Avatar, Divider, Grid, IconButton, Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";
// import { Button, CardActions, TextField, styled } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useUserContext } from "../UserContext";

/* const CustomTextField = styled(TextField)({
    marginTop: 0,//2,
    marginBottom: 0, //3,
    "& .MuiFilledInput-root": {
      background: 'white'
    },
    "& .MuiFilledInput-root:hover": {
      background: 'white'
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: 'white'
    },
    "& .MuiFilledInput-root.Mui-disabled": {
      backgroundColor: 'white'
    },
  }); */

export default function UserProfilelDialog ({ isDialogOpen, handleCloseDialog }) {
    
    const { user } = useUserContext();

    return(
        <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogTitle>
                My profile
                <IconButton
                    onClick={handleCloseDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={1}
                  direction="column"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                   <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >  
                    <Avatar src={ user.avatar } sx={{ m:1, width: 96, height: 96 }} />
                    <Typography variant="h4" >{ user.username }</Typography>
                    <Typography variant="h6" >{user.fullname}</Typography>
                    <Typography >{user.email}</Typography>
                    <Typography color="grey">{user.userID}</Typography>
                    </Box>
                    </Grid>
                    {/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CardActions sx={{ paddingTop: 0.5, paddingBottom: 0, display: "flex", justifyContent: "flex-end" }} >
                        <Button size="small" onClick={handleUpdate}>Update</Button> 
                    </CardActions>
                    </Grid>*/}
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
