import * as CX from 'archimate-js/lib/metamodel/Concept';
import * as MU from 'archimate-js/lib/util/ModelUtil';

import ArchimateIcon from './ArchimateIcon';

const ACTOR_PATH = "m 5.5 9.5 h 8 m -4 3.5 l 4 5 m -4 -5 l -4 5 m 4 -11.5 v 6.5 m 0 -12.5 a 0.5 0.5 90 0 0 0 6 a 0.5 0.5 90 0 0 0 -6 z",
    ARTIFACT_PATH = "m 3.5 1.5 l 6 0 l 5 5 l 0 10 l -11 0 l 0 -15 m 11 5 l -5 0 l 0 -5",
    CAPABILITY_PATH = "m 16.5 1.5 l -5 0 l 0 5 l -5 0 l 0 5 l -5 0 l 0 5 l 15 0 z m -10 10 l 10 0 m -5 -5 l 0 10 m -5 -5 l 0 5 m 5 -10 l 5 0",
    COLLABORATION_PATH = "m 0.5 9 a 1 1 90 0 0 11 0 a 1 1 90 0 0 -11 0 m 17 0 a 1 1 90 0 0 -11 0 a 1 1 90 0 0 11 0",
    COMMUNICATION_NETWORK_PATH = "m 1 13.5 a 1 1 90 0 0 5 0 a 1 1 90 0 0 -5 0 m 2 -9 a 1 1 90 0 0 5 0 a 1 1 90 0 0 -5 0 m 14 0 a 1 1 90 0 0 -5 0 a 1 1 90 0 0 5 0 m -7 9 a 1 1 90 0 0 5 0 a 1 1 90 0 0 -5 0 m -2 -9 l 4 0 m -6 9 l 4 0 m -6.5 -2.5 l 2 -4 m 7 4 l 2 -4",
    COMPONENT_PATH = "m 4.5 4.5 l 0 -3 l 12 0 l 0 15 l -12 0 l 0 -3 m -3 -3 l 6 0 l 0 3 l -6 0 z m 3 0 l 0 -3 m -3 -3 l 6 0 l 0 3 l -6 0 z",
    CONTRACT_PATH = "m 1.5 2.5 l 15 0 l 0 13 l -15 0 z m 0 4 l 15 0 m -15 5 l 15 0",
    COURSE_OF_ACTION_PATH = "m 6.5 6 a 1 1 90 0 0 11 0 a 1 1 90 0 0 -11 0 m 2.5 0 a 1 1 90 0 0 6 0 a 1 1 90 0 0 -6 0 m 2.5 0.5 a 1 1 90 0 0 1 0 a 1 1 90 0 0 0 -1 a 1 1 90 0 0 -1 0 a 1 1 90 0 0 0 1 m -3.5 3.5 l -0.5 4 l -3.5 -3.5 z m -2.5 1.5 q -3.5 2.5 -2.5 6 l 1 0 q -1 -3.5 2.5 -5 m -0.5 -1.5 l 1 0 l 0 1",
    DEVICE_PATH = "m 5 11.5 l -3.5 4 l 15 0 l -3.5 -4 m -11.5 -2 l 0 -5 a 2 2 90 0 1 2 -2 l 11 0 a 2 2 90 0 1 2 2 l 0 5 a 2 2 90 0 1 -2 2 l -11 0 a 2 2 90 0 1 -2 -2",
    EVENT_PATH = "m 0.5 3.5 l 12 0 c 6.5 0 6.5 11 0 11 l -12 0 l 3.5 -5.5 z",
    FUNCTION_PATH = "m 9 2 l -6.5 5.5 l 0 8.5 l 6.5 -5.5 l 6.5 5.5 l 0 -8.5 z",
    INTERACTION_PATH = "m 10.5 2 q 6 0 6 7 q 0 7 -6 7 z m -3 14 q -6 0 -6 -7 q 0 -7 6 -7 z",
    INTERFACE_PATH = "m 7.5 9.5 h -7 m 17 0 a 5 5 90 0 0 -10 0 a 5 5 90 0 0 10 0 z",
    NODE_PATH = "m 1.5 5.5 l 11 0 l 0 11 l -11 0 l 0 -11 l 4 -4 l 11 0 l 0 11 l -4 4 m 0 -11 l 4 -4",
    NOTE_PATH = "m 1.5 1.5 h 15 v 11 l -3 3 h -12 z m 3.5 4 h 8 m -8 3 h 8 m -8 3 h 6 z",
    OBJECT_PATH = "m 1.5 2.5 l 15 0 l 0 13 l -15 0 z m 0 4 l 15 0",
    PATH_PATH = "m 6 3.5 l -5 5.1 m 0 -0.2 l 5 5.099 m 6 -10 l 5 5.101 m 0 -0.2 l -5 5.099 m -9 -5 l 3 0 m 1.5 0 l 3 0 m 1.5 0 l 3 0', // m 6 3.5 l -5 5 m 0 0 l 5 5 m 6 -10 l 5 5 m 0 0 l -5 5 m -9 -5 l 3 0 m 1.5 0 l 3 0 m 1.5 0 l 3 0",
    PROCESS_PATH = "m 0.5 6.5 l 9 0 l 0 -4 l 8 6.5 l -8 6.5 l 0 -4 l -9 0 z",
    PRODUCT_PATH = "m 1.5 2.5 l 15 0 l 0 13 l -15 0 z m 0 4 l 8 0 m 0 -4 l 0 4",
    REPRESENTATION_PATH = "m 1.5 2.5 l 15 0 l 0 11.5 c -7.5 -5 -7.5 5 -15 0 z m 0 4 l 15 0",
    RESOURCE_PATH = "m 15.5 7 l 0 -2 l -1.5 -1.5 l -12 0 l -1.5 1.5 l 0 8 l 1.5 1.5 l 12 0 l 1.5 -1.5 l 0 -2 l 1.5 0 l 0.5 -0.5 l 0 -3 l -0.5 -0.5 z m -8 -2 l 0 8 m -4 -8 l 0 8 m 2 0 l 0 -8 m 10 2 l 0 4",
    ROLE_PATH = "m 5 4.5 l 8 0 a 1 1 90 0 1 0 9 l -8 0 a 1 1 90 0 1 0 -9 m 8 9 a 0.5 0.5 90 0 1 0 -9",
    SERVICE_PATH = "m 5 4.5 l 8 0 a 1 1 90 0 1 0 9 l -8 0 a 1 1 90 0 1 0 -9",
    SYSTEM_SOFTWARE_PATH = "m 2.5 10 a 1 1 90 0 0 11 0 a 1 1 90 0 0 -11 0 m 7.29 -7.5 a 5.5 5.5 90 0 0 -4.544 2.7392 a 5.5 5.5 90 0 1 8.254 4.7608 a 5.5 5.5 90 0 1 -0.7353 2.7473 a 5.5 5.5 90 0 0 2.7353 -4.7473 a 5.5 5.5 90 0 0 -5.71 -5.5",
    VALUE_STREAM_PATH = "m 17 9 l -5 -6.5 l -11 0 l 5 6.5 l -5 6.5 l 11 0 z",
    VIEW_PATH = "m 0.5 3 h 6 v 4 h -6 z m 6 2 h 5 m 0 -2 h 6 v 4 h -6 v -4 m -8 10 v -6 z h 8 m 0 -2 h 6 v 4 h -6 z",
    COMPOSITION_PATH = "m 17 1 l -8 8 l -1 7 l -7 1 l 1 -7 l 7 -1",
    AGGREGATION_PATH = "m 17 1 l -8 8 l -1 7 l -7 1 l 1 -7 l 7 -1",
    ASSIGNMENT_PATH = "m 3 17 a 1 1 0 0 0 1 -5 a 1 1 0 0 0 -1 5 m -1 -1 l 10 -10 m 3 3 l -6 -6 l 8 -2 z",
    REALIZATION_PATH = "m 1 17 l 1 -1 m 1 -1 l 1 -1 m 1 -1 l 1 -1 m 1 -1 l 1 -1 m 1 -1 l 1 -1 m 1 -1 l 1 -1 m 3 3 l -6 -6 l 8 -2 z",
    ASSOCIATION_PATH = "m 1 17 l 16 -16",
    INFLUENCE_PATH = "m 1 17 l 2 -2 m 2 -2 l 3 -3 m 2 -2 l 3 -3 m 2 -2 l 2 -2 m -2 8 l 2 -8 m 0 0 l -8 2 m 1 11 l 5 0 m -2.5 -2.5 l 0 5",
    ACCESS_PATH = "m 1 17 l 1 -1 m 1 -1 l 1 -1 m 1 -1 l 1 -1 m 1 -1 l 1 -1 m 1 -1 l 1 -1 m 1 -1 l 1 -1 m 1 -1 l 1 -1 m 1 -1 l 1 -1 m -7 1 l 8 -2 m -2 8 l 2 -8",
    SERVING_PATH = "m 1 17 l 16 -16 m -8 2 l 8 -2 m -2 8 l 2 -8",
    TRIGGERING_PATH = "m 1 17 l 11 -11 m 3 3 l -6 -6 l 8 -2 z",
    FLOW_PATH = "m 1 17 l 2 -2 m 2 -2 l 3 -3 m 2 -2 l 2 -2 m 3 3 l -6 -6 l 8 -2 z",
    SPECIALIZATION_PATH = "m 1 17 l 11 -11 m 3 3 l -6 -6 l 8 -2 z",
    JUNCTION_AND_PATH = "",
    JUNCTION_OR_PATH = "";
    
const iconsMap = new Map([
    [CX.STRATEGY_CAPABILITY, <ArchimateIcon fontSize="small" fill="rgb(245, 222, 170)" iconPath={CAPABILITY_PATH}/>],
    [CX.STRATEGY_COURSE_OF_ACTION, <ArchimateIcon fontSize="small" fill="rgb(245, 222, 170)" iconPath={COURSE_OF_ACTION_PATH}/>],
    [CX.STRATEGY_RESOURCE, <ArchimateIcon fontSize="small" fill="rgb(245, 222, 170)" iconPath={RESOURCE_PATH}/>],
    [CX.STRATEGY_VALUE_STREAM, <ArchimateIcon fontSize="small" fill="rgb(245, 222, 170)" iconPath={VALUE_STREAM_PATH}/>],
    [CX.BUSINESS_ACTOR, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 181)" iconPath={ACTOR_PATH}/>],
    [CX.BUSINESS_COLLABORATION, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 181)" iconPath={COLLABORATION_PATH}/>],
    [CX.BUSINESS_CONTRACT, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 181)" iconPath={CONTRACT_PATH}/>],
    [CX.BUSINESS_EVENT, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 181)" iconPath={EVENT_PATH}/>],
    [CX.BUSINESS_FUNCTION, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 181)" iconPath={FUNCTION_PATH}/>],
    [CX.BUSINESS_INTERACTION, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 181)" iconPath={INTERACTION_PATH}/>],
    [CX.BUSINESS_INTERFACE, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 181)" iconPath={INTERFACE_PATH}/>],
    [CX.BUSINESS_OBJECT, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 181)" iconPath={OBJECT_PATH}/>],
    [CX.BUSINESS_PROCESS, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 181)" iconPath={PROCESS_PATH}/>],
    [CX.BUSINESS_PRODUCT, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 181)" iconPath={PRODUCT_PATH}/>],
    [CX.BUSINESS_REPRESENTATION, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 181)" iconPath={REPRESENTATION_PATH}/>],
    [CX.BUSINESS_ROLE, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 181)" iconPath={ROLE_PATH}/>],
    [CX.BUSINESS_SERVICE, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 181)" iconPath={SERVICE_PATH}/>],
    [CX.APPLICATION_COLLABORATION, <ArchimateIcon fontSize="small" fill="rgb(181, 255, 255)" iconPath={COLLABORATION_PATH}/>],
    [CX.APPLICATION_COMPONENT, <ArchimateIcon fontSize="small" fill="rgb(181, 255, 255)" iconPath={COMPONENT_PATH}/>],
    [CX.APPLICATION_DATA_OBJECT, <ArchimateIcon fontSize="small" fill="rgb(181, 255, 255)" iconPath={OBJECT_PATH}/>],
    [CX.APPLICATION_EVENT, <ArchimateIcon fontSize="small" fill="rgb(181, 255, 255)" iconPath={EVENT_PATH}/>], 
    [CX.APPLICATION_FUNCTION, <ArchimateIcon fontSize="small" fill="rgb(181, 255, 255)" iconPath={FUNCTION_PATH}/>],
    [CX.APPLICATION_INTERACTION, <ArchimateIcon fontSize="small" fill="rgb(181, 255, 255)" iconPath={INTERACTION_PATH}/>],
    [CX.APPLICATION_INTERFACE, <ArchimateIcon fontSize="small" fill="rgb(181, 255, 255)" iconPath={INTERFACE_PATH}/>],  
    [CX.APPLICATION_PROCESS, <ArchimateIcon fontSize="small" fill="rgb(181, 255, 255)" iconPath={PROCESS_PATH}/>],
    [CX.APPLICATION_SERVICE, <ArchimateIcon fontSize="small" fill="rgb(181, 255, 255)" iconPath={SERVICE_PATH}/>],
    [CX.TECHNOLOGY_ARTIFACT, <ArchimateIcon fontSize="small" fill="rgb(201, 231, 183)" iconPath={ARTIFACT_PATH}/>],  
    [CX.TECHNOLOGY_COLLABORATION, <ArchimateIcon fontSize="small" fill="rgb(201, 231, 183)" iconPath={COLLABORATION_PATH}/>],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, <ArchimateIcon fontSize="small" fill="rgb(201, 231, 183)" iconPath={COMMUNICATION_NETWORK_PATH}/>],
    [CX.TECHNOLOGY_DEVICE, <ArchimateIcon fontSize="small" fill="rgb(201, 231, 183)" iconPath={DEVICE_PATH}/>],
    [CX.TECHNOLOGY_EVENT, <ArchimateIcon fontSize="small" fill="rgb(201, 231, 183)" iconPath={EVENT_PATH}/>],
    [CX.TECHNOLOGY_FUNCTION, <ArchimateIcon fontSize="small" fill="rgb(201, 231, 183)" iconPath={FUNCTION_PATH}/>],
    [CX.TECHNOLOGY_INTERACTION, <ArchimateIcon fontSize="small" fill="rgb(201, 231, 183)" iconPath={INTERACTION_PATH}/>],
    [CX.TECHNOLOGY_INTERFACE, <ArchimateIcon fontSize="small" fill="rgb(201, 231, 183)" iconPath={INTERFACE_PATH}/>],
    [CX.TECHNOLOGY_NODE, <ArchimateIcon fontSize="small" fill="rgb(201, 231, 183)" iconPath={NODE_PATH}/>],
    [CX.TECHNOLOGY_PATH, <ArchimateIcon fontSize="small" fill="rgb(201, 231, 183)" iconPath={PATH_PATH}/>],
    [CX.TECHNOLOGY_PROCESS, <ArchimateIcon fontSize="small" fill="rgb(201, 231, 183)" iconPath={PROCESS_PATH}/>],
    [CX.TECHNOLOGY_SERVICE, <ArchimateIcon fontSize="small" fill="rgb(201, 231, 183)" iconPath={SERVICE_PATH}/>],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, <ArchimateIcon fontSize="small" fill="rgb(201, 231, 183)" iconPath={SYSTEM_SOFTWARE_PATH}/>],
    [CX.RELATIONSHIP_COMPOSITION, <ArchimateIcon fontSize="small" fill="rgb(0, 0, 0)" iconPath={COMPOSITION_PATH}/>],
    [CX.RELATIONSHIP_AGGREGATION, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 255)" iconPath={AGGREGATION_PATH}/>], 
    [CX.RELATIONSHIP_ASSIGNMENT, <ArchimateIcon fontSize="small" fill="rgb(0, 0, 0)" iconPath={ASSIGNMENT_PATH}/>],
    [CX.RELATIONSHIP_REALIZATION, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 255)" iconPath={REALIZATION_PATH}/>],
    [CX.RELATIONSHIP_ASSOCIATION, <ArchimateIcon fontSize="small" iconPath={ASSOCIATION_PATH}/>],
    [CX.RELATIONSHIP_INFLUENCE, <ArchimateIcon fontSize="small" iconPath={INFLUENCE_PATH}/>],
    [CX.RELATIONSHIP_ACCESS, <ArchimateIcon fontSize="small" iconPath={ACCESS_PATH}/>],
    [CX.RELATIONSHIP_SERVING, <ArchimateIcon fontSize="small" iconPath={SERVING_PATH}/>],
    [CX.RELATIONSHIP_TRIGGERING, <ArchimateIcon fontSize="small" fill="rgb(0, 0, 0)" iconPath={TRIGGERING_PATH}/>],
    [CX.RELATIONSHIP_FLOW, <ArchimateIcon fontSize="small" fill="rgb(0, 0, 0)" iconPath={FLOW_PATH}/>],
    [CX.RELATIONSHIP_SPECIALIZATION, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 255)" iconPath={SPECIALIZATION_PATH}/>],
    [CX.RELATIONSHIP_JUNCTION_AND, <ArchimateIcon fontSize="small" fill="rgb(0, 0, 0)" iconPath={JUNCTION_AND_PATH}/>],
    [CX.RELATIONSHIP_JUNCTION_OR, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 255)" iconPath={JUNCTION_OR_PATH}/>],
    [MU.NOTE, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 255)" iconPath={NOTE_PATH}/>],
    [MU.VIEW, <ArchimateIcon fontSize="small" fill="rgb(255, 255, 255)" iconPath={VIEW_PATH}/>],
]);

export function getArchimateIcon(elementName) {
    return iconsMap.get(elementName);
}
