import { createContext, useContext } from "react";
import { useModelerReducer, MODELER_INITIAL_STATE, MODELER_ACTIONS } from "./ModelerReducer";
import Modeler from 'archimate-js/lib/Modeler';
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_MODEL, UPDATE_MODEL, MODELS_BY_USER, GET_XMLMODEL } from "./graphql-operations";
import { useUserContext } from "./UserContext";
import useCustomSnackbar from "./Component/CustomSnackbar/useCustomSnackbar";
import { logger } from "./logger";


const ModelerContext = createContext(MODELER_INITIAL_STATE);

const ModelerProvider = ({ children }) => {

    // by default, userID is set to Visitor's ID ("id-0")
    const { user } = useUserContext();
    const snackbar = useCustomSnackbar();

    const [createModel] = useMutation(CREATE_MODEL, {
        refetchQueries: [
            {
                query: MODELS_BY_USER,
                variables: { query: {ownerID: user.userID } }
            }
        ],
        onCompleted: (data) => {
            var id = data.insertOneModel._id,
                name = data.insertOneModel.name;
            dispatch({
                type: MODELER_ACTIONS.SAVE_MODEL,
                payload: {
                    model_name: name,
                    model_innerID: id,
                    model_unsaved: false,
                    loading: false,
                }
            });  
            logger.log(`New model #${id} successfully inserted`);
            snackbar.showSuccess(`Model ${name} successfully saved.`);
        },
        onError: (error) => {
            logger.error(error);
            dispatchError("Can't save model on server: " + error.message);
        }
    });

    const [updateModel] = useMutation(UPDATE_MODEL, {
        refetchQueries: [
            {
                query: MODELS_BY_USER,
                variables: { query: {ownerID: user.userID } }
            }
        ],
        onCompleted: (data) => {
            var id = data.updateOneModel._id,
                name = data.updateOneModel.name;
            dispatch({
                type: MODELER_ACTIONS.SAVE_MODEL,
                payload: {
                    model_name: name,
                    model_innerID: id,
                    model_unsaved: false,
                    loading: false,
                }
            });
            logger.log(`Model #${id} successfully updated`);
            snackbar.showSuccess(`Model ${name} successfully updated.`);
        },
        onError: (error) => {
            logger.error(error);
            dispatchError("Can't update model on server: " + error.message);
        }
    });

    const [loadXmlmodel] = useLazyQuery(GET_XMLMODEL, {
        fetchPolicy: 'network-only',
        /*variables: { 
            query: { _id: selectedModel } 
        },*/
        onCompleted: (data) => { 
            logger.log('loadXmlmodel completed');
            var id = data.model._id,
                xmlmodel = data.model.xmlmodel;
            state.modeler.importXML(xmlmodel)
            .then(message => {
                if (message.warnings.lenth) {
                    logger.warn(message.warnings);
                    dispatchError("Warnings: " + message.warnings);
                }
                state.modeler.openView()
                    .then(() => {
                        logger.log(state.modeler.getModel());
                        dispatch({
                            type: MODELER_ACTIONS.OPEN_MODEL,
                            payload: {
                                model_name: state.modeler.getModel().name,
                                model_innerID : id,
                                model_unsaved: false,
                                model_readOnly: data.model.readonly,
                                loading: false,
                                refresh: true,
                            }
                        });
                        snackbar.showSuccess(`Model ${state.modeler.getModel().name} successfully opened.`);
                    })
                    .catch((err) =>{
                        logger.error(err)
                        dispatchError("Error on openning model: " + err.message);
                    })
            })
            .catch(err => {
                logger.log (err);
                dispatchError("Error on openning model : " + err.message);
            });
        },
        onError: (error) => {
            logger.error(error);
            dispatchError("Can't load model from server: " + error.message);
        }
    });

    const [state, dispatch] = useModelerReducer();

    const dispatchError = (message) => {
        dispatch({
            type: MODELER_ACTIONS.ERROR,
            payload: {
                error: { message: message },
                loading: false,
            }
        });
    };

    const dispatchLoading = () => {
        dispatch({
            type: MODELER_ACTIONS.LOADING,
            payload: {
                error: null,
                loading: true,
            }
        });
    };

    const initModeler = async (container) => {
        logger.log("init modeler for " + container);
        dispatchLoading();
        const newModeler = new Modeler({
            container,
            keyboard: {
                bindTo: window,
            }
        });
        newModeler.get('canvas').zoom('fit-viewport');
        await newModeler.createNewModel();
        dispatch({
            type: MODELER_ACTIONS.INIT_MODELER,
            payload: {
                modeler: newModeler,
                model_name: newModeler.getModel().name,
                model_innerID : "0",
                model_unsaved: false,
                loading: false,
                refresh: true,
            }
        });
        logger.log("dispatch new model ok");
        /*.then(message => {
            dispatch({
                type: MODELER_ACTIONS.INIT_MODELER,
                payload: {
                    modeler: newModeler,
                    model_name: newModeler.getModel().name,
                    model_innerID : "0",
                    model_unsaved: false,
                    loading: false,
                    refresh: true,
                }
            });
            logger.log("dispatch new model ok");
            // snackbar.showSuccess(`New model opened.`);
        })
        .catch(err => {
            logger.error(err.message);
            //throw new Error("Can't load model on canvas: " + err.message);
            dispatchError("Init modeler: " + err.message);
        });*/
    };

    const newModel = async () => {
        logger.log("load new model");
        dispatchLoading();
        await state.modeler.createNewModel();
        dispatch({
            type: MODELER_ACTIONS.NEW_MODEL,
            payload: {
                model_name: state.modeler.getModel().name,
                model_innerID : "0",
                model_unsaved: false,
                model_readOnly: false,
                loading: false,
                refresh: true,
            }
        });
        logger.log("dispatch new model ok");
        snackbar.showSuccess(`New model opened.`);
            /*.then(response => {
                dispatch({
                    type: MODELER_ACTIONS.NEW_MODEL,
                    payload: {
                        model_name: state.modeler.getModel().name,
                        model_innerID : "0",
                        model_unsaved: false,
                        loading: false,
                        refresh: true,
                    }
                });
                logger.log("dispatch new model ok");
                snackbar.showSuccess(`New model opened.`);
            })
            .catch(error => {
                logger.error(error.message);
                //throw new Error("Can't load model on canvas: " + err.message);
                dispatchError("New model: " + error.message);
            });*/
    };

    const updateModelName = (name, documentation) => {
        logger.log("update model name");
        state.modeler.getModel().name = name;
        if (documentation !== null) {
            state.modeler.getModel().documentation = documentation;
        }
        dispatch({
            type: MODELER_ACTIONS.UPDATE_MODEL_NAME,
            payload: {
                model_name: name,
                model_unsaved: true,
            }
        });              
    };

    const updateModelInnerID = (id) => {
        logger.log("update model innerID");
        dispatch({
            type: MODELER_ACTIONS.UPDATE_MODEL_INNERID,
            payload: {
                model_innerID: id,
            }
        });              
    };

    /*const saveModel = () => {
        logger.log("save model");
        dispatch({
            type: MODELER_ACTIONS.SAVE_MODEL,
            payload: {
                model_unsaved: false,
            }
        });              
    };*/

    const saveModel = async (name, documentation, nextStep) => {
        logger.log("save model");
        dispatchLoading();
        if (name !== null) {
            state.modeler.getModel().name = name;
        }
        if (documentation !== null) {
            state.modeler.getModel().documentation = documentation;
        }
        const xmlmodel = await state.modeler.saveXML({ format: true });
        const today = new Date();
        const input = {
            description: state.modeler.getModel().documentation,
            modelID: state.modeler.getModel().id,
            name: state.modeler.getModel().name,
            ownerID: user.userID,
            xmlmodel: xmlmodel.xml,
        };
        if (state.model_innerID === "0") {
            logger.log('Create new model in MDB');
            var data = Object.assign( input, {
                    createdate: today,
                    lastupdate: today,
                    readonly:false,
                });
            await createModel({ variables: { data: data }});
        } else {
            logger.log('Update model #' + state.model_innerID);
            var set = Object.assign( input, {
                    lastupdate: today,
                    readonly: state.model_readOnly,
                });                    
            await updateModel({ variables: { 
                query: { _id: state.model_innerID },
                set: set }});
        }
        if (nextStep !== undefined) {
            nextStep();
        }
            /*.then(xmlmodel => {
                const today = new Date();
                const input = {
                    description: state.modeler.getModel().documentation,
                    modelID: state.modeler.getModel().id,
                    name: state.modeler.getModel().name,
                    ownerID: user.userID,
                    xmlmodel: xmlmodel.xml
                };
                if (state.model_innerID === "0") {
                    logger.log('Create new model in MDB');
                    var data = Object.assign( input, {
                            createdate: today ,
                            lastupdate: today
                        });
                    createModel({ variables: { data: data }});
                } else {
                    logger.log('Update model #' + state.model_innerID);
                    var set = Object.assign( input, {
                            lastupdate: today
                        });                    
                    updateModel({ variables: { 
                        query: { _id: state.model_innerID },
                        set: set }});
                }
            })
            .catch(error => {
                logger.error(error.message);
                //throw new Error("Can't load model on canvas: " + err.message);
                dispatchError("Save model: " + error.message);
            });*/
    };


    const refreshed = () => {
        logger.log("model refreshed");
        dispatch({
            type: MODELER_ACTIONS.REFRESHED,
            payload: {
                refresh: false,
            }
        });              
    };

    const changed = () => {
        logger.log("model changed");
        dispatch({
            type: MODELER_ACTIONS.CHANGED,
            payload: {
                model_unsaved: true,
            }
        });              
    };


    const openModel = (innerID) => {
        logger.log("open model for id #" + innerID);
        dispatchLoading();
        loadXmlmodel({
            variables: { 
                query: { _id: innerID } 
            }});
    };

    const value = {
        loading: state.loading,
        error: state.error,
        refresh: state.refresh,
        modeler: state.modeler,
        model_name: state.model_name,
        model_innerID: state.model_innerID,
        model_unsaved: state.model_unsaved,
        model_readOnly: state.model_readOnly,
        initModeler,
        newModel,
        saveModel,
        openModel,
        updateModelName,
        updateModelInnerID,
        refreshed,
        changed
    }

    return (
        <ModelerContext.Provider value={value}>
            {children}
        </ModelerContext.Provider>
    );
};

// context consumer hook
const useModeler = () => {
    // get the context
    const context = useContext(ModelerContext);
  
    // if `undefined`, throw an error
    if (context === undefined) {
      throw new Error("useModeler was used outside of its Provider");
    }
  
    return context;
  };

export { ModelerProvider, useModeler };