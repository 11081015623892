import { useState } from "react";
import { useQuery } from "@apollo/client";
import { MODELS_BY_USER } from "../graphql-operations";
import { Divider, Typography, Grid, Card, CardHeader, IconButton, CardActions, CardContent, Dialog, DialogTitle, DialogContentText, DialogContent, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import { format } from "date-fns";
import { useUserContext } from "../UserContext";
//import useCustomSnackbar from "./CustomSnackbar/useCustomSnackbar";
import DeleteModelButton from "./DeleteModelButton";
import SaveCurrentModelDialog from "./SaveCurrentModelDialog";
import { useModeler } from "../ModelerContext";
import { logger } from "../logger";

export default function OpenModelDialog ({ isDialogOpen, handleCloseDialog }) {
    
    const [saveCurrentModelDialogIsOpen, setSaveCurrentModelDialogIsOpen] = useState(false);
    const [selectedModel, setSelectedModel] = useState(null);

    // by default, userID is set to Visitor's ID ("id-0")
    const { user } = useUserContext();
    const {openModel, model_unsaved} = useModeler();
    //const snackbar = useCustomSnackbar();

   
    // const { loading, error, data } = useQuery(ALL_MODELS);
    const { loading, error, data } = useQuery(MODELS_BY_USER, {
        variables: { query: { ownerID: user.userID } }
      });
/* 
    const [loadXmlmodel, { called, refetch }] = useLazyQuery(GET_XMLMODEL, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => { 
            logger.log('loadXmlmodel completed');
            var id = data.model._id,
                xmlmodel = data.model.xmlmodel;
            openModel(id, xmlmodel);
        },
        onError: (error) => {
            logger.error(error);
            snackbar.showError("Can't get model from server: " + error.message);
        }
    });

    const [deleteModel] = useMutation(DELETE_MODEL, {
        update: (cache, { data: { deleteOneModel } }) => {
            const { models } = cache.readQuery({
                query: MODELS_BY_USER,
                variables: { query: {ownerID: user.userID } }
            });
            const deletedModelID = deleteOneModel._id;
            const updatedModels = models.filter(model => model._id !== deletedModelID);
            cache.writeQuery({
                query: MODELS_BY_USER,
                variables: { query: {ownerID: user.userID } },
                data: {
                    models: updatedModels
                }
            });
        },
        onCompleted: (data) => {
            var id = data.deleteOneModel._id,
                name = data.deleteOneModel.name;
            logger.log(`Model #${id} successfully deleted`);
            snackbar.showSuccess(`${name} successfully deleted.`);
        },
        onError: (error) => {
            logger.error(error);
            snackbar.showError(error.message);
        }
    });
*/
    const handleOpen = (id) => {
        logger.log('handle open model for id #' + id);
        handleCloseDialog();
        if (model_unsaved) {
            logger.log('model_unsaved is true');
            setSelectedModel(id);
            setSaveCurrentModelDialogIsOpen(true);
        } else {
            logger.log('model_unsaved is false');
            //openSelectedModel();
            openModel(id);
        }
    };

    const openSelectedModel = () => {
        logger.log('Open model #'+selectedModel);
        openModel(selectedModel);
        /*
        loadXmlmodel({
            variables: { 
                query: { _id: selectedModel } 
            }});
        /*
        if (called) {
            logger.log('called is true');
            refetch();
          }
          else {
            logger.log('called is true');
            loadXmlmodel();
          }*/
    }

    /*const handleDelete = async (id) => {
        logger.log('Delete model #' + id);
        await deleteModel({ variables: { query: { _id: id  } }});
    };*/

    const handleCloseSaveCurrentModelDialog = () => {
        setSaveCurrentModelDialogIsOpen(false);
    };

    return(
        <>
        <SaveCurrentModelDialog
        isDialogOpen={saveCurrentModelDialogIsOpen}
        handleCloseDialog={handleCloseSaveCurrentModelDialog}
        selectedModel={selectedModel}
        nextStep={openSelectedModel}
        />
        <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle>
                My models
                <IconButton
                    onClick={handleCloseDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                    { loading && <DialogContentText>Loading...</DialogContentText> }
                    { error && <DialogContentText color="red">Encountered an error : {error.message}</DialogContentText> }
                    { data && (data.models.length > 0)
                        ? ( <Grid container spacing={1}>
                        { data.models.map((model) => (
                        <Grid item key={model._id} xs={12} md={12} lg={12}>
                            <Card elevation={1} sx={{ paddingTop: 0 , paddingBottom: 0 }} >
                                <CardHeader
                                    //action={
                                    //    <IconButton onClick={() => handleEdit(model._id)}>
                                    //        <EditOutlined />
                                    //    </IconButton>
                                    //}
                                    title={model.name}
                                    subheader={model.modelID}
                                    sx={{ paddingTop: 0.5 , paddingBottom: 0.5 }}
                                />
                                <CardContent sx={{ paddingTop: 0, paddingBottom: 0, marginBottom: 0, '&:last-child': { pb: 0 }}}>
                                    <Typography sx={{ paddingBottom: 0.5 }} >
                                        {model.description}
                                    </Typography>
                                    <Typography variant="overline" sx={{ paddingBottom: 0 }} >
                                        Creation date: { format(new Date(model.createdate), 'P HH:mm') }
                                        &nbsp;&nbsp;|&nbsp;&nbsp;Last update: { format(new Date(model.lastupdate), 'P HH:mm') }
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ paddingTop: 0, paddingBottom: 0.5, display: "flex", justifyContent: "space-between" }} >
                                    <Button size="small" onClick={() => handleOpen(model._id)}>Open model</Button>
                                    <DeleteModelButton 
                                        buttonLabel="Delete"
                                        ButtonProps={{ size: "small", color: "error", disabled: model.readonly }}
                                        modelName={model.name}
                                        modelId={model._id}
                                    />
                                </CardActions>
                            </Card>
                        </Grid>
                    )) }
                    </Grid> )
                    : ( <DialogContentText>No model available !</DialogContentText> )
                    }
            </DialogContent>
        </Dialog>
        </>
    );
}

/*
        <List
            sx={{flexGrow: 1}}
        >
          {baseElementsList.map((baseElement) => (
            <ListItem key={baseElement} disablePadding>
              <ListItemButton onClick={() => handleChange()}>
                <ListItemText primary={baseElement.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <DialogButton 
            buttonLabel="Delete"
            ButtonProps={{ size: "small", color: "error" }}
            dialogTitle="Delete model ?"
            dialogMessage={ "Do you really want to delete model '" + model.name + "' ?" }
            action={() => handleDelete(model._id)}
            actionButtonLabel="Delete"
            actionButtonColor="error"
        />
*/