import { useReducer } from "react";
import { is, NOTE, VIEW, CONNECTION_RELATIONSHIP, CONNECTION_LINE } from "archimate-js/lib/util/ModelUtil";
import { logger } from "../../logger";

export const ARCHIMATE_NODE = 'archimate:Node',
    ARCHIMATE_CONNECTION = 'archimate:Connection',
    ARCHIMATE_VIEW = 'archimate:View';

const INITIAL_STATE = {
    archiType: "",
    type: "",
    name: "",
    documentation: "",
    label: "",
    fillColor: null,
    lineColor: null,
};

const ELEMENT_ACTIONS = {
    INIT_ELEMENT: "INIT_ELEMENT",
    UPDATE_INPUT: "UPDATE_INPUT",
    UPDATE_DOCUMENTATION: "UPDATE_DOCUMENTATION",
};

const inspectData = (data) => {
    if (data.type === VIEW) {
        return {
            archiType: ARCHIMATE_VIEW,
            type: data.type,
            name: (data.businessObject.name || ""),
            documentation: (data.businessObject.documentation || ""),
            label: "",
            fillColor: null,
            lineColor: null,
        };
    } else if (data.type === NOTE) {
        return {
            archiType: ARCHIMATE_NODE,
            type: data.type,
            name: "",
            documentation: "",
            label: data.businessObject.label || "",
            fillColor: data.fillColor,
            lineColor: data.lineColor,
        };
    } else if (is(data.businessObject, ARCHIMATE_NODE)) {
        return {
            archiType: ARCHIMATE_NODE,
            type: data.type,
            name: (data.businessObject.elementRef && data.businessObject.elementRef.name || ""),
            documentation: (data.businessObject.elementRef && data.businessObject.elementRef.documentation || ""),
            label: "",
            fillColor: data.fillColor,
            lineColor: data.lineColor,
        };
    } else if (is(data.businessObject, ARCHIMATE_CONNECTION)) {
        return {
            archiType: ARCHIMATE_CONNECTION,
            type: data.type,
            name: (data.businessObject.relationshipRef && data.businessObject.relationshipRef.name || ""),
            documentation: (data.businessObject.relationshipRef && data.businessObject.relationshipRef.documentation || ""),
            label: "",
            fillColor: null,
            lineColor: data.lineColor || "#ff0000",
        };
    }
};

const elementReducer = (state, action) => {
    switch (action.type) {
        case ELEMENT_ACTIONS.INIT_ELEMENT:
            var element = inspectData(action.payload);
            if (element.type === CONNECTION_RELATIONSHIP || element.type === CONNECTION_LINE) {
                return {
                    ...state,
                    archiType: element.archiType,
                    type: null,
                    name: null,
                    documentation: null,
                    label: null,
                    fillColor: null,
                    lineColor: null,
                };
            } else {
                return {
                    ...state,
                    archiType: element.archiType,
                    type: element.type,
                    name: element.name,
                    documentation: element.documentation,
                    label: element.label,
                    fillColor: element.fillColor,
                    lineColor: element.lineColor,
                };        
            }
        case ELEMENT_ACTIONS.UPDATE_INPUT:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        case ELEMENT_ACTIONS.UPDATE_DOCUMENTATION:
            return {
                ...state,
                [action.payload.documentation]: action.payload.value,
            };
        default:
        return state;
    }
};

const useElementReducer = () => {
    const [state, dispatch] = useReducer(elementReducer, INITIAL_STATE);
    return [state, dispatch];
};

export {useElementReducer, ELEMENT_ACTIONS }