import * as CX from './Concept';

export const RELATIONSHIPS_ALLOWED_FROM_OTHER_LOCATION = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'cgno'],
    [CX.MOTIVATION_CONSTRAINT, 'cgrno'],
    [CX.MOTIVATION_DRIVER, 'cgno'],
    [CX.MOTIVATION_GOAL, 'cgrno'],
    [CX.MOTIVATION_MEANING, 'cgno'],
    [CX.MOTIVATION_OUTCOME, 'cgrno'],
    [CX.MOTIVATION_PRINCIPLE, 'cgrno'],
    [CX.MOTIVATION_REQUIREMENT, 'cgrno'],
    [CX.MOTIVATION_STAKEHOLDER, 'cgirno'],
    [CX.MOTIVATION_VALUE, 'cgno'],
    [CX.STRATEGY_CAPABILITY, 'cgro'],
    [CX.STRATEGY_VALUE_STREAM, 'cgro'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'cgro'],
    [CX.STRATEGY_RESOURCE, 'cgro'],
    [CX.BUSINESS_ACTOR, 'cgirvtfo'],
    [CX.BUSINESS_COLLABORATION, 'cgirvtfo'],
    [CX.BUSINESS_EVENT, 'cgirvtfo'],
    [CX.BUSINESS_FUNCTION, 'cgirvtfo'],
    [CX.BUSINESS_INTERACTION, 'cgirvtfo'],
    [CX.BUSINESS_INTERFACE, 'cgirvtfo'],
    [CX.BUSINESS_OBJECT, 'cgao'],
    [CX.BUSINESS_PROCESS, 'cgirvtfo'],
    [CX.BUSINESS_ROLE, 'cgirvtfo'],
    [CX.BUSINESS_SERVICE, 'cgirvtfo'],
    [CX.BUSINESS_CONTRACT, 'cgao'],
    [CX.BUSINESS_PRODUCT, 'cgvtfo'],
    [CX.BUSINESS_REPRESENTATION, 'cgao'],
    [CX.APPLICATION_COLLABORATION, 'cgrvtfo'],
    [CX.APPLICATION_COMPONENT, 'cgrvtfo'],
    [CX.APPLICATION_EVENT, 'cgirvtfo'],
    [CX.APPLICATION_FUNCTION, 'cgirvtfo'],
    [CX.APPLICATION_INTERACTION, 'cgirvtfo'],
    [CX.APPLICATION_INTERFACE, 'cgrvtfo'],
    [CX.APPLICATION_PROCESS, 'cgirvtfo'],
    [CX.APPLICATION_SERVICE, 'cgirvtfo'],
    [CX.APPLICATION_DATA_OBJECT, 'cgao'],
    [CX.TECHNOLOGY_ARTIFACT, 'cgiao'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'cgvtfo'],
    [CX.TECHNOLOGY_DEVICE, 'cgirvtfo'],
    [CX.TECHNOLOGY_NODE, 'cgirvtfo'],
    [CX.TECHNOLOGY_PATH, 'cgrvtfo'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'cgirvtfo'],
    [CX.TECHNOLOGY_COLLABORATION, 'cgrvtfo'],
    [CX.TECHNOLOGY_EVENT, 'cgirvtfo'],
    [CX.TECHNOLOGY_FUNCTION, 'cgirvtfo'],
    [CX.TECHNOLOGY_INTERACTION, 'cgirvtfo'],
    [CX.TECHNOLOGY_INTERFACE, 'cgirvtfo'],
    [CX.TECHNOLOGY_PROCESS, 'cgirvtfo'],
    [CX.TECHNOLOGY_SERVICE, 'cgirvtfo'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'cgvtfo'],
    [CX.PHYSICAL_EQUIPMENT, 'cgirvtfo'],
    [CX.PHYSICAL_FACILITY, 'cgirvtfo'],
    [CX.PHYSICAL_MATERIAL, 'cgiao'],
    [CX.IMP_MIG_DELIVERABLE, 'cgo'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'cgio'],
    [CX.IMP_MIG_WORK_PACKAGE, 'cgio'],
    [CX.IMP_MIG_GAP, 'cgo'],
    [CX.IMP_MIG_PLATEAU, 'cgo'],
    [CX.OTHER_LOCATION, 'scgvtfo'],
    [CX.OTHER_GROUPING, 'scgirvantfo'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
]);

export const RELATIONSHIPS_ALLOWED_FROM_OTHER_GROUPING = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'scgno'],
    [CX.MOTIVATION_CONSTRAINT, 'scgrno'],
    [CX.MOTIVATION_DRIVER, 'scgno'],
    [CX.MOTIVATION_GOAL, 'scgrno'],
    [CX.MOTIVATION_MEANING, 'scgno'],
    [CX.MOTIVATION_OUTCOME, 'scgrno'],
    [CX.MOTIVATION_PRINCIPLE, 'scgrno'],
    [CX.MOTIVATION_REQUIREMENT, 'scgrno'],
    [CX.MOTIVATION_STAKEHOLDER, 'scgirno'],
    [CX.MOTIVATION_VALUE, 'scgno'],
    [CX.STRATEGY_CAPABILITY, 'scgirvtfo'],
    [CX.STRATEGY_VALUE_STREAM, 'scgirvtfo'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'scgrvtfo'],
    [CX.STRATEGY_RESOURCE, 'scgrvtfo'],
    [CX.BUSINESS_ACTOR, 'scgirvtfo'],
    [CX.BUSINESS_COLLABORATION, 'scgirvtfo'],
    [CX.BUSINESS_EVENT, 'scgirvtfo'],
    [CX.BUSINESS_FUNCTION, 'scgirvtfo'],
    [CX.BUSINESS_INTERACTION, 'scgirvtfo'],
    [CX.BUSINESS_INTERFACE, 'scgirvtfo'],
    [CX.BUSINESS_OBJECT, 'scgrao'],
    [CX.BUSINESS_PROCESS, 'scgirvtfo'],
    [CX.BUSINESS_ROLE, 'scgirvtfo'],
    [CX.BUSINESS_SERVICE, 'scgirvtfo'],
    [CX.BUSINESS_CONTRACT, 'scgrao'],
    [CX.BUSINESS_PRODUCT, 'scgrvtfo'],
    [CX.BUSINESS_REPRESENTATION, 'scgrao'],
    [CX.APPLICATION_COLLABORATION, 'scgrvtfo'],
    [CX.APPLICATION_COMPONENT, 'scgrvtfo'],
    [CX.APPLICATION_EVENT, 'scgirvtfo'],
    [CX.APPLICATION_FUNCTION, 'scgirvtfo'],
    [CX.APPLICATION_INTERACTION, 'scgirvtfo'],
    [CX.APPLICATION_INTERFACE, 'scgrvtfo'],
    [CX.APPLICATION_PROCESS, 'scgirvtfo'],
    [CX.APPLICATION_SERVICE, 'scgirvtfo'],
    [CX.APPLICATION_DATA_OBJECT, 'scgrao'],
    [CX.TECHNOLOGY_ARTIFACT, 'scgirao'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'scgrvtfo'],
    [CX.TECHNOLOGY_DEVICE, 'scgirvtfo'],
    [CX.TECHNOLOGY_NODE, 'scgirvtfo'],
    [CX.TECHNOLOGY_PATH, 'scgrvtfo'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'scgirvtfo'],
    [CX.TECHNOLOGY_COLLABORATION, 'scgrvtfo'],
    [CX.TECHNOLOGY_EVENT, 'scgirvtfo'],
    [CX.TECHNOLOGY_FUNCTION, 'scgirvtfo'],
    [CX.TECHNOLOGY_INTERACTION, 'scgirvtfo'],
    [CX.TECHNOLOGY_INTERFACE, 'scgirvtfo'],
    [CX.TECHNOLOGY_PROCESS, 'scgirvtfo'],
    [CX.TECHNOLOGY_SERVICE, 'scgirvtfo'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'scgrvtfo'],
    [CX.PHYSICAL_EQUIPMENT, 'scgirvtfo'],
    [CX.PHYSICAL_FACILITY, 'scgirvtfo'],
    [CX.PHYSICAL_MATERIAL, 'scgirao'],
    [CX.IMP_MIG_DELIVERABLE, 'scgrao'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'scgitfo'],
    [CX.IMP_MIG_WORK_PACKAGE, 'scgitfo'],
    [CX.IMP_MIG_GAP, 'scgo'],
    [CX.IMP_MIG_PLATEAU, 'scgrtfo'],
    [CX.OTHER_LOCATION, 'scgrvtfo'],
    [CX.OTHER_GROUPING, 'scgirvantfo'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
]);