import * as CX from './Concept';

export const RELATIONSHIPS_ALLOWED_FROM_BUSINESS_ACTOR = new Map([
  [CX.MOTIVATION_ASSESSMENT, 'no'],
  [CX.MOTIVATION_CONSTRAINT, 'rno'],
  [CX.MOTIVATION_DRIVER, 'no'],
  [CX.MOTIVATION_GOAL, 'rno'],
  [CX.MOTIVATION_MEANING, 'no'],
  [CX.MOTIVATION_OUTCOME, 'rno'],
  [CX.MOTIVATION_PRINCIPLE, 'rno'],
  [CX.MOTIVATION_REQUIREMENT, 'rno'],
  [CX.MOTIVATION_STAKEHOLDER, 'ino'],
  [CX.MOTIVATION_VALUE, 'no'],
  [CX.STRATEGY_CAPABILITY, 'ro'],
  [CX.STRATEGY_VALUE_STREAM, 'ro'],
  [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
  [CX.STRATEGY_RESOURCE, 'ro'],
  [CX.BUSINESS_ACTOR, 'scgvtfo'],
  [CX.BUSINESS_COLLABORATION, 'vtfo'],
  [CX.BUSINESS_EVENT, 'ivtfo'],
  [CX.BUSINESS_FUNCTION, 'ivtfo'],
  [CX.BUSINESS_INTERACTION, 'ivtfo'],
  [CX.BUSINESS_INTERFACE, 'cgivtfo'],
  [CX.BUSINESS_OBJECT, 'ao'],
  [CX.BUSINESS_PROCESS, 'ivtfo'],
  [CX.BUSINESS_ROLE, 'ivtfo'],
  [CX.BUSINESS_SERVICE, 'irvtfo'],
  [CX.BUSINESS_CONTRACT, 'ao'],
  [CX.BUSINESS_PRODUCT, 'vtfo'],
  [CX.BUSINESS_REPRESENTATION, 'ao'],
  [CX.APPLICATION_COLLABORATION, 'vtfo'],
  [CX.APPLICATION_COMPONENT, 'vtfo'],
  [CX.APPLICATION_EVENT, 'vtfo'],
  [CX.APPLICATION_FUNCTION, 'vtfo'],
  [CX.APPLICATION_INTERACTION, 'vtfo'],
  [CX.APPLICATION_INTERFACE, 'vtfo'],
  [CX.APPLICATION_PROCESS, 'vtfo'],
  [CX.APPLICATION_SERVICE, 'vtfo'],
  [CX.APPLICATION_DATA_OBJECT, 'ao'],
  [CX.TECHNOLOGY_ARTIFACT, 'ao'],
  [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'vtfo'],
  [CX.TECHNOLOGY_DEVICE, 'vtfo'],
  [CX.TECHNOLOGY_NODE, 'vtfo'],
  [CX.TECHNOLOGY_PATH, 'vtfo'],
  [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'vtfo'],
  [CX.TECHNOLOGY_COLLABORATION, 'vtfo'],
  [CX.TECHNOLOGY_EVENT, 'vtfo'],
  [CX.TECHNOLOGY_FUNCTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERACTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERFACE, 'vtfo'],
  [CX.TECHNOLOGY_PROCESS, 'vtfo'],
  [CX.TECHNOLOGY_SERVICE, 'vtfo'],
  [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'vtfo'],
  [CX.PHYSICAL_EQUIPMENT, 'vtfo'],
  [CX.PHYSICAL_FACILITY, 'vtfo'],
  [CX.PHYSICAL_MATERIAL, 'ao'],
  [CX.IMP_MIG_DELIVERABLE, 'o'],
  [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'io'],
  [CX.IMP_MIG_WORK_PACKAGE, 'io'],
  [CX.IMP_MIG_GAP, 'o'],
  [CX.IMP_MIG_PLATEAU, 'o'],
  [CX.OTHER_LOCATION, 'vtfo'],
  [CX.OTHER_GROUPING, 'scgirvantfo'],
  [CX.RELATIONSHIP_ACCESS, 'o'],
  [CX.RELATIONSHIP_AGGREGATION, 'o'],
  [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
  [CX.RELATIONSHIP_ASSOCIATION, 'o'],
  [CX.RELATIONSHIP_COMPOSITION, 'o'],
  [CX.RELATIONSHIP_FLOW, 'o'],
  [CX.RELATIONSHIP_INFLUENCE, 'o'],
  [CX.RELATIONSHIP_JUNCTION_AND, ''],
  [CX.RELATIONSHIP_JUNCTION_OR, ''],
  [CX.RELATIONSHIP_REALIZATION, 'o'],
  [CX.RELATIONSHIP_SERVING, 'o'],
  [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
  [CX.RELATIONSHIP_TRIGGERING, 'o']
]);

export const RELATIONSHIPS_ALLOWED_FROM_BUSINESS_COLLABORATION = new Map([
  [CX.MOTIVATION_ASSESSMENT, 'no'],
  [CX.MOTIVATION_CONSTRAINT, 'rno'],
  [CX.MOTIVATION_DRIVER, 'no'],
  [CX.MOTIVATION_GOAL, 'rno'],
  [CX.MOTIVATION_MEANING, 'no'],
  [CX.MOTIVATION_OUTCOME, 'rno'],
  [CX.MOTIVATION_PRINCIPLE, 'rno'],
  [CX.MOTIVATION_REQUIREMENT, 'rno'],
  [CX.MOTIVATION_STAKEHOLDER, 'ino'],
  [CX.MOTIVATION_VALUE, 'no'],
  [CX.STRATEGY_CAPABILITY, 'ro'],
  [CX.STRATEGY_VALUE_STREAM, 'ro'],
  [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
  [CX.STRATEGY_RESOURCE, 'ro'],
  [CX.BUSINESS_ACTOR, 'gvtfo'],
  [CX.BUSINESS_COLLABORATION, 'scgvtfo'],
  [CX.BUSINESS_EVENT, 'ivtfo'],
  [CX.BUSINESS_FUNCTION, 'ivtfo'],
  [CX.BUSINESS_INTERACTION, 'ivtfo'],
  [CX.BUSINESS_INTERFACE, 'cgivtfo'],
  [CX.BUSINESS_OBJECT, 'ao'],
  [CX.BUSINESS_PROCESS, 'ivtfo'],
  [CX.BUSINESS_ROLE, 'givtfo'],
  [CX.BUSINESS_SERVICE, 'irvtfo'],
  [CX.BUSINESS_CONTRACT, 'ao'],
  [CX.BUSINESS_PRODUCT, 'vtfo'],
  [CX.BUSINESS_REPRESENTATION, 'ao'],
  [CX.APPLICATION_COLLABORATION, 'vtfo'],
  [CX.APPLICATION_COMPONENT, 'vtfo'],
  [CX.APPLICATION_EVENT, 'vtfo'],
  [CX.APPLICATION_FUNCTION, 'vtfo'],
  [CX.APPLICATION_INTERACTION, 'vtfo'],
  [CX.APPLICATION_INTERFACE, 'vtfo'],
  [CX.APPLICATION_PROCESS, 'vtfo'],
  [CX.APPLICATION_SERVICE, 'vtfo'],
  [CX.APPLICATION_DATA_OBJECT, 'ao'],
  [CX.TECHNOLOGY_ARTIFACT, 'ao'],
  [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'vtfo'],
  [CX.TECHNOLOGY_DEVICE, 'vtfo'],
  [CX.TECHNOLOGY_NODE, 'vtfo'],
  [CX.TECHNOLOGY_PATH, 'vtfo'],
  [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'vtfo'],
  [CX.TECHNOLOGY_COLLABORATION, 'vtfo'],
  [CX.TECHNOLOGY_EVENT, 'vtfo'],
  [CX.TECHNOLOGY_FUNCTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERACTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERFACE, 'vtfo'],
  [CX.TECHNOLOGY_PROCESS, 'vtfo'],
  [CX.TECHNOLOGY_SERVICE, 'vtfo'],
  [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'vtfo'],
  [CX.PHYSICAL_EQUIPMENT, 'vtfo'],
  [CX.PHYSICAL_FACILITY, 'vtfo'],
  [CX.PHYSICAL_MATERIAL, 'ao'],
  [CX.IMP_MIG_DELIVERABLE, 'o'],
  [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'io'],
  [CX.IMP_MIG_WORK_PACKAGE, 'io'],
  [CX.IMP_MIG_GAP, 'o'],
  [CX.IMP_MIG_PLATEAU, 'o'],
  [CX.OTHER_LOCATION, 'vtfo'],
  [CX.OTHER_GROUPING, 'scgirvantfo'],
  [CX.RELATIONSHIP_ACCESS, 'o'],
  [CX.RELATIONSHIP_AGGREGATION, 'o'],
  [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
  [CX.RELATIONSHIP_ASSOCIATION, 'o'],
  [CX.RELATIONSHIP_COMPOSITION, 'o'],
  [CX.RELATIONSHIP_FLOW, 'o'],
  [CX.RELATIONSHIP_INFLUENCE, 'o'],
  [CX.RELATIONSHIP_JUNCTION_AND, ''],
  [CX.RELATIONSHIP_JUNCTION_OR, ''],
  [CX.RELATIONSHIP_REALIZATION, 'o'],
  [CX.RELATIONSHIP_SERVING, 'o'],
  [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
  [CX.RELATIONSHIP_TRIGGERING, 'o']
]);

export const RELATIONSHIPS_ALLOWED_FROM_BUSINESS_EVENT = new Map([
  [CX.MOTIVATION_ASSESSMENT, 'no'],
  [CX.MOTIVATION_CONSTRAINT, 'rno'],
  [CX.MOTIVATION_DRIVER, 'no'],
  [CX.MOTIVATION_GOAL, 'rno'],
  [CX.MOTIVATION_MEANING, 'no'],
  [CX.MOTIVATION_OUTCOME, 'rno'],
  [CX.MOTIVATION_PRINCIPLE, 'rno'],
  [CX.MOTIVATION_REQUIREMENT, 'rno'],
  [CX.MOTIVATION_STAKEHOLDER, 'no'],
  [CX.MOTIVATION_VALUE, 'no'],
  [CX.STRATEGY_CAPABILITY, 'o'],
  [CX.STRATEGY_VALUE_STREAM, 'o'],
  [CX.STRATEGY_COURSE_OF_ACTION, 'o'],
  [CX.STRATEGY_RESOURCE, 'o'],
  [CX.BUSINESS_ACTOR, 'vtfo'],
  [CX.BUSINESS_COLLABORATION, 'vtfo'],
  [CX.BUSINESS_EVENT, 'scgvtfo'],
  [CX.BUSINESS_FUNCTION, 'vtfo'],
  [CX.BUSINESS_INTERACTION, 'vtfo'],
  [CX.BUSINESS_INTERFACE, 'vtfo'],
  [CX.BUSINESS_OBJECT, 'ao'],
  [CX.BUSINESS_PROCESS, 'vtfo'],
  [CX.BUSINESS_ROLE, 'vtfo'],
  [CX.BUSINESS_SERVICE, 'vtfo'],
  [CX.BUSINESS_CONTRACT, 'ao'],
  [CX.BUSINESS_PRODUCT, 'vtfo'],
  [CX.BUSINESS_REPRESENTATION, 'ao'],
  [CX.APPLICATION_COLLABORATION, 'vtfo'],
  [CX.APPLICATION_COMPONENT, 'vtfo'],
  [CX.APPLICATION_EVENT, 'vtfo'],
  [CX.APPLICATION_FUNCTION, 'vtfo'],
  [CX.APPLICATION_INTERACTION, 'vtfo'],
  [CX.APPLICATION_INTERFACE, 'vtfo'],
  [CX.APPLICATION_PROCESS, 'vtfo'],
  [CX.APPLICATION_SERVICE, 'vtfo'],
  [CX.APPLICATION_DATA_OBJECT, 'ao'],
  [CX.TECHNOLOGY_ARTIFACT, 'ao'],
  [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'vtfo'],
  [CX.TECHNOLOGY_DEVICE, 'vtfo'],
  [CX.TECHNOLOGY_NODE, 'vtfo'],
  [CX.TECHNOLOGY_PATH, 'vtfo'],
  [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'vtfo'],
  [CX.TECHNOLOGY_COLLABORATION, 'vtfo'],
  [CX.TECHNOLOGY_EVENT, 'vtfo'],
  [CX.TECHNOLOGY_FUNCTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERACTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERFACE, 'vtfo'],
  [CX.TECHNOLOGY_PROCESS, 'vtfo'],
  [CX.TECHNOLOGY_SERVICE, 'vtfo'],
  [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'vtfo'],
  [CX.PHYSICAL_EQUIPMENT, 'vtfo'],
  [CX.PHYSICAL_FACILITY, 'vtfo'],
  [CX.PHYSICAL_MATERIAL, 'ao'],
  [CX.IMP_MIG_DELIVERABLE, 'o'],
  [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
  [CX.IMP_MIG_WORK_PACKAGE, 'o'],
  [CX.IMP_MIG_GAP, 'o'],
  [CX.IMP_MIG_PLATEAU, 'o'],
  [CX.OTHER_LOCATION, 'vtfo'],
  [CX.OTHER_GROUPING, 'scgrvantfo'],
  [CX.RELATIONSHIP_ACCESS, 'o'],
  [CX.RELATIONSHIP_AGGREGATION, 'o'],
  [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
  [CX.RELATIONSHIP_ASSOCIATION, 'o'],
  [CX.RELATIONSHIP_COMPOSITION, 'o'],
  [CX.RELATIONSHIP_FLOW, 'o'],
  [CX.RELATIONSHIP_INFLUENCE, 'o'],
  [CX.RELATIONSHIP_JUNCTION_AND, ''],
  [CX.RELATIONSHIP_JUNCTION_OR, ''],
  [CX.RELATIONSHIP_REALIZATION, 'o'],
  [CX.RELATIONSHIP_SERVING, 'o'],
  [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
  [CX.RELATIONSHIP_TRIGGERING, 'o']
]);

export const RELATIONSHIPS_ALLOWED_FROM_BUSINESS_FUNCTION = new Map([
  [CX.MOTIVATION_ASSESSMENT, 'no'],
  [CX.MOTIVATION_CONSTRAINT, 'rno'],
  [CX.MOTIVATION_DRIVER, 'no'],
  [CX.MOTIVATION_GOAL, 'rno'],
  [CX.MOTIVATION_MEANING, 'no'],
  [CX.MOTIVATION_OUTCOME, 'rno'],
  [CX.MOTIVATION_PRINCIPLE, 'rno'],
  [CX.MOTIVATION_REQUIREMENT, 'rno'],
  [CX.MOTIVATION_STAKEHOLDER, 'no'],
  [CX.MOTIVATION_VALUE, 'no'],
  [CX.STRATEGY_CAPABILITY, 'ro'],
  [CX.STRATEGY_VALUE_STREAM, 'ro'],
  [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
  [CX.STRATEGY_RESOURCE, 'o'],
  [CX.BUSINESS_ACTOR, 'vtfo'],
  [CX.BUSINESS_COLLABORATION, 'vtfo'],
  [CX.BUSINESS_EVENT, 'vtfo'],
  [CX.BUSINESS_FUNCTION, 'scgvtfo'],
  [CX.BUSINESS_INTERACTION, 'cgvtfo'],
  [CX.BUSINESS_INTERFACE, 'vtfo'],
  [CX.BUSINESS_OBJECT, 'ao'],
  [CX.BUSINESS_PROCESS, 'cgvtfo'],
  [CX.BUSINESS_ROLE, 'vtfo'],
  [CX.BUSINESS_SERVICE, 'rvtfo'],
  [CX.BUSINESS_CONTRACT, 'ao'],
  [CX.BUSINESS_PRODUCT, 'vtfo'],
  [CX.BUSINESS_REPRESENTATION, 'ao'],
  [CX.APPLICATION_COLLABORATION, 'vtfo'],
  [CX.APPLICATION_COMPONENT, 'vtfo'],
  [CX.APPLICATION_EVENT, 'vtfo'],
  [CX.APPLICATION_FUNCTION, 'vtfo'],
  [CX.APPLICATION_INTERACTION, 'vtfo'],
  [CX.APPLICATION_INTERFACE, 'vtfo'],
  [CX.APPLICATION_PROCESS, 'vtfo'],
  [CX.APPLICATION_SERVICE, 'vtfo'],
  [CX.APPLICATION_DATA_OBJECT, 'ao'],
  [CX.TECHNOLOGY_ARTIFACT, 'ao'],
  [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'vtfo'],
  [CX.TECHNOLOGY_DEVICE, 'vtfo'],
  [CX.TECHNOLOGY_NODE, 'vtfo'],
  [CX.TECHNOLOGY_PATH, 'vtfo'],
  [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'vtfo'],
  [CX.TECHNOLOGY_COLLABORATION, 'vtfo'],
  [CX.TECHNOLOGY_EVENT, 'vtfo'],
  [CX.TECHNOLOGY_FUNCTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERACTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERFACE, 'vtfo'],
  [CX.TECHNOLOGY_PROCESS, 'vtfo'],
  [CX.TECHNOLOGY_SERVICE, 'vtfo'],
  [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'vtfo'],
  [CX.PHYSICAL_EQUIPMENT, 'vtfo'],
  [CX.PHYSICAL_FACILITY, 'vtfo'],
  [CX.PHYSICAL_MATERIAL, 'ao'],
  [CX.IMP_MIG_DELIVERABLE, 'o'],
  [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
  [CX.IMP_MIG_WORK_PACKAGE, 'o'],
  [CX.IMP_MIG_GAP, 'o'],
  [CX.IMP_MIG_PLATEAU, 'o'],
  [CX.OTHER_LOCATION, 'vtfo'],
  [CX.OTHER_GROUPING, 'scgrvantfo'],
  [CX.RELATIONSHIP_ACCESS, 'o'],
  [CX.RELATIONSHIP_AGGREGATION, 'o'],
  [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
  [CX.RELATIONSHIP_ASSOCIATION, 'o'],
  [CX.RELATIONSHIP_COMPOSITION, 'o'],
  [CX.RELATIONSHIP_FLOW, 'o'],
  [CX.RELATIONSHIP_INFLUENCE, 'o'],
  [CX.RELATIONSHIP_JUNCTION_AND, ''],
  [CX.RELATIONSHIP_JUNCTION_OR, ''],
  [CX.RELATIONSHIP_REALIZATION, 'o'],
  [CX.RELATIONSHIP_SERVING, 'o'],
  [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
  [CX.RELATIONSHIP_TRIGGERING, 'o']
]);

export const RELATIONSHIPS_ALLOWED_FROM_BUSINESS_INTERACTION = new Map([
  [CX.MOTIVATION_ASSESSMENT, 'no'],
  [CX.MOTIVATION_CONSTRAINT, 'rno'],
  [CX.MOTIVATION_DRIVER, 'no'],
  [CX.MOTIVATION_GOAL, 'rno'],
  [CX.MOTIVATION_MEANING, 'no'],
  [CX.MOTIVATION_OUTCOME, 'rno'],
  [CX.MOTIVATION_PRINCIPLE, 'rno'],
  [CX.MOTIVATION_REQUIREMENT, 'rno'],
  [CX.MOTIVATION_STAKEHOLDER, 'no'],
  [CX.MOTIVATION_VALUE, 'no'],
  [CX.STRATEGY_CAPABILITY, 'ro'],
  [CX.STRATEGY_VALUE_STREAM, 'ro'],
  [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
  [CX.STRATEGY_RESOURCE, 'o'],
  [CX.BUSINESS_ACTOR, 'vtfo'],
  [CX.BUSINESS_COLLABORATION, 'vtfo'],
  [CX.BUSINESS_EVENT, 'vtfo'],
  [CX.BUSINESS_FUNCTION, 'cgvtfo'],
  [CX.BUSINESS_INTERACTION, 'scgvtfo'],
  [CX.BUSINESS_INTERFACE, 'vtfo'],
  [CX.BUSINESS_OBJECT, 'ao'],
  [CX.BUSINESS_PROCESS, 'cgvtfo'],
  [CX.BUSINESS_ROLE, 'vtfo'],
  [CX.BUSINESS_SERVICE, 'rvtfo'],
  [CX.BUSINESS_CONTRACT, 'ao'],
  [CX.BUSINESS_PRODUCT, 'vtfo'],
  [CX.BUSINESS_REPRESENTATION, 'ao'],
  [CX.APPLICATION_COLLABORATION, 'vtfo'],
  [CX.APPLICATION_COMPONENT, 'vtfo'],
  [CX.APPLICATION_EVENT, 'vtfo'],
  [CX.APPLICATION_FUNCTION, 'vtfo'],
  [CX.APPLICATION_INTERACTION, 'vtfo'],
  [CX.APPLICATION_INTERFACE, 'vtfo'],
  [CX.APPLICATION_PROCESS, 'vtfo'],
  [CX.APPLICATION_SERVICE, 'vtfo'],
  [CX.APPLICATION_DATA_OBJECT, 'ao'],
  [CX.TECHNOLOGY_ARTIFACT, 'ao'],
  [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'vtfo'],
  [CX.TECHNOLOGY_DEVICE, 'vtfo'],
  [CX.TECHNOLOGY_NODE, 'vtfo'],
  [CX.TECHNOLOGY_PATH, 'vtfo'],
  [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'vtfo'],
  [CX.TECHNOLOGY_COLLABORATION, 'vtfo'],
  [CX.TECHNOLOGY_EVENT, 'vtfo'],
  [CX.TECHNOLOGY_FUNCTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERACTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERFACE, 'vtfo'],
  [CX.TECHNOLOGY_PROCESS, 'vtfo'],
  [CX.TECHNOLOGY_SERVICE, 'vtfo'],
  [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'vtfo'],
  [CX.PHYSICAL_EQUIPMENT, 'vtfo'],
  [CX.PHYSICAL_FACILITY, 'vtfo'],
  [CX.PHYSICAL_MATERIAL, 'ao'],
  [CX.IMP_MIG_DELIVERABLE, 'o'],
  [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
  [CX.IMP_MIG_WORK_PACKAGE, 'o'],
  [CX.IMP_MIG_GAP, 'o'],
  [CX.IMP_MIG_PLATEAU, 'o'],
  [CX.OTHER_LOCATION, 'vtfo'],
  [CX.OTHER_GROUPING, 'scgrvantfo'],
  [CX.RELATIONSHIP_ACCESS, 'o'],
  [CX.RELATIONSHIP_AGGREGATION, 'o'],
  [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
  [CX.RELATIONSHIP_ASSOCIATION, 'o'],
  [CX.RELATIONSHIP_COMPOSITION, 'o'],
  [CX.RELATIONSHIP_FLOW, 'o'],
  [CX.RELATIONSHIP_INFLUENCE, 'o'],
  [CX.RELATIONSHIP_JUNCTION_AND, ''],
  [CX.RELATIONSHIP_JUNCTION_OR, ''],
  [CX.RELATIONSHIP_REALIZATION, 'o'],
  [CX.RELATIONSHIP_SERVING, 'o'],
  [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
  [CX.RELATIONSHIP_TRIGGERING, 'o']
]);

export const RELATIONSHIPS_ALLOWED_FROM_BUSINESS_INTERFACE = new Map([
  [CX.MOTIVATION_ASSESSMENT, 'no'],
  [CX.MOTIVATION_CONSTRAINT, 'rno'],
  [CX.MOTIVATION_DRIVER, 'no'],
  [CX.MOTIVATION_GOAL, 'rno'],
  [CX.MOTIVATION_MEANING, 'no'],
  [CX.MOTIVATION_OUTCOME, 'rno'],
  [CX.MOTIVATION_PRINCIPLE, 'rno'],
  [CX.MOTIVATION_REQUIREMENT, 'rno'],
  [CX.MOTIVATION_STAKEHOLDER, 'no'],
  [CX.MOTIVATION_VALUE, 'no'],
  [CX.STRATEGY_CAPABILITY, 'ro'],
  [CX.STRATEGY_VALUE_STREAM, 'ro'],
  [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
  [CX.STRATEGY_RESOURCE, 'ro'],
  [CX.BUSINESS_ACTOR, 'vtfo'],
  [CX.BUSINESS_COLLABORATION, 'vtfo'],
  [CX.BUSINESS_EVENT, 'vtfo'],
  [CX.BUSINESS_FUNCTION, 'vtfo'],
  [CX.BUSINESS_INTERACTION, 'vtfo'],
  [CX.BUSINESS_INTERFACE, 'scgvtfo'],
  [CX.BUSINESS_OBJECT, 'ao'],
  [CX.BUSINESS_PROCESS, 'vtfo'],
  [CX.BUSINESS_ROLE, 'vtfo'],
  [CX.BUSINESS_SERVICE, 'ivtfo'],
  [CX.BUSINESS_CONTRACT, 'ao'],
  [CX.BUSINESS_PRODUCT, 'vtfo'],
  [CX.BUSINESS_REPRESENTATION, 'ao'],
  [CX.APPLICATION_COLLABORATION, 'vtfo'],
  [CX.APPLICATION_COMPONENT, 'vtfo'],
  [CX.APPLICATION_EVENT, 'vtfo'],
  [CX.APPLICATION_FUNCTION, 'vtfo'],
  [CX.APPLICATION_INTERACTION, 'vtfo'],
  [CX.APPLICATION_INTERFACE, 'vtfo'],
  [CX.APPLICATION_PROCESS, 'vtfo'],
  [CX.APPLICATION_SERVICE, 'vtfo'],
  [CX.APPLICATION_DATA_OBJECT, 'ao'],
  [CX.TECHNOLOGY_ARTIFACT, 'ao'],
  [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'vtfo'],
  [CX.TECHNOLOGY_DEVICE, 'vtfo'],
  [CX.TECHNOLOGY_NODE, 'vtfo'],
  [CX.TECHNOLOGY_PATH, 'vtfo'],
  [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'vtfo'],
  [CX.TECHNOLOGY_COLLABORATION, 'vtfo'],
  [CX.TECHNOLOGY_EVENT, 'vtfo'],
  [CX.TECHNOLOGY_FUNCTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERACTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERFACE, 'vtfo'],
  [CX.TECHNOLOGY_PROCESS, 'vtfo'],
  [CX.TECHNOLOGY_SERVICE, 'vtfo'],
  [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'vtfo'],
  [CX.PHYSICAL_EQUIPMENT, 'vtfo'],
  [CX.PHYSICAL_FACILITY, 'vtfo'],
  [CX.PHYSICAL_MATERIAL, 'ao'],
  [CX.IMP_MIG_DELIVERABLE, 'o'],
  [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
  [CX.IMP_MIG_WORK_PACKAGE, 'o'],
  [CX.IMP_MIG_GAP, 'o'],
  [CX.IMP_MIG_PLATEAU, 'o'],
  [CX.OTHER_LOCATION, 'vtfo'],
  [CX.OTHER_GROUPING, 'scgirvantfo'],
  [CX.RELATIONSHIP_ACCESS, 'o'],
  [CX.RELATIONSHIP_AGGREGATION, 'o'],
  [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
  [CX.RELATIONSHIP_ASSOCIATION, 'o'],
  [CX.RELATIONSHIP_COMPOSITION, 'o'],
  [CX.RELATIONSHIP_FLOW, 'o'],
  [CX.RELATIONSHIP_INFLUENCE, 'o'],
  [CX.RELATIONSHIP_JUNCTION_AND, ''],
  [CX.RELATIONSHIP_JUNCTION_OR, ''],
  [CX.RELATIONSHIP_REALIZATION, 'o'],
  [CX.RELATIONSHIP_SERVING, 'o'],
  [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
  [CX.RELATIONSHIP_TRIGGERING, 'o']
]);

export const RELATIONSHIPS_ALLOWED_FROM_BUSINESS_OBJECT = new Map([
  [CX.MOTIVATION_ASSESSMENT, 'no'],
  [CX.MOTIVATION_CONSTRAINT, 'rno'],
  [CX.MOTIVATION_DRIVER, 'no'],
  [CX.MOTIVATION_GOAL, 'rno'],
  [CX.MOTIVATION_MEANING, 'no'],
  [CX.MOTIVATION_OUTCOME, 'rno'],
  [CX.MOTIVATION_PRINCIPLE, 'rno'],
  [CX.MOTIVATION_REQUIREMENT, 'rno'],
  [CX.MOTIVATION_STAKEHOLDER, 'no'],
  [CX.MOTIVATION_VALUE, 'no'],
  [CX.STRATEGY_CAPABILITY, 'ro'],
  [CX.STRATEGY_VALUE_STREAM, 'ro'],
  [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
  [CX.STRATEGY_RESOURCE, 'ro'],
  [CX.BUSINESS_ACTOR, 'o'],
  [CX.BUSINESS_COLLABORATION, 'o'],
  [CX.BUSINESS_EVENT, 'o'],
  [CX.BUSINESS_FUNCTION, 'o'],
  [CX.BUSINESS_INTERACTION, 'o'],
  [CX.BUSINESS_INTERFACE, 'o'],
  [CX.BUSINESS_OBJECT, 'scgo'],
  [CX.BUSINESS_PROCESS, 'o'],
  [CX.BUSINESS_ROLE, 'o'],
  [CX.BUSINESS_SERVICE, 'o'],
  [CX.BUSINESS_CONTRACT, 'scgo'],
  [CX.BUSINESS_PRODUCT, 'o'],
  [CX.BUSINESS_REPRESENTATION, 'o'],
  [CX.APPLICATION_COLLABORATION, 'o'],
  [CX.APPLICATION_COMPONENT, 'o'],
  [CX.APPLICATION_EVENT, 'o'],
  [CX.APPLICATION_FUNCTION, 'o'],
  [CX.APPLICATION_INTERACTION, 'o'],
  [CX.APPLICATION_INTERFACE, 'o'],
  [CX.APPLICATION_PROCESS, 'o'],
  [CX.APPLICATION_SERVICE, 'o'],
  [CX.APPLICATION_DATA_OBJECT, 'o'],
  [CX.TECHNOLOGY_ARTIFACT, 'o'],
  [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'o'],
  [CX.TECHNOLOGY_DEVICE, 'o'],
  [CX.TECHNOLOGY_NODE, 'o'],
  [CX.TECHNOLOGY_PATH, 'o'],
  [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'o'],
  [CX.TECHNOLOGY_COLLABORATION, 'o'],
  [CX.TECHNOLOGY_EVENT, 'o'],
  [CX.TECHNOLOGY_FUNCTION, 'o'],
  [CX.TECHNOLOGY_INTERACTION, 'o'],
  [CX.TECHNOLOGY_INTERFACE, 'o'],
  [CX.TECHNOLOGY_PROCESS, 'o'],
  [CX.TECHNOLOGY_SERVICE, 'o'],
  [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'o'],
  [CX.PHYSICAL_EQUIPMENT, 'o'],
  [CX.PHYSICAL_FACILITY, 'o'],
  [CX.PHYSICAL_MATERIAL, 'o'],
  [CX.IMP_MIG_DELIVERABLE, 'o'],
  [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
  [CX.IMP_MIG_WORK_PACKAGE, 'o'],
  [CX.IMP_MIG_GAP, 'o'],
  [CX.IMP_MIG_PLATEAU, 'o'],
  [CX.OTHER_LOCATION, 'o'],
  [CX.OTHER_GROUPING, 'scgrno'],
  [CX.RELATIONSHIP_ACCESS, 'o'],
  [CX.RELATIONSHIP_AGGREGATION, 'o'],
  [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
  [CX.RELATIONSHIP_ASSOCIATION, 'o'],
  [CX.RELATIONSHIP_COMPOSITION, 'o'],
  [CX.RELATIONSHIP_FLOW, 'o'],
  [CX.RELATIONSHIP_INFLUENCE, 'o'],
  [CX.RELATIONSHIP_JUNCTION_AND, ''],
  [CX.RELATIONSHIP_JUNCTION_OR, ''],
  [CX.RELATIONSHIP_REALIZATION, 'o'],
  [CX.RELATIONSHIP_SERVING, 'o'],
  [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
  [CX.RELATIONSHIP_TRIGGERING, 'o']
]);

export const RELATIONSHIPS_ALLOWED_FROM_BUSINESS_PROCESS = new Map([
  [CX.MOTIVATION_ASSESSMENT, 'no'],
  [CX.MOTIVATION_CONSTRAINT, 'rno'],
  [CX.MOTIVATION_DRIVER, 'no'],
  [CX.MOTIVATION_GOAL, 'rno'],
  [CX.MOTIVATION_MEANING, 'no'],
  [CX.MOTIVATION_OUTCOME, 'rno'],
  [CX.MOTIVATION_PRINCIPLE, 'rno'],
  [CX.MOTIVATION_REQUIREMENT, 'rno'],
  [CX.MOTIVATION_STAKEHOLDER, 'no'],
  [CX.MOTIVATION_VALUE, 'no'],
  [CX.STRATEGY_CAPABILITY, 'ro'],
  [CX.STRATEGY_VALUE_STREAM, 'ro'],
  [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
  [CX.STRATEGY_RESOURCE, 'o'],
  [CX.BUSINESS_ACTOR, 'vtfo'],
  [CX.BUSINESS_COLLABORATION, 'vtfo'],
  [CX.BUSINESS_EVENT, 'vtfo'],
  [CX.BUSINESS_FUNCTION, 'cgvtfo'],
  [CX.BUSINESS_INTERACTION, 'cgvtfo'],
  [CX.BUSINESS_INTERFACE, 'vtfo'],
  [CX.BUSINESS_OBJECT, 'ao'],
  [CX.BUSINESS_PROCESS, 'scgvtfo'],
  [CX.BUSINESS_ROLE, 'vtfo'],
  [CX.BUSINESS_SERVICE, 'rvtfo'],
  [CX.BUSINESS_CONTRACT, 'ao'],
  [CX.BUSINESS_PRODUCT, 'vtfo'],
  [CX.BUSINESS_REPRESENTATION, 'ao'],
  [CX.APPLICATION_COLLABORATION, 'vtfo'],
  [CX.APPLICATION_COMPONENT, 'vtfo'],
  [CX.APPLICATION_EVENT, 'vtfo'],
  [CX.APPLICATION_FUNCTION, 'vtfo'],
  [CX.APPLICATION_INTERACTION, 'vtfo'],
  [CX.APPLICATION_INTERFACE, 'vtfo'],
  [CX.APPLICATION_PROCESS, 'vtfo'],
  [CX.APPLICATION_SERVICE, 'vtfo'],
  [CX.APPLICATION_DATA_OBJECT, 'ao'],
  [CX.TECHNOLOGY_ARTIFACT, 'ao'],
  [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'vtfo'],
  [CX.TECHNOLOGY_DEVICE, 'vtfo'],
  [CX.TECHNOLOGY_NODE, 'vtfo'],
  [CX.TECHNOLOGY_PATH, 'vtfo'],
  [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'vtfo'],
  [CX.TECHNOLOGY_COLLABORATION, 'vtfo'],
  [CX.TECHNOLOGY_EVENT, 'vtfo'],
  [CX.TECHNOLOGY_FUNCTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERACTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERFACE, 'vtfo'],
  [CX.TECHNOLOGY_PROCESS, 'vtfo'],
  [CX.TECHNOLOGY_SERVICE, 'vtfo'],
  [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'vtfo'],
  [CX.PHYSICAL_EQUIPMENT, 'vtfo'],
  [CX.PHYSICAL_FACILITY, 'vtfo'],
  [CX.PHYSICAL_MATERIAL, 'ao'],
  [CX.IMP_MIG_DELIVERABLE, 'o'],
  [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
  [CX.IMP_MIG_WORK_PACKAGE, 'o'],
  [CX.IMP_MIG_GAP, 'o'],
  [CX.IMP_MIG_PLATEAU, 'o'],
  [CX.OTHER_LOCATION, 'vtfo'],
  [CX.OTHER_GROUPING, 'scgrvantfo'],
  [CX.RELATIONSHIP_ACCESS, 'o'],
  [CX.RELATIONSHIP_AGGREGATION, 'o'],
  [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
  [CX.RELATIONSHIP_ASSOCIATION, 'o'],
  [CX.RELATIONSHIP_COMPOSITION, 'o'],
  [CX.RELATIONSHIP_FLOW, 'o'],
  [CX.RELATIONSHIP_INFLUENCE, 'o'],
  [CX.RELATIONSHIP_JUNCTION_AND, ''],
  [CX.RELATIONSHIP_JUNCTION_OR, ''],
  [CX.RELATIONSHIP_REALIZATION, 'o'],
  [CX.RELATIONSHIP_SERVING, 'o'],
  [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
  [CX.RELATIONSHIP_TRIGGERING, 'o']
]);

export const RELATIONSHIPS_ALLOWED_FROM_BUSINESS_ROLE = new Map([
  [CX.MOTIVATION_ASSESSMENT, 'no'],
  [CX.MOTIVATION_CONSTRAINT, 'rno'],
  [CX.MOTIVATION_DRIVER, 'no'],
  [CX.MOTIVATION_GOAL, 'rno'],
  [CX.MOTIVATION_MEANING, 'no'],
  [CX.MOTIVATION_OUTCOME, 'rno'],
  [CX.MOTIVATION_PRINCIPLE, 'rno'],
  [CX.MOTIVATION_REQUIREMENT, 'rno'],
  [CX.MOTIVATION_STAKEHOLDER, 'ino'],
  [CX.MOTIVATION_VALUE, 'no'],
  [CX.STRATEGY_CAPABILITY, 'ro'],
  [CX.STRATEGY_VALUE_STREAM, 'ro'],
  [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
  [CX.STRATEGY_RESOURCE, 'ro'],
  [CX.BUSINESS_ACTOR, 'vtfo'],
  [CX.BUSINESS_COLLABORATION, 'vtfo'],
  [CX.BUSINESS_EVENT, 'ivtfo'],
  [CX.BUSINESS_FUNCTION, 'ivtfo'],
  [CX.BUSINESS_INTERACTION, 'ivtfo'],
  [CX.BUSINESS_INTERFACE, 'cgvtfo'],
  [CX.BUSINESS_OBJECT, 'ao'],
  [CX.BUSINESS_PROCESS, 'ivtfo'],
  [CX.BUSINESS_ROLE, 'scgvtfo'],
  [CX.BUSINESS_SERVICE, 'irvtfo'],
  [CX.BUSINESS_CONTRACT, 'ao'],
  [CX.BUSINESS_PRODUCT, 'vtfo'],
  [CX.BUSINESS_REPRESENTATION, 'ao'],
  [CX.APPLICATION_COLLABORATION, 'vtfo'],
  [CX.APPLICATION_COMPONENT, 'vtfo'],
  [CX.APPLICATION_EVENT, 'vtfo'],
  [CX.APPLICATION_FUNCTION, 'vtfo'],
  [CX.APPLICATION_INTERACTION, 'vtfo'],
  [CX.APPLICATION_INTERFACE, 'vtfo'],
  [CX.APPLICATION_PROCESS, 'vtfo'],
  [CX.APPLICATION_SERVICE, 'vtfo'],
  [CX.APPLICATION_DATA_OBJECT, 'ao'],
  [CX.TECHNOLOGY_ARTIFACT, 'ao'],
  [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'vtfo'],
  [CX.TECHNOLOGY_DEVICE, 'vtfo'],
  [CX.TECHNOLOGY_NODE, 'vtfo'],
  [CX.TECHNOLOGY_PATH, 'vtfo'],
  [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'vtfo'],
  [CX.TECHNOLOGY_COLLABORATION, 'vtfo'],
  [CX.TECHNOLOGY_EVENT, 'vtfo'],
  [CX.TECHNOLOGY_FUNCTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERACTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERFACE, 'vtfo'],
  [CX.TECHNOLOGY_PROCESS, 'vtfo'],
  [CX.TECHNOLOGY_SERVICE, 'vtfo'],
  [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'vtfo'],
  [CX.PHYSICAL_EQUIPMENT, 'vtfo'],
  [CX.PHYSICAL_FACILITY, 'vtfo'],
  [CX.PHYSICAL_MATERIAL, 'ao'],
  [CX.IMP_MIG_DELIVERABLE, 'o'],
  [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'io'],
  [CX.IMP_MIG_WORK_PACKAGE, 'io'],
  [CX.IMP_MIG_GAP, 'o'],
  [CX.IMP_MIG_PLATEAU, 'o'],
  [CX.OTHER_LOCATION, 'vtfo'],
  [CX.OTHER_GROUPING, 'scgirvantfo'],
  [CX.RELATIONSHIP_ACCESS, 'o'],
  [CX.RELATIONSHIP_AGGREGATION, 'o'],
  [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
  [CX.RELATIONSHIP_ASSOCIATION, 'o'],
  [CX.RELATIONSHIP_COMPOSITION, 'o'],
  [CX.RELATIONSHIP_FLOW, 'o'],
  [CX.RELATIONSHIP_INFLUENCE, 'o'],
  [CX.RELATIONSHIP_JUNCTION_AND, ''],
  [CX.RELATIONSHIP_JUNCTION_OR, ''],
  [CX.RELATIONSHIP_REALIZATION, 'o'],
  [CX.RELATIONSHIP_SERVING, 'o'],
  [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
  [CX.RELATIONSHIP_TRIGGERING, 'o']
]);

export const RELATIONSHIPS_ALLOWED_FROM_BUSINESS_SERVICE = new Map([
  [CX.MOTIVATION_ASSESSMENT, 'no'],
  [CX.MOTIVATION_CONSTRAINT, 'rno'],
  [CX.MOTIVATION_DRIVER, 'no'],
  [CX.MOTIVATION_GOAL, 'rno'],
  [CX.MOTIVATION_MEANING, 'no'],
  [CX.MOTIVATION_OUTCOME, 'rno'],
  [CX.MOTIVATION_PRINCIPLE, 'rno'],
  [CX.MOTIVATION_REQUIREMENT, 'rno'],
  [CX.MOTIVATION_STAKEHOLDER, 'no'],
  [CX.MOTIVATION_VALUE, 'no'],
  [CX.STRATEGY_CAPABILITY, 'ro'],
  [CX.STRATEGY_VALUE_STREAM, 'ro'],
  [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
  [CX.STRATEGY_RESOURCE, 'o'],
  [CX.BUSINESS_ACTOR, 'vtfo'],
  [CX.BUSINESS_COLLABORATION, 'vtfo'],
  [CX.BUSINESS_EVENT, 'vtfo'],
  [CX.BUSINESS_FUNCTION, 'vtfo'],
  [CX.BUSINESS_INTERACTION, 'vtfo'],
  [CX.BUSINESS_INTERFACE, 'vtfo'],
  [CX.BUSINESS_OBJECT, 'ao'],
  [CX.BUSINESS_PROCESS, 'vtfo'],
  [CX.BUSINESS_ROLE, 'vtfo'],
  [CX.BUSINESS_SERVICE, 'scgvtfo'],
  [CX.BUSINESS_CONTRACT, 'ao'],
  [CX.BUSINESS_PRODUCT, 'vtfo'],
  [CX.BUSINESS_REPRESENTATION, 'ao'],
  [CX.APPLICATION_COLLABORATION, 'vtfo'],
  [CX.APPLICATION_COMPONENT, 'vtfo'],
  [CX.APPLICATION_EVENT, 'vtfo'],
  [CX.APPLICATION_FUNCTION, 'vtfo'],
  [CX.APPLICATION_INTERACTION, 'vtfo'],
  [CX.APPLICATION_INTERFACE, 'vtfo'],
  [CX.APPLICATION_PROCESS, 'vtfo'],
  [CX.APPLICATION_SERVICE, 'vtfo'],
  [CX.APPLICATION_DATA_OBJECT, 'ao'],
  [CX.TECHNOLOGY_ARTIFACT, 'ao'],
  [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'vtfo'],
  [CX.TECHNOLOGY_DEVICE, 'vtfo'],
  [CX.TECHNOLOGY_NODE, 'vtfo'],
  [CX.TECHNOLOGY_PATH, 'vtfo'],
  [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'vtfo'],
  [CX.TECHNOLOGY_COLLABORATION, 'vtfo'],
  [CX.TECHNOLOGY_EVENT, 'vtfo'],
  [CX.TECHNOLOGY_FUNCTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERACTION, 'vtfo'],
  [CX.TECHNOLOGY_INTERFACE, 'vtfo'],
  [CX.TECHNOLOGY_PROCESS, 'vtfo'],
  [CX.TECHNOLOGY_SERVICE, 'vtfo'],
  [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'vtfo'],
  [CX.PHYSICAL_EQUIPMENT, 'vtfo'],
  [CX.PHYSICAL_FACILITY, 'vtfo'],
  [CX.PHYSICAL_MATERIAL, 'ao'],
  [CX.IMP_MIG_DELIVERABLE, 'o'],
  [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
  [CX.IMP_MIG_WORK_PACKAGE, 'o'],
  [CX.IMP_MIG_GAP, 'o'],
  [CX.IMP_MIG_PLATEAU, 'o'],
  [CX.OTHER_LOCATION, 'vtfo'],
  [CX.OTHER_GROUPING, 'scgrvantfo'],
  [CX.RELATIONSHIP_ACCESS, 'o'],
  [CX.RELATIONSHIP_AGGREGATION, 'o'],
  [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
  [CX.RELATIONSHIP_ASSOCIATION, 'o'],
  [CX.RELATIONSHIP_COMPOSITION, 'o'],
  [CX.RELATIONSHIP_FLOW, 'o'],
  [CX.RELATIONSHIP_INFLUENCE, 'o'],
  [CX.RELATIONSHIP_JUNCTION_AND, ''],
  [CX.RELATIONSHIP_JUNCTION_OR, ''],
  [CX.RELATIONSHIP_REALIZATION, 'o'],
  [CX.RELATIONSHIP_SERVING, 'o'],
  [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
  [CX.RELATIONSHIP_TRIGGERING, 'o']
]);

export const RELATIONSHIPS_ALLOWED_FROM_BUSINESS_CONTRACT = new Map([
  [CX.MOTIVATION_ASSESSMENT, 'no'],
  [CX.MOTIVATION_CONSTRAINT, 'rno'],
  [CX.MOTIVATION_DRIVER, 'no'],
  [CX.MOTIVATION_GOAL, 'rno'],
  [CX.MOTIVATION_MEANING, 'no'],
  [CX.MOTIVATION_OUTCOME, 'rno'],
  [CX.MOTIVATION_PRINCIPLE, 'rno'],
  [CX.MOTIVATION_REQUIREMENT, 'rno'],
  [CX.MOTIVATION_STAKEHOLDER, 'no'],
  [CX.MOTIVATION_VALUE, 'no'],
  [CX.STRATEGY_CAPABILITY, 'ro'],
  [CX.STRATEGY_VALUE_STREAM, 'ro'],
  [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
  [CX.STRATEGY_RESOURCE, 'ro'],
  [CX.BUSINESS_ACTOR, 'o'],
  [CX.BUSINESS_COLLABORATION, 'o'],
  [CX.BUSINESS_EVENT, 'o'],
  [CX.BUSINESS_FUNCTION, 'o'],
  [CX.BUSINESS_INTERACTION, 'o'],
  [CX.BUSINESS_INTERFACE, 'o'],
  [CX.BUSINESS_OBJECT, 'scgo'],
  [CX.BUSINESS_PROCESS, 'o'],
  [CX.BUSINESS_ROLE, 'o'],
  [CX.BUSINESS_SERVICE, 'o'],
  [CX.BUSINESS_CONTRACT, 'scgo'],
  [CX.BUSINESS_PRODUCT, 'o'],
  [CX.BUSINESS_REPRESENTATION, 'o'],
  [CX.APPLICATION_COLLABORATION, 'o'],
  [CX.APPLICATION_COMPONENT, 'o'],
  [CX.APPLICATION_EVENT, 'o'],
  [CX.APPLICATION_FUNCTION, 'o'],
  [CX.APPLICATION_INTERACTION, 'o'],
  [CX.APPLICATION_INTERFACE, 'o'],
  [CX.APPLICATION_PROCESS, 'o'],
  [CX.APPLICATION_SERVICE, 'o'],
  [CX.APPLICATION_DATA_OBJECT, 'o'],
  [CX.TECHNOLOGY_ARTIFACT, 'o'],
  [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'o'],
  [CX.TECHNOLOGY_DEVICE, 'o'],
  [CX.TECHNOLOGY_NODE, 'o'],
  [CX.TECHNOLOGY_PATH, 'o'],
  [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'o'],
  [CX.TECHNOLOGY_COLLABORATION, 'o'],
  [CX.TECHNOLOGY_EVENT, 'o'],
  [CX.TECHNOLOGY_FUNCTION, 'o'],
  [CX.TECHNOLOGY_INTERACTION, 'o'],
  [CX.TECHNOLOGY_INTERFACE, 'o'],
  [CX.TECHNOLOGY_PROCESS, 'o'],
  [CX.TECHNOLOGY_SERVICE, 'o'],
  [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'o'],
  [CX.PHYSICAL_EQUIPMENT, 'o'],
  [CX.PHYSICAL_FACILITY, 'o'],
  [CX.PHYSICAL_MATERIAL, 'o'],
  [CX.IMP_MIG_DELIVERABLE, 'o'],
  [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
  [CX.IMP_MIG_WORK_PACKAGE, 'o'],
  [CX.IMP_MIG_GAP, 'o'],
  [CX.IMP_MIG_PLATEAU, 'o'],
  [CX.OTHER_LOCATION, 'o'],
  [CX.OTHER_GROUPING, 'scgrno'],
  [CX.RELATIONSHIP_ACCESS, 'o'],
  [CX.RELATIONSHIP_AGGREGATION, 'o'],
  [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
  [CX.RELATIONSHIP_ASSOCIATION, 'o'],
  [CX.RELATIONSHIP_COMPOSITION, 'o'],
  [CX.RELATIONSHIP_FLOW, 'o'],
  [CX.RELATIONSHIP_INFLUENCE, 'o'],
  [CX.RELATIONSHIP_JUNCTION_AND, ''],
  [CX.RELATIONSHIP_JUNCTION_OR, ''],
  [CX.RELATIONSHIP_REALIZATION, 'o'],
  [CX.RELATIONSHIP_SERVING, 'o'],
  [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
  [CX.RELATIONSHIP_TRIGGERING, 'o']
]);

export const RELATIONSHIPS_ALLOWED_FROM_BUSINESS_PRODUCT = new Map([
  [CX.MOTIVATION_ASSESSMENT, 'no'],
  [CX.MOTIVATION_CONSTRAINT, 'rno'],
  [CX.MOTIVATION_DRIVER, 'no'],
  [CX.MOTIVATION_GOAL, 'rno'],
  [CX.MOTIVATION_MEANING, 'no'],
  [CX.MOTIVATION_OUTCOME, 'rno'],
  [CX.MOTIVATION_PRINCIPLE, 'rno'],
  [CX.MOTIVATION_REQUIREMENT, 'rno'],
  [CX.MOTIVATION_STAKEHOLDER, 'no'],
  [CX.MOTIVATION_VALUE, 'no'],
  [CX.STRATEGY_CAPABILITY, 'ro'],
  [CX.STRATEGY_VALUE_STREAM, 'ro'],
  [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
  [CX.STRATEGY_RESOURCE, 'ro'],
  [CX.BUSINESS_ACTOR, 'vtfo'],
  [CX.BUSINESS_COLLABORATION, 'vtfo'],
  [CX.BUSINESS_EVENT, 'rvtfo'],
  [CX.BUSINESS_FUNCTION, 'rvtfo'],
  [CX.BUSINESS_INTERACTION, 'rvtfo'],
  [CX.BUSINESS_INTERFACE, 'rvtfo'],
  [CX.BUSINESS_OBJECT, 'cgao'],
  [CX.BUSINESS_PROCESS, 'rvtfo'],
  [CX.BUSINESS_ROLE, 'vtfo'],
  [CX.BUSINESS_SERVICE, 'cgrvtfo'],
  [CX.BUSINESS_CONTRACT, 'cgao'],
  [CX.BUSINESS_PRODUCT, 'scgvtfo'],
  [CX.BUSINESS_REPRESENTATION, 'cgao'],
  [CX.APPLICATION_COLLABORATION, 'rvtfo'],
  [CX.APPLICATION_COMPONENT, 'rvtfo'],
  [CX.APPLICATION_EVENT, 'rvtfo'],
  [CX.APPLICATION_FUNCTION, 'rvtfo'],
  [CX.APPLICATION_INTERACTION, 'rvtfo'],
  [CX.APPLICATION_INTERFACE, 'rvtfo'],
  [CX.APPLICATION_PROCESS, 'rvtfo'],
  [CX.APPLICATION_SERVICE, 'cgrvtfo'],
  [CX.APPLICATION_DATA_OBJECT, 'cgao'],
  [CX.TECHNOLOGY_ARTIFACT, 'cgao'],
  [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'vtfo'],
  [CX.TECHNOLOGY_DEVICE, 'rvtfo'],
  [CX.TECHNOLOGY_NODE, 'vtfo'],
  [CX.TECHNOLOGY_PATH, 'vtfo'],
  [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'rvtfo'],
  [CX.TECHNOLOGY_COLLABORATION, 'vtfo'],
  [CX.TECHNOLOGY_EVENT, 'rvtfo'],
  [CX.TECHNOLOGY_FUNCTION, 'rvtfo'],
  [CX.TECHNOLOGY_INTERACTION, 'rvtfo'],
  [CX.TECHNOLOGY_INTERFACE, 'rvtfo'],
  [CX.TECHNOLOGY_PROCESS, 'rvtfo'],
  [CX.TECHNOLOGY_SERVICE, 'cgrvtfo'],
  [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'vtfo'],
  [CX.PHYSICAL_EQUIPMENT, 'rvtfo'],
  [CX.PHYSICAL_FACILITY, 'vtfo'],
  [CX.PHYSICAL_MATERIAL, 'cgao'],
  [CX.IMP_MIG_DELIVERABLE, 'o'],
  [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
  [CX.IMP_MIG_WORK_PACKAGE, 'o'],
  [CX.IMP_MIG_GAP, 'o'],
  [CX.IMP_MIG_PLATEAU, 'o'],
  [CX.OTHER_LOCATION, 'vtfo'],
  [CX.OTHER_GROUPING, 'scgrvantfo'],
  [CX.RELATIONSHIP_ACCESS, 'o'],
  [CX.RELATIONSHIP_AGGREGATION, 'o'],
  [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
  [CX.RELATIONSHIP_ASSOCIATION, 'o'],
  [CX.RELATIONSHIP_COMPOSITION, 'o'],
  [CX.RELATIONSHIP_FLOW, 'o'],
  [CX.RELATIONSHIP_INFLUENCE, 'o'],
  [CX.RELATIONSHIP_JUNCTION_AND, ''],
  [CX.RELATIONSHIP_JUNCTION_OR, ''],
  [CX.RELATIONSHIP_REALIZATION, 'o'],
  [CX.RELATIONSHIP_SERVING, 'o'],
  [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
  [CX.RELATIONSHIP_TRIGGERING, 'o']
]);

export const RELATIONSHIPS_ALLOWED_FROM_BUSINESS_REPRESENTATION = new Map([
  [CX.MOTIVATION_ASSESSMENT, 'no'],
  [CX.MOTIVATION_CONSTRAINT, 'rno'],
  [CX.MOTIVATION_DRIVER, 'no'],
  [CX.MOTIVATION_GOAL, 'rno'],
  [CX.MOTIVATION_MEANING, 'no'],
  [CX.MOTIVATION_OUTCOME, 'rno'],
  [CX.MOTIVATION_PRINCIPLE, 'rno'],
  [CX.MOTIVATION_REQUIREMENT, 'rno'],
  [CX.MOTIVATION_STAKEHOLDER, 'no'],
  [CX.MOTIVATION_VALUE, 'no'],
  [CX.STRATEGY_CAPABILITY, 'ro'],
  [CX.STRATEGY_VALUE_STREAM, 'ro'],
  [CX.STRATEGY_COURSE_OF_ACTION, 'ro'],
  [CX.STRATEGY_RESOURCE, 'ro'],
  [CX.BUSINESS_ACTOR, 'o'],
  [CX.BUSINESS_COLLABORATION, 'o'],
  [CX.BUSINESS_EVENT, 'o'],
  [CX.BUSINESS_FUNCTION, 'o'],
  [CX.BUSINESS_INTERACTION, 'o'],
  [CX.BUSINESS_INTERFACE, 'o'],
  [CX.BUSINESS_OBJECT, 'ro'],
  [CX.BUSINESS_PROCESS, 'o'],
  [CX.BUSINESS_ROLE, 'o'],
  [CX.BUSINESS_SERVICE, 'o'],
  [CX.BUSINESS_CONTRACT, 'ro'],
  [CX.BUSINESS_PRODUCT, 'o'],
  [CX.BUSINESS_REPRESENTATION, 'scgo'],
  [CX.APPLICATION_COLLABORATION, 'o'],
  [CX.APPLICATION_COMPONENT, 'o'],
  [CX.APPLICATION_EVENT, 'o'],
  [CX.APPLICATION_FUNCTION, 'o'],
  [CX.APPLICATION_INTERACTION, 'o'],
  [CX.APPLICATION_INTERFACE, 'o'],
  [CX.APPLICATION_PROCESS, 'o'],
  [CX.APPLICATION_SERVICE, 'o'],
  [CX.APPLICATION_DATA_OBJECT, 'o'],
  [CX.TECHNOLOGY_ARTIFACT, 'o'],
  [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'o'],
  [CX.TECHNOLOGY_DEVICE, 'o'],
  [CX.TECHNOLOGY_NODE, 'o'],
  [CX.TECHNOLOGY_PATH, 'o'],
  [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'o'],
  [CX.TECHNOLOGY_COLLABORATION, 'o'],
  [CX.TECHNOLOGY_EVENT, 'o'],
  [CX.TECHNOLOGY_FUNCTION, 'o'],
  [CX.TECHNOLOGY_INTERACTION, 'o'],
  [CX.TECHNOLOGY_INTERFACE, 'o'],
  [CX.TECHNOLOGY_PROCESS, 'o'],
  [CX.TECHNOLOGY_SERVICE, 'o'],
  [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'o'],
  [CX.PHYSICAL_EQUIPMENT, 'o'],
  [CX.PHYSICAL_FACILITY, 'o'],
  [CX.PHYSICAL_MATERIAL, 'o'],
  [CX.IMP_MIG_DELIVERABLE, 'o'],
  [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
  [CX.IMP_MIG_WORK_PACKAGE, 'o'],
  [CX.IMP_MIG_GAP, 'o'],
  [CX.IMP_MIG_PLATEAU, 'o'],
  [CX.OTHER_LOCATION, 'o'],
  [CX.OTHER_GROUPING, 'scgrno'],
  [CX.RELATIONSHIP_ACCESS, 'o'],
  [CX.RELATIONSHIP_AGGREGATION, 'o'],
  [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
  [CX.RELATIONSHIP_ASSOCIATION, 'o'],
  [CX.RELATIONSHIP_COMPOSITION, 'o'],
  [CX.RELATIONSHIP_FLOW, 'o'],
  [CX.RELATIONSHIP_INFLUENCE, 'o'],
  [CX.RELATIONSHIP_JUNCTION_AND, ''],
  [CX.RELATIONSHIP_JUNCTION_OR, ''],
  [CX.RELATIONSHIP_REALIZATION, 'o'],
  [CX.RELATIONSHIP_SERVING, 'o'],
  [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
  [CX.RELATIONSHIP_TRIGGERING, 'o']
]);
