import {
  assign,
  forEach
} from 'min-dash';

import inherits from 'inherits-browser';

import {
  getLayerType,
  getAspectType,
  getTypeName,
  NOTE,
  NODE_ELEMENT,
  CONNECTION_RELATIONSHIP,
  CONNECTION_LINE
} from '../../util/ModelUtil';

import BaseElementFactory from 'diagram-js/lib/core/ElementFactory';

import {
  DEFAULT_LABEL_SIZE
} from '../../util/LabelUtil';

import { logger } from "../../util/Logger";

import { toHex, DEFAULT_NOTE_COLOR, BLACK_SHADOW, COLOR_LAYER_MAP, DEFAULT_CONNECTION_COLOR } from '../../util/ColorUtil';
import { BLACK_RGBA, LINEWIDTH_NORMAL } from '../../draw/ArchimateRendererUtil';
import { FONTNAME_DEFAULT, FONTSIZE_DEFAULT } from '../../draw/TextRenderer';

/**
 * An Archimate elements factory for diagram-js shapes
 * 
 * This function get informations from PaletteProvider or ArchimateImporter 
 * in order to create a shape, a connection or a root element to display on canvas.
 * The final rendering is done by ArchimateRenderer in order draw the element.
 * 
 * elementType (String) can be : root, shape or label
 * attrs contains :
 * => called from ArchimateImporter
 *   - businessObject (Archimate element description from XML Archimate model => Moddle)
 *   - bounds of the shape (height, width, x, y)
 *   - hidden
 *   - isFrame (boolean)
 *   - type of the element to draw and passed to ArchimateRenderer (ex. BusinessActor)
 * 
 *   => called from PaletteProvider
 *    - only type property 
 *    In this case, 'create' function calls ArchimateFactory create function in order 
 *    to get back two Moddle objects for elementRef and viewElement 
 * 
 * At the end, basecreate (inherits from BaseElementFactory) is calling with elementType and attrs.
 * This function callback drawShape function in ArchimateRenderer.
 * 
 */
export default function ElementFactory(archimateFactory, moddle, translate) {
  BaseElementFactory.call(this);

  this._archimateFactory = archimateFactory;
  this._moddle = moddle;
  this._translate = translate;
}

inherits(ElementFactory, BaseElementFactory);

ElementFactory.$inject = [
  'archimateFactory',
  'moddle',
  'translate'
];

ElementFactory.prototype.baseCreate = BaseElementFactory.prototype.create;


/*

*/
ElementFactory.prototype.create = function(elementType, attrs) {

  logger.log('create(elementType, attrs)');
  logger.log({elementType, ...attrs});

  var translate = this._translate;

  // no special magic for labels,
  // we assume their businessObjects have already been created
  // and wired via attrs
  if (elementType === 'label') {
    logger.log('create label element');
    return this.baseCreate(elementType, assign({ type: 'label' }, DEFAULT_LABEL_SIZE, attrs));
  }

  // create root gfx element 
  if (elementType === 'root') {
    logger.log('create root element');

    var view = attrs.businessObject;

    attrs = assign({
      id: view.id,
      type: 'root'
    }, attrs);

    return this.baseCreate(elementType, attrs);

  }

  if (elementType === 'connection') {
    logger.log('create connection element');

    attrs = attrs || {};

    if (!attrs.type) {
      throw new Error(translate('no relationship type specified'));
      // attrs.type = 'Association';
    }

    // get the Archimate Connection element attached to the archimate view
    var archimateConnection = attrs.businessObject;
    
    if (archimateConnection) {
      // get all attrs needed for rendering from archimateConnection generated by importer
      logger.log(archimateConnection);

      attrs = assign({
        name: archimateConnection.relationshipRef && archimateConnection.relationshipRef.name,
        text: archimateConnection.label,
        id: archimateConnection.id
      }, attrs);

      // get style
      if (archimateConnection.style) {
        var style = archimateConnection.style;

        attrs = assign({
          lineColor: toHex(style.lineColor),
          lineWidth: style.lineWidth
        }, attrs);

        // get font
        if (style.font) {
          var font = style.font;

          attrs = assign({
            fontName: font.name,
            fontSize: font.size,
            fontColor: toHex(font.color),
            fontStyle: font.style
          }, attrs);
        }
      }

      // get special attrs from special relationship
      if (attrs.type === 'Access') {
        attrs = assign({
          accessType: archimateConnection.relationshipRef && archimateConnection.relationshipRef.accessType
        }, attrs);
      }

      if (attrs.type === 'Association') {
        attrs = assign({
          isDirected: archimateConnection.relationshipRef && archimateConnection.relationshipRef.isDirected
        }, attrs);
      }

      if (attrs.type === 'Influence') {
        attrs = assign({
          modifier: archimateConnection.relationshipRef && archimateConnection.relationshipRef.modifier
        }, attrs);
      }
    } else {
      // this is a new connection to draw on canvas, create core businessObject
      // other attrs (eg. relationshipRef) will be set in CreateElementBehavior
      // based on attrs directly set on connection element

      var lineColor, lineWidth;

      if (attrs.type === 'Line') {
        archimateConnection = this._archimateFactory.create('archimate:Connection', { type: CONNECTION_LINE });
        lineColor = BLACK_SHADOW;
        lineWidth = 1;

      } else {
        archimateConnection = this._archimateFactory.create('archimate:Connection', { type: CONNECTION_RELATIONSHIP });
        lineColor = DEFAULT_CONNECTION_COLOR;
        lineWidth = 1;

        attrs = assign({
          name: '',
        }, attrs);

      }
      attrs = assign({
        businessObject: archimateConnection,
        id: archimateConnection.id,
        lineColor: lineColor,
        lineWidth: lineWidth,
      }, attrs);
    }

    logger.log('call baseCreate for getting a connection element with attrs:');
    logger.log(attrs);

    var newConnection = this.baseCreate(elementType, attrs);

    logger.log(newConnection);

    // return this.baseCreate(elementType, attrs);
    return newConnection;

  }

  if (elementType === 'shape') {
    logger.log('create shape element');

    attrs = attrs || {};

    if (!attrs.type) {
      throw new Error(translate('no base element type specified'));
    }

    // get the Archimate Node element attached to the archimate view
    var archimateNode = attrs.businessObject;
    
    if (archimateNode) {
      // get all attrs needed for rendering from archimateNode generated by importer
      logger.log(archimateNode);

      if(attrs.type !== NOTE) {
        attrs = assign({
          name: archimateNode.elementRef && archimateNode.elementRef.name,
          layer: getLayerType(attrs.type),
          aspect: getAspectType(attrs.type),
        }, attrs);
      }

      attrs = assign({
        text: archimateNode.label,
        id: archimateNode.id
      }, attrs);
  
      // get style
      if (archimateNode.style) {
        var style = archimateNode.style;

        attrs = assign({
          lineColor: toHex(style.lineColor),
          fillColor: toHex(style.fillColor),
          textAlign: style.textAlign
        }, attrs);

        // get font
        if (style.font) {
          var font = style.font;

          attrs = assign({
            fontName: font.name,
            fontSize: font.size,
            fontColor: toHex(font.color),
            fontStyle: font.style
          }, attrs);
        }
      }
    } else {
      // this is a new shape to draw on canvas, create core businessObject
      // other attrs (eg. elementRef) will be set in CreateElementBehavior
      // based on attrs directly set on shape element

      var fillColor, lineColor, textAlign;

      if (attrs.type === NOTE) {
        archimateNode = this._archimateFactory.create('archimate:Node', { type: NOTE, label: '' });

        fillColor = DEFAULT_NOTE_COLOR;
        lineColor = BLACK_SHADOW;
        textAlign = 'left-top';

        attrs = assign({
          text: '',
        }, attrs);

      } else {
        
        archimateNode = this._archimateFactory.create('archimate:Node', { type: NODE_ELEMENT });

        var layer = getLayerType(attrs.type);

        fillColor = COLOR_LAYER_MAP.get(layer);
        lineColor = BLACK_SHADOW;
        textAlign = 'center-middle';
        
        attrs = assign({
          name: getTypeName(attrs.type),
          layer: layer,
          aspect: getAspectType(attrs.type),
        }, attrs);

      }

      var size = this._getDefaultSize(attrs.type);

      attrs = assign({
        businessObject: archimateNode,
        id: archimateNode.id,
        fillColor: fillColor,
        lineColor: lineColor,
        textAlign: textAlign,
      }, size, attrs);  // => size's values are overided if attrs contains width and height properties (eg. when attrs object comes from ArchimateFactory)
    }

    logger.log('call baseCreate with :');
    logger.log({elementType, attrs});

    var newShape = this.baseCreate(elementType, attrs);

    logger.log(newShape);

    return newShape;


    // TODO(vbo)
    // var elementRef = attrs.businessObject; // CHANGE TO attrs.businessObject.elementRef
    // var viewElement = attrs.diObject; // CHANGE TO attrs.businessObject 
    var viewElement = attrs.businessObject; // CHANGE TO attrs.businessObject

    // No viewElement created for this shape when drawing from the palette,
    // first create a moddle element for elementType and then create, else use the model element passing in businessObject's attrs
    if (!viewElement) {
      
      if (attrs.type === 'archimate:Note') {
        // 'Note' element, no need to create an BaseElement (elementRef), only a viewElement
        logger.log('new Note => create in ElementFactory');
        viewElement = this._archimateFactory.create('archimate:Note', {
          label: ''
          });
        logger.log('return of create in ElementFactory');
        logger.log(viewElement);        

      } else {
        logger.log('call archimateFactory.createBaseElement to get moddle element');
        var elementRef = this._archimateFactory.createBaseElement(attrs.type);
        // set default name for this element
        elementRef.name = getTypeName(attrs.type);

        logger.log('new BaseElement => createViewElement in ArchimateFactory');
        // normaly, no need to add di reference to elementRef, only if it's a new model element from palette
        viewElement = this._archimateFactory.createViewElement(elementRef, {
          // id: elementRef.id + '_di'
          // id: this._moddle.ids.nextPrefixed('id-')
          });
        logger.log('return of createViewElement in ArchimateFactory');
        logger.log(viewElement);
      }
    }

/*
    if (is(elementRef, 'archimate:Group')) {
      attrs = assign({
        isFrame: true
      }, attrs);
    }
*/

  /* TODO(vbo) seems to never get into this !
  if (attrs.di) {
    logger.log('passing in if(attrs.di) in ElementFactory');
    assign(elementRef.di, attrs.di);
    delete attrs.di;
  }

    applyAttributes(elementRef, attrs, [
      'processRef',
      'isInterrupting',
      'associationDirection',
      'isForCompensation'
    ]);
  */

    var size = this._getDefaultSize(attrs.type),
      layer = getLayerType(attrs.type),
      aspect = getAspectType(attrs.type);


    attrs = assign({
      businessObject: viewElement,
      id: viewElement.id,
      layer: layer,
      aspect: aspect
    }, size, attrs);  // => size's values are overided if attrs contains width and height properties (eg. when attrs object comes from ArchimateFactory)

    logger.log('call baseCreate with :');
    logger.log({elementType, attrs});

    return this.baseCreate(elementType, attrs);
  }
};


ElementFactory.prototype._getDefaultSize = function(elementType) {
  if (elementType === 'archimate:Group') {
    return { width: 300, height: 300 };
  }

  if (elementType === NOTE) {
    // return { width: 150, height: 80 };
  }

  return { width: 120, height: 55 };
};

// helpers //////////////////////

/**
 * Apply attributes from a map to the given element,
 * remove attribute from the map on application.
 *
 * @param {Base} element
 * @param {Object} attrs (in/out map of attributes)
 * @param {Array<String>} attributeNames name of attributes to apply
 */
function applyAttributes(element, attrs, attributeNames) {

  forEach(attributeNames, function(property) {
    if (attrs[property] !== undefined) {
      applyAttribute(element, attrs, property);
    }
  });
}

/**
 * Apply named property to element and drain it from the attrs
 * collection.
 *
 * @param {Base} element
 * @param {Object} attrs (in/out map of attributes)
 * @param {String} attributeName to apply
 */
function applyAttribute(element, attrs, attributeName) {
  element[attributeName] = attrs[attributeName];

  delete attrs[attributeName];
}