import * as React from 'react';
import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

const SvgIcon = styled(MuiSvgIcon, {
  name: 'ArchimateIcon',
//  shouldForwardProp: (prop) => prop !== 'fillColor',
})(({ fill }) => ({
  fill: fill || 'none', //'rgb(255, 255, 255)',
  stroke: 'currentColor',
  //strokeLinecap: 'round',
  strokeLinejoin:  'round',
  strokeWidth:  '0.9px',
 }));

 SvgIcon.defaultProps = {
  viewBox: '0 0 18 18',
  focusable: 'false',
  'aria-hidden': 'true',
  };

 const ArchimateIcon = (props) => {
  const {iconPath, ...rest} = props;
  return (
      <SvgIcon {...rest}>
          <path d={iconPath} />
      </SvgIcon>
  );
 };

 export default ArchimateIcon;