import gql from "graphql-tag";

export const ALL_MODELS = gql`
    query AllModels {
        models {
            _id
            createdate
            description
            lastupdate
            modelID
            name
            ownerID
            xmlmodel
            readonly
        }
    }
`;

export const GET_XMLMODEL = gql`
    query FindModel($query: ModelQueryInput!) {
        model(query: $query) {
            _id
            xmlmodel
            readonly
        }
    }
`;

export const MODELS_BY_USER = gql`
    query ModelsByUser($query: ModelQueryInput!) {
        models(query: $query) {
            _id
            createdate
            description
            lastupdate
            modelID
            name
            ownerID
            readonly
        }
    }
`;

export const UPDATE_MODEL = gql`
    mutation UpdateModel($query: ModelQueryInput!, $set: ModelUpdateInput!) {
        updateOneModel(query: $query, set: $set) {
            _id
            name
        }
    }
`;

export const CREATE_MODEL = gql`
    mutation CreateModel($data: ModelInsertInput!) {
        insertOneModel(data: $data) {
            _id
            name
        }
    }
`;

export const DELETE_MODEL = gql`
    mutation DeleteModel($query: ModelQueryInput!) {
        deleteOneModel(query: $query) {
            _id
            name
        }
    }
`;


