import * as CX from './Concept';

export const RELATIONSHIPS_ALLOWED_FROM_MOTIVATION_ASSESSMENT = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'scgno'],
    [CX.MOTIVATION_CONSTRAINT, 'no'],
    [CX.MOTIVATION_DRIVER, 'no'],
    [CX.MOTIVATION_GOAL, 'no'],
    [CX.MOTIVATION_MEANING, 'no'],
    [CX.MOTIVATION_OUTCOME, 'no'],
    [CX.MOTIVATION_PRINCIPLE, 'no'],
    [CX.MOTIVATION_REQUIREMENT, 'no'],
    [CX.MOTIVATION_STAKEHOLDER, 'no'],
    [CX.MOTIVATION_VALUE, 'no'],
    [CX.STRATEGY_CAPABILITY, 'o'],
    [CX.STRATEGY_VALUE_STREAM, 'o'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'o'],
    [CX.STRATEGY_RESOURCE, 'o'],
    [CX.BUSINESS_ACTOR, 'o'],
    [CX.BUSINESS_COLLABORATION, 'o'],
    [CX.BUSINESS_EVENT, 'o'],
    [CX.BUSINESS_FUNCTION, 'o'],
    [CX.BUSINESS_INTERACTION, 'o'],
    [CX.BUSINESS_INTERFACE, 'o'],
    [CX.BUSINESS_OBJECT, 'o'],
    [CX.BUSINESS_PROCESS, 'o'],
    [CX.BUSINESS_ROLE, 'o'],
    [CX.BUSINESS_SERVICE, 'o'],
    [CX.BUSINESS_CONTRACT, 'o'],
    [CX.BUSINESS_PRODUCT, 'o'],
    [CX.BUSINESS_REPRESENTATION, 'o'],
    [CX.APPLICATION_COLLABORATION, 'o'],
    [CX.APPLICATION_COMPONENT, 'o'],
    [CX.APPLICATION_EVENT, 'o'],
    [CX.APPLICATION_FUNCTION, 'o'],
    [CX.APPLICATION_INTERACTION, 'o'],
    [CX.APPLICATION_INTERFACE, 'o'],
    [CX.APPLICATION_PROCESS, 'o'],
    [CX.APPLICATION_SERVICE, 'o'],
    [CX.APPLICATION_DATA_OBJECT, 'o'],
    [CX.TECHNOLOGY_ARTIFACT, 'o'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'o'],
    [CX.TECHNOLOGY_DEVICE, 'o'],
    [CX.TECHNOLOGY_NODE, 'o'],
    [CX.TECHNOLOGY_PATH, 'o'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'o'],
    [CX.TECHNOLOGY_COLLABORATION, 'o'],
    [CX.TECHNOLOGY_EVENT, 'o'],
    [CX.TECHNOLOGY_FUNCTION, 'o'],
    [CX.TECHNOLOGY_INTERACTION, 'o'],
    [CX.TECHNOLOGY_INTERFACE, 'o'],
    [CX.TECHNOLOGY_PROCESS, 'o'],
    [CX.TECHNOLOGY_SERVICE, 'o'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'o'],
    [CX.PHYSICAL_EQUIPMENT, 'o'],
    [CX.PHYSICAL_FACILITY, 'o'],
    [CX.PHYSICAL_MATERIAL, 'o'],
    [CX.IMP_MIG_DELIVERABLE, 'o'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
    [CX.IMP_MIG_WORK_PACKAGE, 'o'],
    [CX.IMP_MIG_GAP, 'o'],
    [CX.IMP_MIG_PLATEAU, 'o'],
    [CX.OTHER_LOCATION, 'o'],
    [CX.OTHER_GROUPING, 'scgno'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
  ]);

  export const RELATIONSHIPS_ALLOWED_FROM_MOTIVATION_CONSTRAINT = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'no'],
    [CX.MOTIVATION_CONSTRAINT, 'scgno'],
    [CX.MOTIVATION_DRIVER, 'no'],
    [CX.MOTIVATION_GOAL, 'rno'],
    [CX.MOTIVATION_MEANING, 'no'],
    [CX.MOTIVATION_OUTCOME, 'rno'],
    [CX.MOTIVATION_PRINCIPLE, 'rno'],
    [CX.MOTIVATION_REQUIREMENT, 'scgno'],
    [CX.MOTIVATION_STAKEHOLDER, 'no'],
    [CX.MOTIVATION_VALUE, 'no'],
    [CX.STRATEGY_CAPABILITY, 'o'],
    [CX.STRATEGY_VALUE_STREAM, 'o'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'o'],
    [CX.STRATEGY_RESOURCE, 'o'],
    [CX.BUSINESS_ACTOR, 'o'],
    [CX.BUSINESS_COLLABORATION, 'o'],
    [CX.BUSINESS_EVENT, 'o'],
    [CX.BUSINESS_FUNCTION, 'o'],
    [CX.BUSINESS_INTERACTION, 'o'],
    [CX.BUSINESS_INTERFACE, 'o'],
    [CX.BUSINESS_OBJECT, 'o'],
    [CX.BUSINESS_PROCESS, 'o'],
    [CX.BUSINESS_ROLE, 'o'],
    [CX.BUSINESS_SERVICE, 'o'],
    [CX.BUSINESS_CONTRACT, 'o'],
    [CX.BUSINESS_PRODUCT, 'o'],
    [CX.BUSINESS_REPRESENTATION, 'o'],
    [CX.APPLICATION_COLLABORATION, 'o'],
    [CX.APPLICATION_COMPONENT, 'o'],
    [CX.APPLICATION_EVENT, 'o'],
    [CX.APPLICATION_FUNCTION, 'o'],
    [CX.APPLICATION_INTERACTION, 'o'],
    [CX.APPLICATION_INTERFACE, 'o'],
    [CX.APPLICATION_PROCESS, 'o'],
    [CX.APPLICATION_SERVICE, 'o'],
    [CX.APPLICATION_DATA_OBJECT, 'o'],
    [CX.TECHNOLOGY_ARTIFACT, 'o'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'o'],
    [CX.TECHNOLOGY_DEVICE, 'o'],
    [CX.TECHNOLOGY_NODE, 'o'],
    [CX.TECHNOLOGY_PATH, 'o'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'o'],
    [CX.TECHNOLOGY_COLLABORATION, 'o'],
    [CX.TECHNOLOGY_EVENT, 'o'],
    [CX.TECHNOLOGY_FUNCTION, 'o'],
    [CX.TECHNOLOGY_INTERACTION, 'o'],
    [CX.TECHNOLOGY_INTERFACE, 'o'],
    [CX.TECHNOLOGY_PROCESS, 'o'],
    [CX.TECHNOLOGY_SERVICE, 'o'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'o'],
    [CX.PHYSICAL_EQUIPMENT, 'o'],
    [CX.PHYSICAL_FACILITY, 'o'],
    [CX.PHYSICAL_MATERIAL, 'o'],
    [CX.IMP_MIG_DELIVERABLE, 'o'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
    [CX.IMP_MIG_WORK_PACKAGE, 'o'],
    [CX.IMP_MIG_GAP, 'o'],
    [CX.IMP_MIG_PLATEAU, 'o'],
    [CX.OTHER_LOCATION, 'o'],
    [CX.OTHER_GROUPING, 'scgrno'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
  ]);

  export const RELATIONSHIPS_ALLOWED_FROM_MOTIVATION_DRIVER = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'no'],
    [CX.MOTIVATION_CONSTRAINT, 'no'],
    [CX.MOTIVATION_DRIVER, 'scgno'],
    [CX.MOTIVATION_GOAL, 'no'],
    [CX.MOTIVATION_MEANING, 'no'],
    [CX.MOTIVATION_OUTCOME, 'no'],
    [CX.MOTIVATION_PRINCIPLE, 'no'],
    [CX.MOTIVATION_REQUIREMENT, 'no'],
    [CX.MOTIVATION_STAKEHOLDER, 'no'],
    [CX.MOTIVATION_VALUE, 'no'],
    [CX.STRATEGY_CAPABILITY, 'o'],
    [CX.STRATEGY_VALUE_STREAM, 'o'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'o'],
    [CX.STRATEGY_RESOURCE, 'o'],
    [CX.BUSINESS_ACTOR, 'o'],
    [CX.BUSINESS_COLLABORATION, 'o'],
    [CX.BUSINESS_EVENT, 'o'],
    [CX.BUSINESS_FUNCTION, 'o'],
    [CX.BUSINESS_INTERACTION, 'o'],
    [CX.BUSINESS_INTERFACE, 'o'],
    [CX.BUSINESS_OBJECT, 'o'],
    [CX.BUSINESS_PROCESS, 'o'],
    [CX.BUSINESS_ROLE, 'o'],
    [CX.BUSINESS_SERVICE, 'o'],
    [CX.BUSINESS_CONTRACT, 'o'],
    [CX.BUSINESS_PRODUCT, 'o'],
    [CX.BUSINESS_REPRESENTATION, 'o'],
    [CX.APPLICATION_COLLABORATION, 'o'],
    [CX.APPLICATION_COMPONENT, 'o'],
    [CX.APPLICATION_EVENT, 'o'],
    [CX.APPLICATION_FUNCTION, 'o'],
    [CX.APPLICATION_INTERACTION, 'o'],
    [CX.APPLICATION_INTERFACE, 'o'],
    [CX.APPLICATION_PROCESS, 'o'],
    [CX.APPLICATION_SERVICE, 'o'],
    [CX.APPLICATION_DATA_OBJECT, 'o'],
    [CX.TECHNOLOGY_ARTIFACT, 'o'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'o'],
    [CX.TECHNOLOGY_DEVICE, 'o'],
    [CX.TECHNOLOGY_NODE, 'o'],
    [CX.TECHNOLOGY_PATH, 'o'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'o'],
    [CX.TECHNOLOGY_COLLABORATION, 'o'],
    [CX.TECHNOLOGY_EVENT, 'o'],
    [CX.TECHNOLOGY_FUNCTION, 'o'],
    [CX.TECHNOLOGY_INTERACTION, 'o'],
    [CX.TECHNOLOGY_INTERFACE, 'o'],
    [CX.TECHNOLOGY_PROCESS, 'o'],
    [CX.TECHNOLOGY_SERVICE, 'o'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'o'],
    [CX.PHYSICAL_EQUIPMENT, 'o'],
    [CX.PHYSICAL_FACILITY, 'o'],
    [CX.PHYSICAL_MATERIAL, 'o'],
    [CX.IMP_MIG_DELIVERABLE, 'o'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
    [CX.IMP_MIG_WORK_PACKAGE, 'o'],
    [CX.IMP_MIG_GAP, 'o'],
    [CX.IMP_MIG_PLATEAU, 'o'],
    [CX.OTHER_LOCATION, 'o'],
    [CX.OTHER_GROUPING, 'scgno'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
  ]);

  export const RELATIONSHIPS_ALLOWED_FROM_MOTIVATION_GOAL = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'no'],
    [CX.MOTIVATION_CONSTRAINT, 'no'],
    [CX.MOTIVATION_DRIVER, 'no'],
    [CX.MOTIVATION_GOAL, 'scgno'],
    [CX.MOTIVATION_MEANING, 'no'],
    [CX.MOTIVATION_OUTCOME, 'no'],
    [CX.MOTIVATION_PRINCIPLE, 'no'],
    [CX.MOTIVATION_REQUIREMENT, 'no'],
    [CX.MOTIVATION_STAKEHOLDER, 'no'],
    [CX.MOTIVATION_VALUE, 'no'],
    [CX.STRATEGY_CAPABILITY, 'o'],
    [CX.STRATEGY_VALUE_STREAM, 'o'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'o'],
    [CX.STRATEGY_RESOURCE, 'o'],
    [CX.BUSINESS_ACTOR, 'o'],
    [CX.BUSINESS_COLLABORATION, 'o'],
    [CX.BUSINESS_EVENT, 'o'],
    [CX.BUSINESS_FUNCTION, 'o'],
    [CX.BUSINESS_INTERACTION, 'o'],
    [CX.BUSINESS_INTERFACE, 'o'],
    [CX.BUSINESS_OBJECT, 'o'],
    [CX.BUSINESS_PROCESS, 'o'],
    [CX.BUSINESS_ROLE, 'o'],
    [CX.BUSINESS_SERVICE, 'o'],
    [CX.BUSINESS_CONTRACT, 'o'],
    [CX.BUSINESS_PRODUCT, 'o'],
    [CX.BUSINESS_REPRESENTATION, 'o'],
    [CX.APPLICATION_COLLABORATION, 'o'],
    [CX.APPLICATION_COMPONENT, 'o'],
    [CX.APPLICATION_EVENT, 'o'],
    [CX.APPLICATION_FUNCTION, 'o'],
    [CX.APPLICATION_INTERACTION, 'o'],
    [CX.APPLICATION_INTERFACE, 'o'],
    [CX.APPLICATION_PROCESS, 'o'],
    [CX.APPLICATION_SERVICE, 'o'],
    [CX.APPLICATION_DATA_OBJECT, 'o'],
    [CX.TECHNOLOGY_ARTIFACT, 'o'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'o'],
    [CX.TECHNOLOGY_DEVICE, 'o'],
    [CX.TECHNOLOGY_NODE, 'o'],
    [CX.TECHNOLOGY_PATH, 'o'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'o'],
    [CX.TECHNOLOGY_COLLABORATION, 'o'],
    [CX.TECHNOLOGY_EVENT, 'o'],
    [CX.TECHNOLOGY_FUNCTION, 'o'],
    [CX.TECHNOLOGY_INTERACTION, 'o'],
    [CX.TECHNOLOGY_INTERFACE, 'o'],
    [CX.TECHNOLOGY_PROCESS, 'o'],
    [CX.TECHNOLOGY_SERVICE, 'o'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'o'],
    [CX.PHYSICAL_EQUIPMENT, 'o'],
    [CX.PHYSICAL_FACILITY, 'o'],
    [CX.PHYSICAL_MATERIAL, 'o'],
    [CX.IMP_MIG_DELIVERABLE, 'o'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
    [CX.IMP_MIG_WORK_PACKAGE, 'o'],
    [CX.IMP_MIG_GAP, 'o'],
    [CX.IMP_MIG_PLATEAU, 'o'],
    [CX.OTHER_LOCATION, 'o'],
    [CX.OTHER_GROUPING, 'scgno'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
  ]);

  export const RELATIONSHIPS_ALLOWED_FROM_MOTIVATION_MEANING = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'no'],
    [CX.MOTIVATION_CONSTRAINT, 'no'],
    [CX.MOTIVATION_DRIVER, 'no'],
    [CX.MOTIVATION_GOAL, 'no'],
    [CX.MOTIVATION_MEANING, 'scgno'],
    [CX.MOTIVATION_OUTCOME, 'no'],
    [CX.MOTIVATION_PRINCIPLE, 'no'],
    [CX.MOTIVATION_REQUIREMENT, 'no'],
    [CX.MOTIVATION_STAKEHOLDER, 'no'],
    [CX.MOTIVATION_VALUE, 'no'],
    [CX.STRATEGY_CAPABILITY, 'o'],
    [CX.STRATEGY_VALUE_STREAM, 'o'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'o'],
    [CX.STRATEGY_RESOURCE, 'o'],
    [CX.BUSINESS_ACTOR, 'o'],
    [CX.BUSINESS_COLLABORATION, 'o'],
    [CX.BUSINESS_EVENT, 'o'],
    [CX.BUSINESS_FUNCTION, 'o'],
    [CX.BUSINESS_INTERACTION, 'o'],
    [CX.BUSINESS_INTERFACE, 'o'],
    [CX.BUSINESS_OBJECT, 'o'],
    [CX.BUSINESS_PROCESS, 'o'],
    [CX.BUSINESS_ROLE, 'o'],
    [CX.BUSINESS_SERVICE, 'o'],
    [CX.BUSINESS_CONTRACT, 'o'],
    [CX.BUSINESS_PRODUCT, 'o'],
    [CX.BUSINESS_REPRESENTATION, 'o'],
    [CX.APPLICATION_COLLABORATION, 'o'],
    [CX.APPLICATION_COMPONENT, 'o'],
    [CX.APPLICATION_EVENT, 'o'],
    [CX.APPLICATION_FUNCTION, 'o'],
    [CX.APPLICATION_INTERACTION, 'o'],
    [CX.APPLICATION_INTERFACE, 'o'],
    [CX.APPLICATION_PROCESS, 'o'],
    [CX.APPLICATION_SERVICE, 'o'],
    [CX.APPLICATION_DATA_OBJECT, 'o'],
    [CX.TECHNOLOGY_ARTIFACT, 'o'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'o'],
    [CX.TECHNOLOGY_DEVICE, 'o'],
    [CX.TECHNOLOGY_NODE, 'o'],
    [CX.TECHNOLOGY_PATH, 'o'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'o'],
    [CX.TECHNOLOGY_COLLABORATION, 'o'],
    [CX.TECHNOLOGY_EVENT, 'o'],
    [CX.TECHNOLOGY_FUNCTION, 'o'],
    [CX.TECHNOLOGY_INTERACTION, 'o'],
    [CX.TECHNOLOGY_INTERFACE, 'o'],
    [CX.TECHNOLOGY_PROCESS, 'o'],
    [CX.TECHNOLOGY_SERVICE, 'o'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'o'],
    [CX.PHYSICAL_EQUIPMENT, 'o'],
    [CX.PHYSICAL_FACILITY, 'o'],
    [CX.PHYSICAL_MATERIAL, 'o'],
    [CX.IMP_MIG_DELIVERABLE, 'o'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
    [CX.IMP_MIG_WORK_PACKAGE, 'o'],
    [CX.IMP_MIG_GAP, 'o'],
    [CX.IMP_MIG_PLATEAU, 'o'],
    [CX.OTHER_LOCATION, 'o'],
    [CX.OTHER_GROUPING, 'scgno'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
  ]);

  export const RELATIONSHIPS_ALLOWED_FROM_MOTIVATION_OUTCOME = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'no'],
    [CX.MOTIVATION_CONSTRAINT, 'no'],
    [CX.MOTIVATION_DRIVER, 'no'],
    [CX.MOTIVATION_GOAL, 'rno'],
    [CX.MOTIVATION_MEANING, 'no'],
    [CX.MOTIVATION_OUTCOME, 'scgno'],
    [CX.MOTIVATION_PRINCIPLE, 'no'],
    [CX.MOTIVATION_REQUIREMENT, 'no'],
    [CX.MOTIVATION_STAKEHOLDER, 'no'],
    [CX.MOTIVATION_VALUE, 'no'],
    [CX.STRATEGY_CAPABILITY, 'o'],
    [CX.STRATEGY_VALUE_STREAM, 'o'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'o'],
    [CX.STRATEGY_RESOURCE, 'o'],
    [CX.BUSINESS_ACTOR, 'o'],
    [CX.BUSINESS_COLLABORATION, 'o'],
    [CX.BUSINESS_EVENT, 'o'],
    [CX.BUSINESS_FUNCTION, 'o'],
    [CX.BUSINESS_INTERACTION, 'o'],
    [CX.BUSINESS_INTERFACE, 'o'],
    [CX.BUSINESS_OBJECT, 'o'],
    [CX.BUSINESS_PROCESS, 'o'],
    [CX.BUSINESS_ROLE, 'o'],
    [CX.BUSINESS_SERVICE, 'o'],
    [CX.BUSINESS_CONTRACT, 'o'],
    [CX.BUSINESS_PRODUCT, 'o'],
    [CX.BUSINESS_REPRESENTATION, 'o'],
    [CX.APPLICATION_COLLABORATION, 'o'],
    [CX.APPLICATION_COMPONENT, 'o'],
    [CX.APPLICATION_EVENT, 'o'],
    [CX.APPLICATION_FUNCTION, 'o'],
    [CX.APPLICATION_INTERACTION, 'o'],
    [CX.APPLICATION_INTERFACE, 'o'],
    [CX.APPLICATION_PROCESS, 'o'],
    [CX.APPLICATION_SERVICE, 'o'],
    [CX.APPLICATION_DATA_OBJECT, 'o'],
    [CX.TECHNOLOGY_ARTIFACT, 'o'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'o'],
    [CX.TECHNOLOGY_DEVICE, 'o'],
    [CX.TECHNOLOGY_NODE, 'o'],
    [CX.TECHNOLOGY_PATH, 'o'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'o'],
    [CX.TECHNOLOGY_COLLABORATION, 'o'],
    [CX.TECHNOLOGY_EVENT, 'o'],
    [CX.TECHNOLOGY_FUNCTION, 'o'],
    [CX.TECHNOLOGY_INTERACTION, 'o'],
    [CX.TECHNOLOGY_INTERFACE, 'o'],
    [CX.TECHNOLOGY_PROCESS, 'o'],
    [CX.TECHNOLOGY_SERVICE, 'o'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'o'],
    [CX.PHYSICAL_EQUIPMENT, 'o'],
    [CX.PHYSICAL_FACILITY, 'o'],
    [CX.PHYSICAL_MATERIAL, 'o'],
    [CX.IMP_MIG_DELIVERABLE, 'o'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
    [CX.IMP_MIG_WORK_PACKAGE, 'o'],
    [CX.IMP_MIG_GAP, 'o'],
    [CX.IMP_MIG_PLATEAU, 'o'],
    [CX.OTHER_LOCATION, 'o'],
    [CX.OTHER_GROUPING, 'scgrno'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
  ]);

  export const RELATIONSHIPS_ALLOWED_FROM_MOTIVATION_PRINCIPLE = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'no'],
    [CX.MOTIVATION_CONSTRAINT, 'no'],
    [CX.MOTIVATION_DRIVER, 'no'],
    [CX.MOTIVATION_GOAL, 'rno'],
    [CX.MOTIVATION_MEANING, 'no'],
    [CX.MOTIVATION_OUTCOME, 'rno'],
    [CX.MOTIVATION_PRINCIPLE, 'scgno'],
    [CX.MOTIVATION_REQUIREMENT, 'no'],
    [CX.MOTIVATION_STAKEHOLDER, 'no'],
    [CX.MOTIVATION_VALUE, 'no'],
    [CX.STRATEGY_CAPABILITY, 'o'],
    [CX.STRATEGY_VALUE_STREAM, 'o'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'o'],
    [CX.STRATEGY_RESOURCE, 'o'],
    [CX.BUSINESS_ACTOR, 'o'],
    [CX.BUSINESS_COLLABORATION, 'o'],
    [CX.BUSINESS_EVENT, 'o'],
    [CX.BUSINESS_FUNCTION, 'o'],
    [CX.BUSINESS_INTERACTION, 'o'],
    [CX.BUSINESS_INTERFACE, 'o'],
    [CX.BUSINESS_OBJECT, 'o'],
    [CX.BUSINESS_PROCESS, 'o'],
    [CX.BUSINESS_ROLE, 'o'],
    [CX.BUSINESS_SERVICE, 'o'],
    [CX.BUSINESS_CONTRACT, 'o'],
    [CX.BUSINESS_PRODUCT, 'o'],
    [CX.BUSINESS_REPRESENTATION, 'o'],
    [CX.APPLICATION_COLLABORATION, 'o'],
    [CX.APPLICATION_COMPONENT, 'o'],
    [CX.APPLICATION_EVENT, 'o'],
    [CX.APPLICATION_FUNCTION, 'o'],
    [CX.APPLICATION_INTERACTION, 'o'],
    [CX.APPLICATION_INTERFACE, 'o'],
    [CX.APPLICATION_PROCESS, 'o'],
    [CX.APPLICATION_SERVICE, 'o'],
    [CX.APPLICATION_DATA_OBJECT, 'o'],
    [CX.TECHNOLOGY_ARTIFACT, 'o'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'o'],
    [CX.TECHNOLOGY_DEVICE, 'o'],
    [CX.TECHNOLOGY_NODE, 'o'],
    [CX.TECHNOLOGY_PATH, 'o'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'o'],
    [CX.TECHNOLOGY_COLLABORATION, 'o'],
    [CX.TECHNOLOGY_EVENT, 'o'],
    [CX.TECHNOLOGY_FUNCTION, 'o'],
    [CX.TECHNOLOGY_INTERACTION, 'o'],
    [CX.TECHNOLOGY_INTERFACE, 'o'],
    [CX.TECHNOLOGY_PROCESS, 'o'],
    [CX.TECHNOLOGY_SERVICE, 'o'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'o'],
    [CX.PHYSICAL_EQUIPMENT, 'o'],
    [CX.PHYSICAL_FACILITY, 'o'],
    [CX.PHYSICAL_MATERIAL, 'o'],
    [CX.IMP_MIG_DELIVERABLE, 'o'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
    [CX.IMP_MIG_WORK_PACKAGE, 'o'],
    [CX.IMP_MIG_GAP, 'o'],
    [CX.IMP_MIG_PLATEAU, 'o'],
    [CX.OTHER_LOCATION, 'o'],
    [CX.OTHER_GROUPING, 'scgrno'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
  ]);

  export const RELATIONSHIPS_ALLOWED_FROM_MOTIVATION_REQUIREMENT = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'no'],
    [CX.MOTIVATION_CONSTRAINT, 'scgno'],
    [CX.MOTIVATION_DRIVER, 'no'],
    [CX.MOTIVATION_GOAL, 'rno'],
    [CX.MOTIVATION_MEANING, 'no'],
    [CX.MOTIVATION_OUTCOME, 'rno'],
    [CX.MOTIVATION_PRINCIPLE, 'rno'],
    [CX.MOTIVATION_REQUIREMENT, 'scgno'],
    [CX.MOTIVATION_STAKEHOLDER, 'no'],
    [CX.MOTIVATION_VALUE, 'no'],
    [CX.STRATEGY_CAPABILITY, 'o'],
    [CX.STRATEGY_VALUE_STREAM, 'o'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'o'],
    [CX.STRATEGY_RESOURCE, 'o'],
    [CX.BUSINESS_ACTOR, 'o'],
    [CX.BUSINESS_COLLABORATION, 'o'],
    [CX.BUSINESS_EVENT, 'o'],
    [CX.BUSINESS_FUNCTION, 'o'],
    [CX.BUSINESS_INTERACTION, 'o'],
    [CX.BUSINESS_INTERFACE, 'o'],
    [CX.BUSINESS_OBJECT, 'o'],
    [CX.BUSINESS_PROCESS, 'o'],
    [CX.BUSINESS_ROLE, 'o'],
    [CX.BUSINESS_SERVICE, 'o'],
    [CX.BUSINESS_CONTRACT, 'o'],
    [CX.BUSINESS_PRODUCT, 'o'],
    [CX.BUSINESS_REPRESENTATION, 'o'],
    [CX.APPLICATION_COLLABORATION, 'o'],
    [CX.APPLICATION_COMPONENT, 'o'],
    [CX.APPLICATION_EVENT, 'o'],
    [CX.APPLICATION_FUNCTION, 'o'],
    [CX.APPLICATION_INTERACTION, 'o'],
    [CX.APPLICATION_INTERFACE, 'o'],
    [CX.APPLICATION_PROCESS, 'o'],
    [CX.APPLICATION_SERVICE, 'o'],
    [CX.APPLICATION_DATA_OBJECT, 'o'],
    [CX.TECHNOLOGY_ARTIFACT, 'o'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'o'],
    [CX.TECHNOLOGY_DEVICE, 'o'],
    [CX.TECHNOLOGY_NODE, 'o'],
    [CX.TECHNOLOGY_PATH, 'o'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'o'],
    [CX.TECHNOLOGY_COLLABORATION, 'o'],
    [CX.TECHNOLOGY_EVENT, 'o'],
    [CX.TECHNOLOGY_FUNCTION, 'o'],
    [CX.TECHNOLOGY_INTERACTION, 'o'],
    [CX.TECHNOLOGY_INTERFACE, 'o'],
    [CX.TECHNOLOGY_PROCESS, 'o'],
    [CX.TECHNOLOGY_SERVICE, 'o'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'o'],
    [CX.PHYSICAL_EQUIPMENT, 'o'],
    [CX.PHYSICAL_FACILITY, 'o'],
    [CX.PHYSICAL_MATERIAL, 'o'],
    [CX.IMP_MIG_DELIVERABLE, 'o'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
    [CX.IMP_MIG_WORK_PACKAGE, 'o'],
    [CX.IMP_MIG_GAP, 'o'],
    [CX.IMP_MIG_PLATEAU, 'o'],
    [CX.OTHER_LOCATION, 'o'],
    [CX.OTHER_GROUPING, 'scgrno'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
  ]);

  export const RELATIONSHIPS_ALLOWED_FROM_MOTIVATION_STAKEHOLDER = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'no'],
    [CX.MOTIVATION_CONSTRAINT, 'no'],
    [CX.MOTIVATION_DRIVER, 'no'],
    [CX.MOTIVATION_GOAL, 'no'],
    [CX.MOTIVATION_MEANING, 'no'],
    [CX.MOTIVATION_OUTCOME, 'no'],
    [CX.MOTIVATION_PRINCIPLE, 'no'],
    [CX.MOTIVATION_REQUIREMENT, 'no'],
    [CX.MOTIVATION_STAKEHOLDER, 'scgno'],
    [CX.MOTIVATION_VALUE, 'no'],
    [CX.STRATEGY_CAPABILITY, 'o'],
    [CX.STRATEGY_VALUE_STREAM, 'o'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'o'],
    [CX.STRATEGY_RESOURCE, 'o'],
    [CX.BUSINESS_ACTOR, 'o'],
    [CX.BUSINESS_COLLABORATION, 'o'],
    [CX.BUSINESS_EVENT, 'o'],
    [CX.BUSINESS_FUNCTION, 'o'],
    [CX.BUSINESS_INTERACTION, 'o'],
    [CX.BUSINESS_INTERFACE, 'o'],
    [CX.BUSINESS_OBJECT, 'o'],
    [CX.BUSINESS_PROCESS, 'o'],
    [CX.BUSINESS_ROLE, 'o'],
    [CX.BUSINESS_SERVICE, 'o'],
    [CX.BUSINESS_CONTRACT, 'o'],
    [CX.BUSINESS_PRODUCT, 'o'],
    [CX.BUSINESS_REPRESENTATION, 'o'],
    [CX.APPLICATION_COLLABORATION, 'o'],
    [CX.APPLICATION_COMPONENT, 'o'],
    [CX.APPLICATION_EVENT, 'o'],
    [CX.APPLICATION_FUNCTION, 'o'],
    [CX.APPLICATION_INTERACTION, 'o'],
    [CX.APPLICATION_INTERFACE, 'o'],
    [CX.APPLICATION_PROCESS, 'o'],
    [CX.APPLICATION_SERVICE, 'o'],
    [CX.APPLICATION_DATA_OBJECT, 'o'],
    [CX.TECHNOLOGY_ARTIFACT, 'o'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'o'],
    [CX.TECHNOLOGY_DEVICE, 'o'],
    [CX.TECHNOLOGY_NODE, 'o'],
    [CX.TECHNOLOGY_PATH, 'o'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'o'],
    [CX.TECHNOLOGY_COLLABORATION, 'o'],
    [CX.TECHNOLOGY_EVENT, 'o'],
    [CX.TECHNOLOGY_FUNCTION, 'o'],
    [CX.TECHNOLOGY_INTERACTION, 'o'],
    [CX.TECHNOLOGY_INTERFACE, 'o'],
    [CX.TECHNOLOGY_PROCESS, 'o'],
    [CX.TECHNOLOGY_SERVICE, 'o'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'o'],
    [CX.PHYSICAL_EQUIPMENT, 'o'],
    [CX.PHYSICAL_FACILITY, 'o'],
    [CX.PHYSICAL_MATERIAL, 'o'],
    [CX.IMP_MIG_DELIVERABLE, 'o'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
    [CX.IMP_MIG_WORK_PACKAGE, 'o'],
    [CX.IMP_MIG_GAP, 'o'],
    [CX.IMP_MIG_PLATEAU, 'o'],
    [CX.OTHER_LOCATION, 'o'],
    [CX.OTHER_GROUPING, 'scgno'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
  ]);

  export const RELATIONSHIPS_ALLOWED_FROM_MOTIVATION_VALUE = new Map([
    [CX.MOTIVATION_ASSESSMENT, 'no'],
    [CX.MOTIVATION_CONSTRAINT, 'no'],
    [CX.MOTIVATION_DRIVER, 'no'],
    [CX.MOTIVATION_GOAL, 'no'],
    [CX.MOTIVATION_MEANING, 'no'],
    [CX.MOTIVATION_OUTCOME, 'no'],
    [CX.MOTIVATION_PRINCIPLE, 'no'],
    [CX.MOTIVATION_REQUIREMENT, 'no'],
    [CX.MOTIVATION_STAKEHOLDER, 'no'],
    [CX.MOTIVATION_VALUE, 'scgno'],
    [CX.STRATEGY_CAPABILITY, 'o'],
    [CX.STRATEGY_VALUE_STREAM, 'o'],
    [CX.STRATEGY_COURSE_OF_ACTION, 'o'],
    [CX.STRATEGY_RESOURCE, 'o'],
    [CX.BUSINESS_ACTOR, 'o'],
    [CX.BUSINESS_COLLABORATION, 'o'],
    [CX.BUSINESS_EVENT, 'o'],
    [CX.BUSINESS_FUNCTION, 'o'],
    [CX.BUSINESS_INTERACTION, 'o'],
    [CX.BUSINESS_INTERFACE, 'o'],
    [CX.BUSINESS_OBJECT, 'o'],
    [CX.BUSINESS_PROCESS, 'o'],
    [CX.BUSINESS_ROLE, 'o'],
    [CX.BUSINESS_SERVICE, 'o'],
    [CX.BUSINESS_CONTRACT, 'o'],
    [CX.BUSINESS_PRODUCT, 'o'],
    [CX.BUSINESS_REPRESENTATION, 'o'],
    [CX.APPLICATION_COLLABORATION, 'o'],
    [CX.APPLICATION_COMPONENT, 'o'],
    [CX.APPLICATION_EVENT, 'o'],
    [CX.APPLICATION_FUNCTION, 'o'],
    [CX.APPLICATION_INTERACTION, 'o'],
    [CX.APPLICATION_INTERFACE, 'o'],
    [CX.APPLICATION_PROCESS, 'o'],
    [CX.APPLICATION_SERVICE, 'o'],
    [CX.APPLICATION_DATA_OBJECT, 'o'],
    [CX.TECHNOLOGY_ARTIFACT, 'o'],
    [CX.TECHNOLOGY_COMMUNICATION_NETWORK, 'o'],
    [CX.TECHNOLOGY_DEVICE, 'o'],
    [CX.TECHNOLOGY_NODE, 'o'],
    [CX.TECHNOLOGY_PATH, 'o'],
    [CX.TECHNOLOGY_SYSTEM_SOFTWARE, 'o'],
    [CX.TECHNOLOGY_COLLABORATION, 'o'],
    [CX.TECHNOLOGY_EVENT, 'o'],
    [CX.TECHNOLOGY_FUNCTION, 'o'],
    [CX.TECHNOLOGY_INTERACTION, 'o'],
    [CX.TECHNOLOGY_INTERFACE, 'o'],
    [CX.TECHNOLOGY_PROCESS, 'o'],
    [CX.TECHNOLOGY_SERVICE, 'o'],
    [CX.PHYSICAL_DISTRIBUTION_NETWORK, 'o'],
    [CX.PHYSICAL_EQUIPMENT, 'o'],
    [CX.PHYSICAL_FACILITY, 'o'],
    [CX.PHYSICAL_MATERIAL, 'o'],
    [CX.IMP_MIG_DELIVERABLE, 'o'],
    [CX.IMP_MIG_IMPLEMENTATION_EVENT, 'o'],
    [CX.IMP_MIG_WORK_PACKAGE, 'o'],
    [CX.IMP_MIG_GAP, 'o'],
    [CX.IMP_MIG_PLATEAU, 'o'],
    [CX.OTHER_LOCATION, 'o'],
    [CX.OTHER_GROUPING, 'scgno'],
    [CX.RELATIONSHIP_ACCESS, 'o'],
    [CX.RELATIONSHIP_AGGREGATION, 'o'],
    [CX.RELATIONSHIP_ASSIGNMENT, 'o'],
    [CX.RELATIONSHIP_ASSOCIATION, 'o'],
    [CX.RELATIONSHIP_COMPOSITION, 'o'],
    [CX.RELATIONSHIP_FLOW, 'o'],
    [CX.RELATIONSHIP_INFLUENCE, 'o'],
    [CX.RELATIONSHIP_JUNCTION_AND, ''],
    [CX.RELATIONSHIP_JUNCTION_OR, ''],
    [CX.RELATIONSHIP_REALIZATION, 'o'],
    [CX.RELATIONSHIP_SERVING, 'o'],
    [CX.RELATIONSHIP_SPECIALIZATION, 'o'],
    [CX.RELATIONSHIP_TRIGGERING, 'o']
  ]);