import React, { useEffect, useRef } from "react";
import { CircularProgress, Backdrop } from "@mui/material";
import useCustomSnackbar from "./CustomSnackbar/useCustomSnackbar";
import 'archimate-js/assets/archimate-js.css';
import { useModeler } from "../ModelerContext";
import { logger } from "../logger";

export default function Canvas () {

    const { error, loading, modeler, refresh, refreshed, initModeler } = useModeler();
    const containerRef = useRef();
    const snackbar = useCustomSnackbar();

    useEffect(() => {
        logger.log("useEffect => modeler triggering");
        const container = containerRef.current;
        if (modeler === null) {
            logger.log("useEffect => initModeler");
            initModeler(container);
        } else {
            if (refresh) {
                logger.log("useEffect => refresh canvas");
                refreshed();            
            }
        }
    }, [modeler, refresh, refreshed, initModeler]);

    /*
    useEffect(() => {
        logger.log("useEffect => refresh triggering");
        if (modeler !== null && refresh) {
            logger.log("useEffect => refresh canvas");
            refreshed();
            //snackbar.showSuccess(`${model_name} successfully loaded on canvas.`);
        }
    },[modeler, refresh, refreshed]);*/

    useEffect(() => {
        if (error) {
            snackbar.showError(error.message);
        }   
    },[error, snackbar]);

    return (
        <>
        {loading && 
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
        }
        <div ref={ containerRef } style={{height: '100vh'}}>
        </div>
        </>
    );

}