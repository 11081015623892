import LogRocket from "logrocket";
import React, { useCallback, useMemo } from "react";
import { createContext, useState, useEffect, useContext } from "react";

const UserContext = createContext();

const UserContextProvider = ({ children }) => {

    const visitor = useMemo(() => ({
        fullname: 'John Doe',
        email: 'john@doe.org',
        username: 'Visitor',
        userID: 'id-0',
        avatar: null,
    }),[]);

    const [user, setUser] = useState(visitor);

    const signout = useCallback(() => {
        setUser(visitor);
    }, [visitor]);

    useEffect(() => {
        const fetchUser = () => {
            setUser(visitor);
        };

        fetchUser();
        LogRocket.identify(visitor.userID, {
            name: visitor.username,
        });

    }, [visitor]);

    const contextValue = useMemo(() => ({
        user,
        signout
    }), [user, signout]);

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};

// context consumer hook
const useUserContext = () => {
    // get the context
    const context = useContext(UserContext);
  
    // if `undefined`, throw an error
    if (context === undefined) {
      throw new Error("useUserContext was used outside of its Provider");
    }
  
    return context;
  };

export { UserContext, UserContextProvider, useUserContext };