import { useState } from "react";
import { Tooltip, Box, IconButton, Avatar, Zoom, Menu, MenuItem, Typography, Divider } from "@mui/material";
import { useUserContext } from "../UserContext";
import UserProfilelDialog from "./UserProfileDialog";
import AboutDialog from "./AboutDialog";

export default function UserMenu () {

    const { user } = useUserContext();
    const [userProfileDialogIsOpen, setUserProfileDialogIsOpen] = useState(false);
    const [aboutDialogIsOpen, setAboutDialogIsOpen] = useState(false);
    const [anchorElUser, setAnchorElUser] = useState(null);

    //const userSettings = ['Profile', 'Logout'];

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
  
    const handleOpenUserProfileDialog = () => {
        setAnchorElUser(null);
        setUserProfileDialogIsOpen(true);
    };

    const handleCloseUserProfileDialog = () => {
        setUserProfileDialogIsOpen(false);
    };

    const handleLogout = () => {

    }

    const handleOpenAboutDialog = () => {
        setAnchorElUser(null);
        setAboutDialogIsOpen(true);
    };

    const handleCloseAboutDialog = () => {
        setAboutDialogIsOpen(false);
    };

    return (
        <>
        <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings" TransitionComponent={Zoom} arrow>
        <IconButton size="small" onClick={handleOpenUserMenu} sx={{ p: 0, mr: 2}}>
            <Avatar alt={ user.username } src={ user.avatar } sx={{ width: 30, height: 30 }} />
        </IconButton>
        </Tooltip>
        <Menu
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={() => { setAnchorElUser(null) }}
        >
        {/*userSettings.map((setting) => (
            <MenuItem key={setting} onClick={handleCloseUserMenu}>
            <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
        ))*/}
        <MenuItem key="Profile" onClick={handleOpenUserProfileDialog}>
            <Typography textAlign="center">Profile</Typography>
        </MenuItem>
        <MenuItem key="Logout" onClick={handleLogout} disabled >
            <Typography textAlign="center">Logout</Typography>
        </MenuItem>
        <Divider />
        <MenuItem key="About" onClick={handleOpenAboutDialog} >
            <Typography textAlign="center">About</Typography>
        </MenuItem>
        </Menu>
        </Box>
        <UserProfilelDialog
            isDialogOpen={userProfileDialogIsOpen}
            handleCloseDialog={handleCloseUserProfileDialog}
        />
        <AboutDialog
            isDialogOpen={aboutDialogIsOpen}
            handleCloseDialog={handleCloseAboutDialog}
        />
        </>
    );
}